<div class="row">
    <div class="col-sm-12">
        <a routerLinkActive="active" mat-raised-button class="btn btn mb-1 ml-2 	fa fa-address-book "
            style="BACKGROUND: #6e7dd2;;color: white; height: 36px;">
            <span style="font-family: Arial, Helvetica, sans-serif; "> Book LCL Orders</span></a>
        <!-- <a routerLinkActive="active" mat-raised-button class="btn btn mb-1 mr-3 ml-3 	fa fa-address-book-o "
            style="background-color: #0077bd;color: white; height: 36px;">
            <span style="font-family: Arial, Helvetica, sans-serif;"> LCL Orders</span></a> -->

        <!-- <a type="button" [routerLink]="['/xp/orders/order-panel/lcl']"
            class=" btn  btn pull-right ml-3 mb-1 fa fa-reply"
            style="background-color: #626364;color: white; height: 34px;">
            <span style="font-family: Arial, Helvetica, sans-serif;"> Back</span></a> -->

    </div>

</div>
<mat-card style="margin-top: 14px;">

    <div class="row ml-3 mt-2">
        <div class="col-sm-4 form-group">
            <mat-select id='servicetype' name='servicetype' class="form-control" style="height: 40px;"
                placeholder="Select Service Type" (ngModelChange)='selectServiceType($event)'
                [(ngModel)]="order.ServiceTypeId">
                <mat-option *ngFor="let item of arrayofservice" [value]="item.LookupId">
                    {{item.LookupName}}
                </mat-option>
            </mat-select>


        </div>
        <div class="col-sm-4">
            <a routerLinkActive="active" type="button" (click)="book()" class="btn  btn mb-1 mr-3 ml-3"
                style="BACKGROUND: #6e7dd2;color: white; height: 34px;">
                <span style="font-family: Arial, Helvetica, sans-serif;">Go <i class="fa fa-angle-double-right"></i>
                </span>
            </a>
        </div>
    </div>
</mat-card>



<mat-card style="margin-top: 46px;">
    <div class="d-flex justify-content-center" *ngIf="orderType == null ">
        <h3 routerLinkActive="active" class="card-subtitle mb-2 text-muted mt-1">Please Select Your Service Type</h3>
    </div>
    <div class="d-flex justify-content-center" *ngIf="orderType != null ">
        <h3 routerLinkActive="active" class="card-subtitle mb-2 text-muted mt-1" style="color: #0077bd !important;">
            {{orderType}}</h3>
    </div>
    <!-- Select Pickup request Date -->
    <div class="col-sm-12" *ngIf="orderType != '' && orderType != null">
        <div class="mt-5" [formGroup]="bookOrderForm" (ngSubmit)="bookOrder();">
            <div class="form-group">
                <div class="row mb-2" style=" margin-top: 18px;">
                    <div class="col-sm-2">
                        <label class="control-label add-member-label">Pickup Date:</label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>

                    <div class="col-sm-5">
                        <input type="date" formControlName="PickUpRequestDate" placeholder="MM/DD/YYYY"
                            class="form-control" style="width:300px;" [min]="today1"
                            (ngModelChange)="onSelectPickUpRequestDate()" id="input-12">

                        <div class="text-danger ml-3" style="margin: 0px;" *ngIf="(bookOrderForm.controls.PickUpRequestDate.touched || submitted) 
                              && bookOrderForm.controls.PickUpRequestDate.errors?.required">
                            Select Pickup request date
                        </div>
                    </div>
                </div>
            </div>
            <!-- Select Pickup request From Time -->
            <div class="form-group">
                <div class="row mb-2" style=" margin-top: 18px;">
                    <div class="col-sm-2">
                        <label class="control-label add-member-label">Pickup From Time:</label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>

                    <div class="col-sm-5">
                        <input type="text" formControlName="FromTime" class="form-control" style="width:300px;"
                            placeholder="HH-MM" (ngModelChange)="onSelectFromTime($event)" [owlDateTimeTrigger]="dt2"
                            [owlDateTime]="dt2" name="dt2" />
                        <owl-date-time [hour12Timer]="'true'" [pickerType]="'timer'" #dt2></owl-date-time>

                        <div class="text-danger ml-3" style="margin: 0px;"
                            *ngIf="(bookOrderForm.controls.FromTime.touched || submitted) && bookOrderForm.controls.FromTime.errors?.required && !bookOrderForm.controls.FromTime.errors?.lessDateTime">
                            Select From Time
                        </div>
                        <div class="text-danger ml-3 mt-1" style="margin: 0px;"
                            *ngIf="bookOrderForm.controls.FromTime.errors?.lessDateTime">
                            From Time should be greater than current time
                        </div>


                    </div>
                </div>
            </div>


            <!-- Select Pickup request To Time -->
            <div class="form-group" *ngIf="isPickUpDateSelected">
                <div class="row mb-2" style=" margin-top: 18px;">
                    <div class="col-sm-2">
                        <label class="control-label add-member-label">Pickup To Time:</label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>

                    <div class="col-sm-5">
                        <input [disabled]='isToTimeDisabled' disabled type="time" formControlName="ToTime"
                            placeholder="HH-MM" showTime="true" style="width:300px;" class="form-control"
                            (ngModelChange)="onSelectToTime($event)" id="input-12">
                        <div class="text-danger ml-3" style="margin: 0px;"
                            *ngIf="(bookOrderForm.controls.ToTime.touched || submitted) && bookOrderForm.controls.ToTime.errors?.required && !bookOrderForm.controls.ToTime.errors?.lessDateTime">
                            Select To Time
                        </div>
                        <div class="text-danger ml-3 mt-1" style="margin: 0px;"
                            *ngIf="bookOrderForm.controls.ToTime.errors?.lessDateTime">
                            To Time should be greater than From time
                        </div>

                    </div>
                </div>
            </div>
            <!-- Select Origin Address-->
            <div class="form-group" *ngIf="orderType != 'LCL Express D2C'">
                <div class="row mb-2" style=" margin-top: 18px;">
                    <div class="col-sm-2">
                        <label class="control-label add-member-label">Origin Address:</label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>

                    <div class="col-sm-5">
                        <!-- Origin Locations Dropdown -->
                        <mat-select class="form-control" style="width: 299px;" placeholder="Select Origin "
                            class="form-control" formControlName="OriginLocationId"
                            (selectionChange)='onOriginLocationSelection($event)'>
                            <mat-option>
                                <ngx-mat-select-search [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="OriginLocationSearch"
                                    (ngModelChange)="originLocationSearch(OriginLocationSearch,'Origin')"
                                    style="width:301px;"
                                    matTooltip="{{OriginLocationSearch == null || (OriginLocationSearch != null && OriginLocationSearch.length < 3) ? 'Enter at least 3 letters' : ''}}"
                                    [placeholderLabel]="'--Select Origin Address--'" [noEntriesFoundLabel]="'Not found'"
                                    [closeIcon]="''">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of originLocationList"
                                [value]="item.LookupId">{{item.LookupName}}</mat-option>
                        </mat-select>
                        <br>
                        <span class="text-danger ml-3"
                            *ngIf="(bookOrderForm.controls.OriginLocationId.touched || submitted) && bookOrderForm.controls.OriginLocationId.errors?.required">
                            Please select Origin
                        </span>
                    </div>
                </div>
            </div>
            <!-- Select Origin -->
            <div class="form-group" *ngIf="orderType != 'LCL Express D2C'">
                <div class="row ">
                    <div class="col-sm-2  mr-0" style="vertical-align: middle;">
                    </div>
                    <div class="col-sm-10">
                        <div class="row" style="width:65%">
                            <div class="col-sm-5">
                                <!-- Origin Locations Dropdown -->
                                <mat-select class="form-control" style="width: 299px;" placeholder="Saved Locations"
                                    formControlName="OriginId" (selectionChange)='onOriginSelection($event)'>
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="OriginSearch"
                                            [ngModelOptions]="{standalone: true}"
                                            [placeholderLabel]="'--Saved Locations--'"
                                            [noEntriesFoundLabel]="'Not found'" [closeIcon]="''">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of originList |stringFilterBy:OriginSearch:'LocationName'"
                                        [value]="item.CustomerLocationId">{{item.LocationName}}</mat-option>
                                </mat-select>
                            </div>
                            <div class="col-sm-1 mt-2" style="text-align: center;">
                                OR
                            </div>
                            <div class="col-sm-6" style="padding: 0px;">
                                <div class="row">

                                    <div class="col-sm-9" style="padding: 0px;">
                                        <!-- Origin Location Map -->
                                        <input style="width: 270px!important;" type="text"
                                            class="form-control ml-3  mr-3" formControlName="OriginMap"
                                            placeholder="Origin Location from Map"
                                            disabled='isMapLocationNotEditable' />
                                    </div>
                                    <div class="col-sm-1" id="icon" style="padding: 0px;"
                                        *ngIf="!isOriginSelectedFromMap"
                                        style="color: #337ab7;padding-top: 4px;cursor: pointer;"
                                        (click)="openMapForLocationCapture('Origin')">
                                        <button class="btn fa fa-crosshairs" style="background-color: #6ebed2;
                                        font-size: 15px;
                                        margin-top: -3px;
                                        margin-left: -17px;
                                        height: 33px;">
                                            <span style="margin-right: 8px;"></span>Capture
                                        </button>
                                    </div>
                                    <!-- <mat-icon >gps_fixed</mat-icon> -->
                                    <div *ngIf="isOriginSelectedFromMap" id="icon" class="col-sm-1 "
                                        style="padding-top: 4px;cursor: pointer;" (click)="cancelMapLocation()">
                                        <button class="btn fa fa-trash" style="background-color:#ed3434;;
                                        font-size: 15px;
                                        margin-top: -3px;
                                        margin-left: -17px;
                                        height: 33px;">
                                            <span style="margin-right: 8px;"></span>Cancel
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <p class="mt-1 " style="color: #246ade;"
                            *ngIf="bookOrderForm.controls['OriginId'].value != null">
                            {{bookOrderForm.controls['OriginAddress'].value}}</p>
                        <span class="text-danger ml-3"
                            *ngIf="(bookOrderForm.controls.OriginId.touched || submitted) && bookOrderForm.controls.OriginId.errors?.required">
                            Please select origin Location </span>
                    </div>


                </div>
            </div>

            <!-- Select Origin Gateway -->
            <div class="form-group" *ngIf="orderType == 'LCL Express D2C'">
                <div class="row mb-2" style=" margin-top: 18px;">
                    <div class="col-sm-2">
                        <label class="control-label add-member-label">Origin Gateway:</label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>

                    <div class="col-sm-5">
                        <mat-select class="form-control" style="width: 299px;" placeholder="Select Origin Gateway"
                            class="form-control" formControlName="OriginGatewayId"
                            (selectionChange)='onGatewaySelection($event)'>
                            <mat-option>
                                <ngx-mat-select-search [(ngModel)]="GatewaySearch" [ngModelOptions]="{standalone: true}"
                                    [placeholderLabel]="'--Select Gateway'" [noEntriesFoundLabel]="'Not found'"
                                    [closeIcon]="''">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of GatewayList |stringFilterBy:GatewaySearch:'ZoneName'"
                                [value]="item.ZoneName">{{item.ZoneName}}</mat-option>
                        </mat-select>
                        <br>
                        <span class="text-danger ml-3"
                            *ngIf="(bookOrderForm.controls.OriginGatewayId.touched || submitted) && bookOrderForm.controls.OriginGatewayId.errors?.required && isD2c ">
                            Please Select Origin Address
                        </span>
                    </div>
                </div>
            </div>
            <!-- select Origin Gateway Branch -->
            <div class="form-group" *ngIf="orderType == 'LCL Express D2C'">
                <div class="row">
                    <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                        <label>Gateway Branch: </label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>
                    <div class="col-sm-5">
                        <input style="width:301px !important;" type="text" class="form-control" disabled
                            formControlName="GatewayBranch" placeholder=" Gateway Branch" required />

                        <div class="text-danger ml-3" style="margin: 0px;"
                            *ngIf="(bookOrderForm.controls.GatewayBranch.touched ) && bookOrderForm.controls.GatewayBranch.errors?.required">
                            Select Gateway Branch
                        </div>
                    </div>

                </div>
            </div>
            <!-- select Origin Gateway Location -->
            <div class="form-group" *ngIf="orderType == 'LCL Express D2C'">
                <div class="row">
                    <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                        <label>Gateway Location: </label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>
                    <div class="col-sm-5">
                        <input style="width:301px !important;" type="text" class="form-control" disabled
                            formControlName="GatewayLocation" placeholder=" Gateway Location" required />

                        <div class="text-danger ml-3" style="margin: 0px;"
                            *ngIf="(bookOrderForm.controls.GatewayLocation.touched ) && bookOrderForm.controls.GatewayLocation.errors?.required">
                            Select Gateway Location
                        </div>
                    </div>

                </div>
            </div>
            <!-- Select Destination Address -->
            <div class="form-group" *ngIf="orderType=='LCL Direct - Delivery'">
                <div class="row mb-2" style=" margin-top: 18px;">
                    <div class="col-sm-2">
                        <label class="control-label add-member-label">Destintion Address:</label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>


                    <div class="col-sm-5">
                        <mat-select class="form-control" style="width: 299px;"
                            placeholder="Select Destination/Pincode/Location/District" class="form-control"
                            formControlName="DestLocationId" (selectionChange)='onDestLocationSelection($event)'>
                            <mat-option>
                                <ngx-mat-select-search [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="DestLocationSearch"
                                    (ngModelChange)="originLocationSearch(DestLocationSearch,'Dest')"
                                    style="width:301px;"
                                    matTooltip="{{DestLocationSearch == null || (DestLocationSearch != null && DestLocationSearch.length < 3) ? 'Enter at least 3 letters' : ''}}"
                                    [placeholderLabel]="'Enter Destination/Pincode/Location/District'"
                                    [noEntriesFoundLabel]="'Not found'" [closeIcon]="''">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of DestLocationList"
                                [value]="item.LookupId">{{item.LookupName}}</mat-option>
                        </mat-select>
                        <br>
                        <span class="text-danger ml-3"
                            *ngIf="(bookOrderForm.controls.DestLocationId.touched || submitted) && bookOrderForm.controls.DestLocationId.errors?.required">
                            Please select Destination
                        </span>
                    </div>
                </div>
            </div>
            <!-- Select Destination Location -->
            <div class="form-group" *ngIf="orderType == 'LCL Direct - Delivery'">
                <div class="row ">
                    <div class="col-sm-2  mr-0" style="vertical-align: middle;">
                    </div>
                    <div class="col-sm-10">
                        <div class="row" style="width:65%">
                            <div class="col-sm-5">
                                <!-- destination Locations Dropdown -->
                                <mat-select class="form-control" style="width: 299px;"
                                    placeholder="Select Destination Drop Location" formControlName="DestId"
                                    (selectionChange)='onDestSelection($event)'>
                                    <mat-option>
                                        <ngx-mat-select-search [(ngModel)]="DestSearch"
                                            [ngModelOptions]="{standalone: true}"
                                            [placeholderLabel]="'--Select Destination--'"
                                            [noEntriesFoundLabel]="'Not found'" [closeIcon]="''">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of destList |stringFilterBy:OriginSearch:'LocationName'"
                                        [value]="item.CustomerLocationId">{{item.LocationName}}</mat-option>
                                </mat-select>
                            </div>
                            <div class="col-sm-1 mt-2" style="text-align: center;">
                                OR
                            </div>
                            <div class="col-sm-6" style="padding: 0px;">
                                <div class="row">

                                    <div class="col-sm-9" style="padding: 0px;">
                                        <!-- Origin Location Map -->
                                        <input style="width: 270px!important;" type="text" class="form-control"
                                            formControlName="DestMap" placeholder="Destination Location from Map"
                                            disabled='isMapLocationNotEditable' />
                                    </div>
                                    <div class="col-sm-1 " id="icon" style="padding: 0px;"
                                        *ngIf="!isDestSelectedFromMap"
                                        style="color: #337ab7;padding-top: 4px;cursor: pointer;"
                                        (click)="openMapForLocationCapture('Dest')"><button class="btn fa fa-crosshairs"
                                            style="background-color: #6ebed2;
                                        font-size: 15px;
                                        margin-top: -3px;
                                        margin-left: -17px;
                                        height: 33px;">
                                            <span style="margin-right: 8px;"></span>Capture
                                        </button>
                                        
                                    </div>
                                    <div *ngIf="isDestSelectedFromMap" id="icon" class="col-sm-1"
                                        style="padding-top: 4px;cursor: pointer;" (click)="cancelMapDestLocation()">
                                        <button class="btn fa fa-trash" style="background-color: #ed3434;;
                                        font-size: 15px;
                                        margin-top: -3px;
                                        margin-left: -17px;
                                        height: 33px;">
                                            <span style="margin-right: 8px;"></span>Cancel
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <p class="mt-1" style="color: #246ade;" *ngIf="bookOrderForm.controls['DestId'].value != null">
                            {{bookOrderForm.controls['DestAddress'].value}}</p>
                        <span class="text-danger ml-3"
                            *ngIf="(bookOrderForm.controls.DestId.touched || submitted) && bookOrderForm.controls.DestId.errors?.required">
                            Please select Destination
                        </span>
                    </div>


                </div>
            </div>

            <!-- Select Vehicle Type -->
            <div class="form-group" *ngIf="orderType == 'LCL Breakbulk'||orderType=='LCL Direct - Delivery'">
                <div class="row ">
                    <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                        <label>Vehicle Type: </label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>
                    <div class="col-sm-5">
                        <mat-select class="form-control" placeholder="Select vehicle Type" class="form-control"
                            style="width:301px;" formControlName="VehicleTypeId"
                            (ngModelChange)='onVehicleTypeSelection($event)'>
                            <mat-option>
                                <ngx-mat-select-search [(ngModel)]="VehicleTypeSearch"
                                    [ngModelOptions]="{standalone: true}" [placeholderLabel]="'--Select Vehicle Type--'"
                                    [noEntriesFoundLabel]="'Not found'" [closeIcon]="''">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                                *ngFor="let item of vehicleTypeList |stringFilterBy:VehicleTypeSearch:'LookupName'"
                                [value]="item.LookupId">{{item.LookupName}}</mat-option>
                        </mat-select>
                        <br>
                        <span class="text-danger ml-3"
                            *ngIf="(bookOrderForm.controls.VehicleTypeId.touched || submitted) && bookOrderForm.controls.VehicleTypeId.errors?.required && isBreakbulk">
                            Please select Vehicle Type
                        </span>
                    </div>

                </div>
            </div>
            <!-- Select Delivery Region -->
            <div class="form-group" *ngIf="orderType == 'LCL Breakbulk'">
                <div class="row ">
                    <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                        <label>Delivery Region: </label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>
                    <div class="col-sm-5">
                        <mat-select class="form-control" placeholder="Select Delivery Region" class="form-control"
                            style="width:301px;" formControlName="DeliveryZoneId"
                            (ngModelChange)='onDeliveryRegionSelection($event)'>
                            <mat-option>
                                <ngx-mat-select-search [(ngModel)]="DeliveryRegionSearch"
                                    [ngModelOptions]="{standalone: true}" [disabled]="isStateSelected"
                                    [placeholderLabel]="'--Select Vehicle Type--'" [noEntriesFoundLabel]="'Not found'"
                                    [closeIcon]="''">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                                *ngFor="let item of deliveryRegionList |stringFilterBy:DeliveryRegionSearch:'ZoneName'"
                                [value]="item.ZoneId">{{item.ZoneName}}</mat-option>
                        </mat-select>
                        <br>

                        <span class="text-danger ml-3"
                            *ngIf="(bookOrderForm.controls.DeliveryZoneId.touched ) && bookOrderForm.controls.DeliveryZoneId.errors?.required && isBreakbulk">
                            Please select Delivery Region
                        </span>
                    </div>

                </div>
            </div>

            <!-- Select Delivery State -->
            <div class="form-group" *ngIf="orderType == 'LCL Breakbulk'">
                <div class="row ">

                    <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                        <label>Delivery State: </label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>
                    <div class="col-sm-5">
                        <mat-select multiple #select class="form-control" placeholder="Select Delivery State"
                            class="form-control" style="width:301px;" formControlName="DeliveryStateIdFE"
                            (ngModelChange)='onStateSelection($event)'>
                            <mat-option>
                                <ngx-mat-select-search [(ngModel)]="selectedStates"
                                    [ngModelOptions]="{standalone: true}"
                                    [placeholderLabel]="'Select Delivery State(s)'" [noEntriesFoundLabel]="'Not found'"
                                    [closeIcon]="''">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                                *ngFor="let item of customStateListForDropDown |stringFilterBy:selectedStates:'label'"
                                [value]="item.value">{{item.label}}</mat-option>
                        </mat-select>
                        <br>
                        <span class="text-danger ml-3"
                            *ngIf="(bookOrderForm.controls.DeliveryStateIdFE.touched ) && bookOrderForm.controls.DeliveryStateIdFE.errors?.required && isBreakbulk">
                            Please select Delivery state
                        </span>

                    </div>


                </div>
            </div>
            <!-- Enter Tentative Weight -->
            <div class="form-group" *ngIf="orderType != 'LCL Breakbulk' && orderType!='LCL Direct - Delivery'">
                <div class="row ">
                    <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                        <label>Tentative Weight: </label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>
                    <div class="col-sm-5">
                        <input min="0" (keypress)="onlyNumbers($event)" style="width:301px;" type="number"
                            class="form-control" formControlName="TentativeWeight" placeholder="Enter Tentative Weight"
                            required />

                        <div class="text-danger ml-3" style="margin: 0px;"
                            *ngIf="(bookOrderForm.controls.TentativeWeight.touched || submitted) && bookOrderForm.controls.TentativeWeight.errors?.required">
                            Please enter Tentative Weight
                        </div>
                    </div>

                </div>
            </div>

            <!-- Enter POC Name -->
            <div class="form-group">
                <div class="row">
                    <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                        <label>POC Name: </label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>
                    <div class="col-sm-5">
                        <input style="width:301px !important;" type="text" class="form-control"
                            formControlName="POCName" placeholder="Enter POC Name" required />

                        <div class="text-danger ml-3" style="margin: 0px;"
                            *ngIf="(bookOrderForm.controls.POCName.touched || submitted) && bookOrderForm.controls.POCName.errors?.required">
                            Please enter POC Name
                        </div>
                    </div>

                </div>
            </div>



            <!-- Enter POC Number -->
            <div class="form-group">
                <div class="row ">
                    <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                        <label>POC Number: </label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>
                    </div>
                    <div class="col-sm-5">
                        <input maxlength="10" style="width: 301px!important;" type="text"
                            (keypress)="onlyNumbers($event)" class="form-control" formControlName="POCNumber"
                            placeholder="Enter POC Number" required />

                        <div class="text-danger ml-3" style="margin: 0px;"
                            *ngIf="(bookOrderForm.controls.POCNumber.touched || submitted) && bookOrderForm.controls.POCNumber.errors?.required">
                            Please enter POC Number
                        </div>
                    </div>

                </div>
            </div>
            <!-- Enter Freight -->
            <div class="form-group" *ngIf="orderType == 'LCL Breakbulk'||orderType=='LCL Direct - Delivery'">
                <div class="row">
                    <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                        <label>Freight </label>
                        <span class="red" style="position: relative; bottom: 2px; left: 3px; color: red;">*</span>

                    </div>
                    <div class="col-sm-5">
                        <input style="width:301px !important;" type="text" class="form-control" min="0"
                            (keypress)="onlyNumbers($event)" formControlName="FreightAmount"
                            placeholder="Enter Freight" />


                        <div class="text-danger ml-3" style="margin: 0px;"
                            *ngIf="(bookOrderForm.controls.FreightAmount.touched || submitted) && bookOrderForm.controls.FreightAmount.errors?.required">
                            Please enter Freight
                        </div>
                    </div>

                </div>
            </div>
            <!-- Enter Remarks -->
            <div class="form-group">
                <div class="row ">
                    <div class="col-sm-2  mr-0" style="vertical-align: middle;">
                        <label>Remarks: </label>
                    </div>
                    <div class="col-sm-5">
                        <textarea style="width: 301px!important;padding: 5px;" class=" " formControlName="Remarks"
                            placeholder="Enter Remarks" cols="20"></textarea>



                    </div>

                </div>
            </div>



            <div class="form-group text-center">
                <button type="submit" class="btn btn-success btn-book" [disabled]='isBookNewOrderClicked'
                    (click)="bookOrder()">Book Order</button>
            </div>
        </div>
    </div>
</mat-card>
<div *ngIf="Spinner">
    <div class="col-xs-12" id="Spinn">
        <div class="loaderr"><span>Loading...</span></div>
    </div>
</div>