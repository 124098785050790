import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  sessionDetails: any;
  headers: { headers: any; };

  constructor(private _http: HttpClient) {
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
   }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

    //to recent messages
    getRecentMessages(userType){
      return this._http.get(environment.apiUrl+"api/get-recent-messages?userType=" + userType);
    }
    //to messages list
    getMessages(id, type){
      return this._http.get(environment.apiUrl+"api/get-messages?id=" + id + "&type=" + type);
    }
    //to send message
    sendMessage(data) {
      return this._http.post(environment.apiUrl + 'api/send-message', data);
    }
     //
  getCustomersList(userType, keyword){
    return this._http.get(environment.apiUrl+"api/get-customers-list?userType=" + userType + "&keyword=" + keyword);
  }

}