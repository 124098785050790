<div *ngIf="access == true">
    <div class="row">
        <div class="col-xl-11">
            <a routerLinkActive="active" class="btn btn-primary ml-2 w-130  fa fa-credit-card-alt" 
            [routerLink] = "['./manage-payables']" style="background-color:#0077bd ;">  <span
            style="font-family: Arial, Helvetica, sans-serif;"> Manage Payables</span></a>
        </div>
    </div>
    
        <div class="row">
            <div class="col-xl-12 mt-5">
                <router-outlet></router-outlet>
            </div>
        </div>
</div>

<div *ngIf="access == false">
    <p class="text-center">You don't have permission to access this screen, please contact administrator.</p>
</div>
