<div class="MarginClass">
    <i class="fa fa-times" aria-hidden="true" (click)="close()"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;cursor: pointer;"></i>
    <h2 mat-dialog-title>Edit Profile</h2>
</div>

<div class="col-sm-12">

    <form class="form-horizontal" novalidate [formGroup]="addEditForm">


        <div class="image-wrapper d-flex justify-content-center">
            <i *ngIf="image" class="fa fa-times delete-img" (click)="removeImage()"></i>
            <img src={{image}} *ngIf="image" alt="Profile" width="40%" height="40%"
                style="border-radius: 30%; height: 150px;">
            <!-- <img [src]="Url" *ngIf="!image && Url" width="40%" height="40%" style="border-radius: 30%; height: 150px;"> -->
            <img src="assets/img/app/no-image.png" *ngIf="!image" style="border-radius: 30%; height: 150px;" width="40%"
                height="40%">
        </div>
        <input type="file" (change)="fileChange(input)" #input class="img-upload-btn d-flex justify-content-center"
            width="40%" />

        <button class="btn btn-info btn-block rounded-0 d-flex justify-content-center" type="button" width="40%">
            <span *ngIf="!image"><i class="fa fa-upload"></i> Select Image... </span>
            <span *ngIf="image"><i class="fa fa-refresh"></i> Change Image... </span>
        </button>
        <div class="row mt-3">
            <div class="form-group col-sm-6">
                <label class="form-control-label">Company Name</label>
                <input class="form-control" formControlName="CustomerName" type="text" disabled readonly
                    style="cursor:not-allowed">
            </div>
            <div class="form-group col-sm-6">
                <label class="form-control-label">Company Code</label>
                <input class="form-control" formControlName="Code" type="text" disabled readonly
                    style="cursor:not-allowed">
            </div>
        </div>
        <div class="row">
            <div class="form-group col-sm-6">
                <label class="form-control-label">Company PAN</label>
                <input class="form-control" formControlName="PAN" type="text" disabled readonly
                    style="cursor:not-allowed">
            </div>
            <div class="form-group col-sm-6">
                <label class="form-control-label">User Email</label>
                <input class="form-control" formControlName="Email" type="text" disabled readonly
                    style="cursor:not-allowed">
            </div>

        </div>
        <div class="row">
            <div class="form-group col-sm-6">
                <label class="form-control-label">User Name</label>
                <input class="form-control" formControlName="FirstName" type="text">
            </div>
            <div class="form-group col-sm-6">
                <label class="form-control-label">User Phone </label>
                <input type="text" formControlName="Phone" class="form-control" (keypress)="onlyNumbers($event)"
                    placeholder="Enter Phone" minlength="10" maxlength="10" required>
                <div *ngIf="!addEditForm.controls.Phone.valid && (addEditForm.controls.Phone.dirty || addEditForm.controls.Phone.touched)"
                    class="error-text">
                    <div *ngIf="addEditForm.controls.Phone.errors.required">
                        Phone is required
                    </div>
                    <div *ngIf="addEditForm.controls.Phone.errors.minlength" class="format">
                        Mobile No should contain 10 numbers
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="form-control-label">Company Address</label>
            <input style="width:100% !important;" class="form-control" formControlName="Address"
                style="border-color:1px solid black" type="text" disabled readonly style="cursor:not-allowed">
        </div>
    </form>


</div>



<mat-dialog-actions align="center" style="padding-bottom: 10px ;margin-right: 12px;">
    <button *ngIf="!isSubmit" class="btn btn-primary fa fa-refresh" (click)="onUpdate()"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white;width: 159px;">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Update</span>
    </button>
    <button *ngIf="isSubmit" disabled readonly  class="btn btn-primary fa fa-refresh" (click)="onUpdate()"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white;width: 159px; cursor: not-allowed;">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Update</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
    <button class="btn btn-tranperent fa fa-key" (click)="openUserDialog(null)"
        style="margin-left: 10px ; background-color: #378d3b !important; color: white;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Change Password</span></button>
</mat-dialog-actions>