<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
    <h1 mat-dialog-title>Upload CSV</h1>
</div>
<div mat-dialog-content>
    <form class="form-horizontal" novalidate [formGroup]="addEditForm">
        <div class="form-group row">

            <label class="control-label col-sm-4 add-member-label bold">Upload Document:</label>
            <div class="col-sm-8">
                <input type="file" accept="text/csv" class="form-control" placeholder="Upload file..." formControlName="Doc"
                    (change)="handleFileInput($event.target.files)" style="margin-top:3px!important; cursor: pointer;"
                    required>
                <div *ngIf="!addEditForm.controls.Doc.valid && (addEditForm.controls.Doc.dirty || addEditForm.controls.Doc.touched)"
                    class="error-text">
                    <div *ngIf="addEditForm.controls.Doc.errors.required">
                        Document Upload is required
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
    <button *ngIf="showDownloadCSV == true" mat-raised-button
    style="cursor:pointer;left:6px;background-color: #286ba5; color: white;" (click)="downloadCSV()">
    Download CSV
</button>
    <button class="btn btn-primary fa fa-paper-plane "(click)="saveData()"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Upload</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
</mat-dialog-actions>