import { Component, OnInit } from '@angular/core';
import { ServiceService } from './service.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
  count: any;
  orderbydistance:any
  constructor(private _tripService: ServiceService) { }

  userData: any = [];
  access : boolean = false;

  ngOnInit(): void {
    this.userData=JSON.parse(localStorage.getItem('userSession'));
    if(this.userData['Role'] === "Operations" || this.userData['Administrator'] === true || this.userData['Role'] === "Operations & Financial")
    {
      this.access = true;
      this.getCountForTabs();
    }
    else{
      this.access = false;
    }
  }
  public getCountForTabs(): void {
    this._tripService.GetCountTabs().subscribe((res) => {
      this.count = res["Data"];
      
    })
  }

  

}
