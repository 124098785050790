<div class="row" *ngIf="access==true">
    <div class="col-xl-12 mt-4">
    
        <!-- <div class="col-xl-6 mb-4">
            <div widget class="card border-0 box-shadow">
                <div class="card-header transparent border-0 text-muted">
                    <h5 class="mb-0">Vertical Bar Chart</h5>
                </div>
                <div class="card-body widget-body">
                    <div class="w-100 h-300p">
                        <ngx-charts-bar-vertical [scheme]="colorScheme" [gradient]="gradient" [xAxis]="showXAxis"
                            [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                            [results]="multi">
    
                            <ng-template #tooltipTemplate let-model="model">
                                <div>
                                    Rating : 5 <br>
                                    Grade : Very Poor
                                </div>
                                <!-- <div *ngIf="model.value > 36 && model.value <= 48">
                                  Rating : 4 <br>
                                  Grade : Poor
                                </div> -->
        <!-- <div *ngIf="model.value > 24 && model.value <= 36">
                                  Rating : 3 <br>
                                  Grade : Average
                                </div>
                                <div *ngIf="model.value > 12 && model.value <= 24">
                                  Rating : 2 <br>
                                  Grade : Good
                                </div>
                                <div *ngIf="model.value <= 12">
                                  Rating : 1 <br>
                                  Grade : Excellent
                                </div> -->
        <!-- </ng-template>
                        </ngx-charts-bar-vertical>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xl-9 mb-3 " style="min-height: 500 !important;">
    
                <div widget style="min-height: 500 !important;" class="card border-0 box-shadow">
                    <div class="card-header transparent border-0 text-muted">
                        <h5 class="d-flex justify-content-center">Branch</h5>
    
                    </div>
                    <div class="float-right">
                        <p class="float-right mr-3">
    
                            <i style="cursor: pointer;font-size: 30px;color: #286ba5;"
                                class="material-icons fa fa-info-circle" data-bs-toggle="collapse"
                                data-bs-target="#collapseWidthExample" aria-expanded="false"
                                aria-controls="collapseWidthExample" ngTooltip="View Info" *ngIf="!infoClicked"
                                (click)="onInfoClick('info')"></i>
                            <i style="cursor: pointer;font-size: 30px;color: #d54540;"
                                class="material-icons expand-hover fa fa-times-circle-o" data-bs-toggle="collapse"
                                data-bs-target="#collapseWidthExample" aria-expanded="false"
                                aria-controls="collapseWidthExample" ngTooltip="Close" *ngIf="infoClicked"
                                (click)="onInfoClick('cancel')"></i>
                        </p>
                    </div>
                    <div style="min-height: 120px;position:absolute;top: 55px;right: 15px; margin-top: 25px;"
                        *ngIf="infoClicked">
                        <div>
                            <div class="card card-body" style="width: 300px;">
                                <table class="table table-bordered table-hover">
                                    <thead class="tbl-head ">
                                        <tr class="tr-grid-header">
                                            <th>Hours</th>
                                            <th>Rating</th>
                                            <th>Grade</th>
                                        </tr>
    
                                    </thead>
    
                                    <tbody>
                                        <tr *ngFor="let item of statusRating,let i=index">
                                            <td>{{item.hours}} </td>
                                            <td>{{item.Rating}}</td>
                                            <td>
                                        <tr>
                                            <td style="border:none;width:80px;text-align:left !important">{{item.Grade}}
                                            </td>
                                            <td style="border:none"><i class="fa fa-square"
                                                    [ngStyle]="{'color': statusRatingColors[i] }"></i>
                                            </td>
                                        </tr>
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body widget-body">
                        <div style="max-width: 100%; overflow: auto;" #chartArea>
                            <ngx-charts-bar-vertical [view]="[1000,390]" [scheme]="colorSchemeb" [results]="bardatabranch"
                                [gradient]="gradientb" [xAxis]="showXAxisb" [showDataLabel]="showDataLabelb"
                                [yAxis]="showYAxisb" [showXAxisLabel]="showXAxisLabelb" [showYAxisLabel]="showYAxisLabelb"
                                [xAxisLabel]="xAxisLabelb" [yAxisLabel]="yAxisLabelb" [yAxisTicks]=[24,48,62,96]
                                (select)="chartsClicked('branch')">
                                <ng-template #tooltipTemplate let-model="model">
                                    <div *ngIf="model.value > 96">
                                        Rating : 5 <br>
                                        Grade : Very Poor
                                    </div>
                                    <div *ngIf="model.value > 62 && model.value <= 96">
                                        Rating : 4 <br>
                                        Grade : Poor
                                    </div>
                                    <div *ngIf="model.value > 48 && model.value <= 62">
                                        Rating : 3 <br>
                                        Grade : Average
                                    </div>
                                    <div *ngIf="model.value > 24 && model.value <= 48">
                                        Rating : 2 <br>
                                        Grade : Good
                                    </div>
                                    <div *ngIf="model.value <= 24">
                                        Rating : 1 <br>
                                        Grade : Excellent
                                    </div>
                                </ng-template>
                            </ngx-charts-bar-vertical>
                        </div>
                    </div>
                </div>
    
            </div>
    
    
    
    
            <div class="col-xl-3">
    
                <div widget class="card border-0 box-shadow">
                    <div class="card-header transparent border-0 text-muted">
                        <h5 class="d-flex justify-content-center">Average</h5>
    
                    </div>
                    <div class="card-body widget-body">
                        <div style="max-width: 100%; overflow: auto;" #chartArea>
                            <ngx-charts-bar-vertical [scheme]="colorScheme" [view]="[400,435]" [gradient]="gradient"
                                [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [showDataLabel]="true"
                                [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [results]="piedataAverage">
                            </ngx-charts-bar-vertical>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    
        <div class="row mt-3 d-flex justify-content-right">
            <div class="col-sm-12 ml-2 d-flex justify-content-right">
                <button type="button" 
                    class="btn btn-dark font-weight-light mgnfilterbesidebuttons fa fa-cloud-download "
                    style=" color: aliceblue; ">
                    <span style="font-family: Arial, Helvetica, sans-serif;"> Download CSV</span>
                </button>
                <button type="button"  
                    class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-share-square-o"
                    style="color: aliceblue;"> <span style="font-family: Arial, Helvetica, sans-serif;"> Share CSV</span>
                </button>
                <!-- <button type="button" mat-raised-button (click)="openManageGridDialog()"
                    class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-address-book"
                    style="float: right;  color: aliceblue;"> <span style="font-family: Arial, Helvetica, sans-serif;">
                        Manage Grid</span>
                </button> -->
                <button type="button" 
                    class="btn btn-dark font-weight-light mgnfilterbesidebuttons ml-2 fa fa-filter"
                    >
                    <span style="font-family: Arial, Helvetica, sans-serif;"> Filter</span>
                </button>
            </div>
        </div>
    
        <div>
            <table id="example3 " class="table table-bordered table-hover .table-fixed mt-2" matSort>
    
                <thead class="tbl-head">
                    <tr class="tr-grid-header">
                        <!-- <th colspan="100" class="customerName text-center v-a-m">Grid Name</th> -->
                    </tr>
                    <tr class="tr-grid-header ">
                        <th>Branch</th>
                        <th>Zone </th>
                        <th>Vehicle Number</th>
                        <th> Vehicle Type </th>
                        <th>Capacity Type </th>
                        <th>Order Id </th>
                        <th>Customer </th>
                        <th> XPCN</th>
                        <th>Date & Time(In)</th>
                        <th>Date & Time(Out) </th>
                        <th>Activity Loading or Unloading</th>
                        <th>Time Taken </th>
                        <th> Status</th>
                    </tr>
                    <tr class="tr-grid-header">
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="customerTable?.length == 0 ">
                        <td class="text-center" colspan="100">No records found</td>
                    </tr>
                    <tr *ngFor="let data of customerTable">
                        <td>{{data.Branch}}</td>
                        <td>{{data.zone}}</td>
                        <td>{{data.VehicleNumber}}</td>
                        <td>{{data.VehicleType}} </td>
                        <td>{{data.CapacityType}} </td>
                        <td>{{data.OrderId}} </td>
                        <td>{{data.Customer}} </td>
                        <td> {{data.XPCN}}</td>
                        <td> {{data.DateTimein}}</td>
                        <td>{{data.DateTimeout}}</td>
                        <td>{{data.ActivityLoadingorunloading}} </td>
                        <td>{{data.TimeTaken}} </td>
                        <td>{{data.Status}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    </div>
    <div *ngIf="access == false">
        <p class="text-center dj">Work In Progress.........</p>
    </div>