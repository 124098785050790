import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  headers: { headers: any; };

  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }  

  CPGetBookingsForGenerateXPCN(filter) {
    return this._http.post(environment.apiUrl + 'api/cp-get-lcl-bookings-for-generate-xpcn', filter);
  }
  CPGetcustomermis(filter) {
    return this._http.post(environment.apiUrl + 'api/get-customer-mis', filter);
  }


  getdelivereddata(filter) {
    return this._http.post(environment.apiUrl + 'api/get-delivered-records', filter);
  }
  getcancelledrecord(filter) {
    return this._http.post(environment.apiUrl + 'api/cp_get-all_Cancelled_record', filter);
  }
  CPGetXPCNPrintStickers(filter) {
    return this._http.post(environment.apiUrl + 'api/cp-get-lcl-xpcn-print-sticker', filter);
  }

  CPGetXPCNForTrack(filter) {
    return this._http.post(environment.apiUrl + 'api/cp-get-lcl-xpcn-track', filter);
  }

  getWarehouseDetailsByBookingId(bookingId,warehouseType){
    return this._http.get(environment.apiUrl + 'api/get-booking-warehouse-details?bookingId=' + bookingId+"&warehouseType="+warehouseType);
  }

  updateReadyForPickupLCLExpressMilkrun(bookingId,distributionCenterId){
    return this._http.get(environment.apiUrl + 'api/close-add-xpcns-express-milkrun?bookingId=' + bookingId+"&distributionCenterId="+distributionCenterId);
  }

  closeaddxpcntab(data) {
    return this._http.get(environment.apiUrl + 'api/close-add-xpcns?int_BookingId=' + data);
  }

  getXPCNDetails(bookingId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-details-by-bookingid?bookingId=' + bookingId);
  }

  addOrderAndXPCNLCL(bookingId) {
    return this._http.get(environment.apiUrl + 'api/insert-lcl-order-and-xpcn-details?bookingId=' + bookingId);
  }
  
  getCancellationxpcnbyxpcnId(xpcnId) {
    return this._http.get(environment.apiUrl + 'api/get-cancellation-xpcn-by-xpcnid?xpcnId=' + xpcnId)
  }

  getVACReasons() {
    return this._http.get(environment.apiUrl + 'api/get-vac-reasons-details');
  }

  getLCLXPCNDetailsForFinzalization(int_lcl_xpcn_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-details-for-finalization?int_lcl_xpcn_id=' + int_lcl_xpcn_id);
  }

  checkxpcnfinalizestatus(ID){
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-finalise-status?xpcnId='+ ID)
  }

  searchLCLEWayBill(xpcnId,ewaybillNo,consignorStateId){
    return this._http.get(environment.apiUrl + 'api/search-lcl-ewaybill?xpcnId=' + xpcnId + '&ewaybillNo=' + ewaybillNo+'&consignorStateId='+consignorStateId);
  }
  //to get the list of states to bind the dropdown
  GetStatesDropdownlist() {
    return this._http.get(environment.apiUrl + 'api/get-stateslist')
  }

  //to get the list of districts by stateid's
  GetDistrictsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-districtlistbystateid?StateId=' + data)
  }

  // to get the list of locations by districtid's
  GetLocationsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-locationlistbystateid?DistrictId=' + data)
  }

  // to get the list of locations by districtid's
  GetPostcodesDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-postcodes-by-location?LocationId=' + data)
  }

  saveLCLXPCNInvoice(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-xpcn-invoice-lcl', data);
  }

  deleteLCLXPCNInvoice(data){
    return this._http.post(environment.apiUrl + 'api/delete-xpcn-invoice-lcl', data);
  }

  saveLCLXPCNWaybill(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-xpcn-waybill-lcl', data);
  }

  deleteLCLXPCNWayBill(data){
    return this._http.post(environment.apiUrl + 'api/delete-xpcn-waybill-lcl', data);
  }

  finalizeLCLXPCN(data){
    return this._http.post(environment.apiUrl + 'api/finalize-lcl-xpcn', data);
  }
  // to get finalize xpcn
  upsertLCLOrderXPCNBoxes(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-lcl-order-xpcn-boxes', data);
  }

  getLCLOrderXPCNBoxes(int_lcl_xpcn_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-xpcn-boxes?int_lcl_xpcn_id=' + int_lcl_xpcn_id);
  }
  
  getConsigneeDetails() {
    return this._http.get(environment.apiUrl + 'api/get-consignee-for-finalize-lcl-xpcns')
  }
  
  getConsigneeDetailsbyId(id) {
    return this._http.get(environment.apiUrl + 'api/get-consignee-for-finalize-lcl-xpcns-by-id/' + id)
  }
  
  CheckGatewayForDistrict(id,xpcnId) {
    return this._http.get(environment.apiUrl + 'api/get-geteway-by-consignee-district?districtId=' + id +'&xpcnId=' + xpcnId);
  }

  deleteXPCNBoxesConsignor(boxId) {
    return this._http.get(environment.apiUrl + 'api/delete-xpcn-box-consignor?boxId=' + boxId);
  }

  upsertLCLOrderXPCNBoxesAll(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-lcl-order-xpcn-boxes-all-including-deleted', data);
  }
  
  updatedirectdeliveryReadypickup(item){
    return this._http.get(environment.apiUrl + 'api/update-direct-delivery-close-add-xpcn?int_BookingId='+item);
  }

  EditLCLCancellation(int_booking_id,Reason)
  {
    return this._http.get(environment.apiUrl + 'api/cancel-lcl-booking?int_booking_id='+int_booking_id+'&Reason='+Reason);
  }

  getCustomerDetailsfromGSTINBilling(gstin) {
    return this._http.get(environment.apiUrl + 'api/get-customer-details-by-gstin-billing?gstin=' + gstin)
  }

  getFreight(data){
    return this._http.post(environment.coreApiUrl + 'api/v2/get-freight-details-from-rates', data);
  }

}
