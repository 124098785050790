import { Injectable } from '@angular/core';
import { Settings } from './app.settings.model';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

export class GlobalFilter {
    public FromDate = null;
    public ToDate = null;
    public keyword= null;
    public Category=null;
  }

@Injectable()
export class AppSettings {
    fromDate = moment().startOf('month').toDate();
    toDate = moment().endOf('month').toDate();
    public GlobalFilter :BehaviorSubject<GlobalFilter> = new BehaviorSubject<GlobalFilter>({FromDate:this.fromDate,ToDate:this.toDate,keyword:null,Category:null});
    public RecievablesGlobalFilter :BehaviorSubject<GlobalFilter> = new BehaviorSubject<GlobalFilter>({FromDate:this.fromDate,ToDate:this.toDate,keyword:null,Category:null});
    public settings = new Settings(
        'XP India',
        'Angular Admin Template with Bootstrap 4',
        {
            menu: 'vertical', //horizontal , vertical
            menuType: 'default', //default, compact, mini
            showMenu: true,
            navbarIsFixed: true,
            footerIsFixed: false,
            sidebarIsFixed: true,
            showSideChat: false,
            sideChatIsHoverable: true,
            skin: 'combined' //'combined'  //light , dark, blue, green, combined, purple, orange, brown, grey, pink          
        }
    )
}