<div class="dropdown d-inline-block">
    <a title="Click here to view Messages" style="width: 45px;
    padding: 1px 4px 0px 0px !important;" [routerLink]="['/xp/chat']" (click)="updateNewMessageCount()">
        <i class="fa fa-envelope-o" aria-hidden="true" style="font-size: 22px;">
        </i>
        <span class="badge badge-notify" style="right: 10px;
        bottom: 12px;
        position: relative;
        border-radius: 10px; font-size: 12px;
        background: red;">{{messageCount}}</span>
    </a>
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="user-menu" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        <b>{{userName}}</b>
        <i class="fa fa-user-o" aria-hidden="true" style="margin-left: 10px; font-size: 18px;"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-right rounded-0 mt-3 p-0 box-shadow" aria-labelledby="user-menu">
        <div class="user-menu">
            <div class="user-info text-center p-3">
                <img src="{{image}}" *ngIf="image" alt="user-img" class="user-img rounded-circle">
                <img src="assets/img/app/no-image.png" *ngIf="!image" alt="user-img" class="user-img rounded-circle">
                <p class="mt-1 mb-0">
                    {{name}} <br> <small>{{roleName}}</small>
                </p>
            </div>
            <a class="dropdown-item" (click)="openUserDialogs(null)"><i class="fa fa-user mr-2"></i>Profile</a>
            <a class="dropdown-item mb-1" routerLink="/login" (click)="logoutUser()"><i
                    class="fa fa-power-off mr-2"></i>Log out</a>

        </div>
    </div>
</div>