import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderXpcnDialogComponent } from 'src/app/pages/shared/order-xpcn-dialog/order-xpcn-dialog.component';
import { OrderXplsDialogComponent } from 'src/app/pages/shared/order-xpcn-dialog/order-xpls-dialog/order-xpls-dialog.component';
import { SharedService } from 'src/app/pages/shared/shared.service';

@Component({
  selector: 'app-addxpcn-dialog',
  templateUrl: './addxpcn-dialog.component.html',
  styleUrls: ['./addxpcn-dialog.component.scss']
})
export class AddxpcnDialogComponent implements OnInit {
  xpcnDetails;
  orderId;
  goodsDesc = [];
  packageTypes = [];
  invoices: any = [];
  waybills: any = [];
  riskTypes = [];
  freightModes = [];
  gst = 0;
  rate = 0;
  list = [];


  xplsDetails;
  xplsId;
  vias = [];
  originPostcodes = [];
  destPostcodes = [];
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _booking: SharedService) { }

  ngOnInit(): void {
    if (this.data != null && this.data['Data'] != null) {
      this.list = this.data['Data']['Orders'];
    }
  }
  close(): void {
    this.dialogRef.close();
  }
  click = 0;
  openXPCNPreview(item) {
    let xpcn = {
      XPCNId: item.XPCNId
    }
    if (this.click == 0) {
      this.click++;
      this._booking.getFinalizeXPCN(xpcn).subscribe(data => {
        if (data != null && data['Data'] != null) {

          this.xpcnDetails = data['Data'];
          this.goodsDesc = data['Data'].GoodsDescs;
          this.packageTypes = data['Data'].PackageTypes;
          this.riskTypes = data['Data'].RiskTypes;
          this.freightModes = data['Data'].FreightModes;
          this.invoices = data['Data'].Invoices;
          this.waybills = data['Data'].WayBills;

          this.gst = data['Data'].CustomerGST;
          this.rate = data['Data'].Rate;

          let dialogRef = this.dialog.open(OrderXpcnDialogComponent, {
            data: { xpcn: this.xpcnDetails, invoices: this.invoices, waybills: this.waybills, showFreight: false },
            height: 'auto',
            width: '900px',
            autoFocus: false
          });
        }
        this.click = 0;
      });
    }

  }

  openXPLSPreview(item) {
    let xpsl = {
      OrderId: item.OrderId,
      XPLSId: item.XPCNId
    }
    if (this.click == 0) {
      this.click++;
      this._booking.getFinalizeXPLS(xpsl).subscribe(data => {
        if (data != null && data['Data'] != null) {

          this.xplsDetails = data['Data'];
          this.originPostcodes = data['Data'].OriginPostcodes;
          this.destPostcodes = data['Data'].DestinationPostcodes;
          this.vias = data['Data'].Vias;
          this.packageTypes = data['Data'].PackageTypes;
          this.riskTypes = data['Data'].RiskTypes;
          this.freightModes = data['Data'].FreightModes;
          this.invoices = data['Data'].Invoices;
          this.waybills = data['Data'].WayBills;

          this.gst = data['Data'].CustomerGST;
          this.rate = data['Data'].Rate;

          let dialogRef = this.dialog.open(OrderXplsDialogComponent, {
            data: { xpls: this.xplsDetails, invoices: this.invoices, waybills: this.waybills, showFreight: false },
            height: 'auto',
            width: '900px',
            autoFocus: false
          });
        }
        this.click=0;
      });
    }
  }
}
