import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-manage-grid-dialog',
  templateUrl: './manage-grid-dialog.component.html',
  styleUrls: ['./manage-grid-dialog.component.scss']
})
export class ManageGridDialogComponent implements OnInit {
  savedHeaders = [];
  headers = [];
  screenName = '';
  checkbox: boolean = true;
  checkboxfalse: boolean = false;
  subtasks: [
    { color: 'primary'}
  ]
  constructor(public dialogRef: MatDialogRef<ManageGridDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.headers = this.data['headers'];
    this.screenName = this.data['screenName'];
    let data = localStorage.getItem(this.screenName);
    if(data != null && data != '') {
      this.savedHeaders = JSON.parse(data);
      this.headers.forEach((x, i) => {
        if(this.savedHeaders.includes(i)) {
          x.IsSelected = true;
        } else {
          x.IsSelected = false;
        }
      });
    } else {
      this.headers.forEach(x => {
        x.IsSelected = true;
      });
    }
  }

  save() {
    this.savedHeaders = [];
    this.headers.forEach((x, i) => {
      if(x.IsSelected == true) {
        this.savedHeaders.push(i)
      }
    });
    localStorage.removeItem(this.screenName);
    localStorage.setItem(this.screenName, JSON.stringify(this.savedHeaders));
    this.dialogRef.close();
  }

  close(){
    this.dialogRef.close();
  }
}
