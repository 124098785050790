import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TextValidator } from '../../customers/text_validator';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-add-invoice-dialog',
  templateUrl: './add-invoice-dialog.component.html',
  styleUrls: ['./add-invoice-dialog.component.scss']
})
export class AddInvoiceDialogComponent implements OnInit {

  today = new Date();
  minday = new Date();
  formData: FormData = new FormData();
  addEditForm:FormGroup;
  isInvoiceAdded : boolean = false
  performedAction = -1
  updatedInvoiceDetails : any[] =[]
  INSERT = 0
  UPDATE = 1
 

  isEdit = true;
  constructor(
    public dialogRef:MatDialogRef<AddInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private _pagesService: PagesService,
    private _datePipe: DatePipe,
    public fb:FormBuilder
  ) { 
    this.addEditForm = this.fb.group({
      XPCNId: new FormControl(''),
      InvoiceId: new FormControl(''),
      strOrderDate: new FormControl(''),
      InvoiceNo: new FormControl('', Validators.compose([Validators.required])),
      Date: new FormControl('', Validators.compose([Validators.required])),
      InvoiceValue: new FormControl('', Validators.compose([Validators.required])),
      DocUrl: new FormControl(''),
      Doc: new FormControl('')
    });
    this.today.setDate(this.today.getDate());
    this.minday.setDate(this.today.getDate() - 3);
  }

  ngOnInit() {
    this.addEditForm.controls['XPCNId'].setValue(this.data['xpcnId']);
    this.addEditForm.controls['strOrderDate'].setValue(this.data['orderDate']);
    if(this.data['invoice'] == null) {
      this.isEdit = false;
    } else {     
      this.addEditForm.controls['InvoiceId'].setValue(this.data['invoice']['InvoiceId']);
      this.addEditForm.controls['InvoiceNo'].setValue(this.data['invoice']['InvoiceNo']);
      this.addEditForm.controls['Date'].setValue(this.data['invoice']['Date']);
      this.addEditForm.controls['InvoiceValue'].setValue(this.data['invoice']['InvoiceValue']);
      this.addEditForm.controls['DocUrl'].setValue(this.data['invoice']['DocUrl']);
    }
  }
  close():void{
    this.dialogRef.close({isInvoiceAdded: this.isInvoiceAdded,lclXPCNId:this.data.xpcnId,updatedInvoiceDetails: this.updatedInvoiceDetails,action:this.performedAction == this.INSERT?'insert':this.performedAction == this.UPDATE?'update':'no-action'})
  }
  
  handleFileInput(files: FileList) {
    if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/pdf'];
      // const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'application/vnd.ms-excel', 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 10000000) {
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 10 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls['Doc'].setValue(null);
        return;
      }
    }
  }

  priceValidator(event) {
    return TextValidator.priceValidator(event);
  }

  saveData(action: number) {
  
    if (this.addEditForm.valid) {
      this.performedAction = action
      let startDate = new Date(this._datePipe.transform(this.addEditForm.controls['Date'].value, 'yyyy-MM-dd'));
     
        this.addEditForm.controls['Date'].setValue(startDate);
        this.formData.delete('FormData');
        this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
        this._pagesService.saveLCLXPCNInvoice(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.isInvoiceAdded = true
                this.updatedInvoiceDetails = data['Data']
                console.log(this.updatedInvoiceDetails[0])
                this.close()
              } 
            }
          }
        });
      // }
    } else {
      this.addEditForm.controls['InvoiceNo'].markAsTouched();
      this.addEditForm.controls['Date'].markAsTouched();
      this.addEditForm.controls['InvoiceValue'].markAsTouched();
    }
  }
}