import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import { PagesService } from '../pages.service';
// import { AddXpcnDialogComponent } from 'src/app/pages/shared/add-xpcn-dialog/add-xpcn-dialog.component';
import { MapDialogComponent } from '../shared/map-dialog/map-dialog.component';
import { Router } from '@angular/router';
import { DeleteBookingDialogComponent } from './delete-booking-dialog/delete-booking-dialog.component';
import { AddNewXPCNComponent } from '../shared/add-new-xpcn/add-new-xpcn.component';


@Component({
  selector: 'app-generate-xpcn',
  templateUrl: './generate-xpcn.component.html',
  styleUrls: ['./generate-xpcn.component.scss']
})
export class GenerateXpcnComponent implements OnInit {

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  filterForm: FormGroup;
  endIndex: number;
  startIndex = 1;
  appliedTopFilter: any
  filterObject: FormGroup;
  OrderList = [];
  filterBy = '';
  keyword = '';
  keywordDate = '';
  orderCount = 0;
  dateFilter = '';
  user;
  readonly DISTRIBUTION_CENTER = "Distribution Center"

  public popoverTitle: string = 'Confirm Ready For Pickup';
  public popoverMessage: string = 'Are you sure you?';



  OrderTable = [];
  filterToggle: boolean = true;
  CustomDate: boolean = false;
  excelData: any[];
  customFromDate = null;
  customToDate = null;

  constructor(private fb: FormBuilder,
    private _pageService: PagesService,
    private _datePipe: DatePipe,
    public alertService: AlertService,
    public dialog: MatDialog,
    public route: Router,
    public _downloadExcelService: DownloadExcelService

  ) {
    this.filterObject = this.fb.group({
      FilterType: new FormControl(''),
      FromDate: new FormControl(''),
      ToDate: new FormControl(''),
      StartIndex: new FormControl(''),
      GridSize: new FormControl(''),
      BranchId: new FormControl(''),
      FilterBy: new FormControl(''),
      Keyword: new FormControl(''),
      CustomerId: new FormControl('')
    })
    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      filterBy: new FormControl(""),
      Keyword: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      datefilterType: new FormControl("")

    });

    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);

  }
  ngOnInit(): void {
    this.filterForm.controls["datefilterType"].setValue('YTD');
    this.getData();
  }

  getData() {
    this._pageService.CPGetBookingsForGenerateXPCN(this.filterForm.value).subscribe((res) => {
      this.OrderList = res["Data"];
      if (this.OrderList != null && this.OrderList.length > 0) {
        this.totalSize = this.OrderList[0].TotalRecords;
      }
    })
  }


  openLCLXPCNPreview(XPCNID) {
    // this._pageService.getLCLXPCNDetailsForFinzalization(XPCNID).subscribe(data => {
    //   if (data != null && data['Data'] != null) {

    //     let xpcnDetails = data['Data'];
    //     let goodsDesc = data['Data'].GoodsDescs;
    //     let packageTypes = data['Data'].PackageTypes;
    //     let riskTypes = data['Data'].RiskTypes;
    //     let freightModes = data['Data'].FreightModes;
    //     let invoices = data['Data'].Invoices;
    //     let waybills = data['Data'].WayBills;

    //     let gst = data['Data'].CustomerGST;
    //     let rate = data['Data'].Rate;

    //     // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
    //     // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
    //     let dialogRef = this.dialog.open(PreviewXpcnComponent, {
    //       data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
    //       height: 'auto',
    //       width: '900px',
    //       autoFocus: false
    //     });
    //   }
    // });
  }


  filter() {
    if (this.filterBy != '' && this.filterBy != null) {
      this.filterForm.controls['filterBy'].setValue(this.filterBy);
      if (this.filterBy != 'asndate' && this.filterBy != 'update' && this.filterBy != 'xpcndate') {
        if (this.keyword.trim() != '' && this.keyword != null) {
          this.filterForm.controls['Keyword'].setValue(this.keyword.trim());
        } else {
          this.alertService.createAlert("Please enter keyword", 0);
          return;
        }
        this.keywordDate = null;
      } else {
        this.keyword = null;
        if (this.keywordDate != '' && this.keywordDate != null) {
          this.filterForm.controls['KeywordDate'].setValue(this._datePipe.transform(this.keywordDate, 'yyyy-MM-dd'));
        } else {
          this.alertService.createAlert("Please select date", 0);
          return;
        }
      }
      this.getData();
    } else {
      this.alertService.createAlert("Please select filter by", 0);
      return;
    }
  }

  clear() {
    this.startIndex = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.totalSize = 0;
    this.filterBy = '';
    this.keyword = null;
    this.keywordDate = null;
    this.filterForm.controls['filterBy'].setValue(null);
    this.filterForm.controls['Keyword'].setValue(null);
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["EndIndex"].setValue(this.pageSize);
    this.getData();

  }

  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getData();

  }

  printStickers(id, code, width) {
    window.open(environment.apiUrl + 'InvoiceView/DownloadConsignorStickers?id=' + id + "&code=" + code + "&width=" + width, "_blank");
  }

  fromDate
  toDate

  dateFilterChanged(type) {

    this.filterForm.controls["datefilterType"].setValue(null);

    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.dateFilter = type;
    this.filterForm.controls["datefilterType"].setValue(this.dateFilter);

    if (type == 'Dates') {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } else {
        this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
        this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      }
    } else {
      this.filterForm.controls["FromDate"].setValue(null);
      this.filterForm.controls["ToDate"].setValue(null);

      this.fromDate = null;
      this.toDate = null;
    }

    this.getData();


  }

  datefilterclear() {
    this.fromDate = null;
    this.toDate = null;
    this.filterForm.controls["datefilterType"].setValue('MTD');
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.getData();

  }

  public exportAsExcel() {
    this.excelData = [];
    let list = [];

    this.filterForm.controls['EndIndex'].setValue(this.totalSize);
    this._pageService.CPGetBookingsForGenerateXPCN(this.filterForm.value).subscribe(data => {
      if (data != null) {
        list = data['Data'];
        if (list.length > 0) {
          list.forEach(element => {
            let xpcn = element.vc_xpcn.replace(',', ' | ');
            this.excelData.push({
              'Booking Date': this._datePipe.transform(element['booking_date'], 'dd-MM-y'),
              'Booking Id': element['vc_bookingid'],
              'Service Type': element['ServiceType'],
              'Customer Name': element['Customer'],
              'Customer Pick-up Location': element['PickUpAddress'],
              'Status': element['Status'],
              'Executive Name & No': element['ExecutiveName'] + '(' + element['PickupExecutiveno'] + ')',
              'XPCN(s)': xpcn
            })
          });
        }
        else {
          this.excelData.push({
            'Booking Date': '-',
            'Booking Id': '-',
            'Service Type':'-',
            'Customer Name': '-',
            'Customer Pick-up Location': '-',
            'Status': '-',
            'Executive Name & No': '-',
            'XPCN(s)': '-'
          })
        }
        this._downloadExcelService.exportAsExcelFile(this.excelData, 'Generate XPCN/LR');
      }
    })
  }

  CloseXpcnAddButton(bookingId,servicetype) {
    if(servicetype !== 'LCL Direct - Delivery'){

      this._pageService.getWarehouseDetailsByBookingId(bookingId, this.DISTRIBUTION_CENTER).subscribe(res => {
        if (res != null) {
          const data = res['Data'];
          const lclBookingDetails = data['LCLBookingDetails'];
          if (lclBookingDetails['BookingSubServiceType'] === "LCL Express Milkrun") {
            if (data['WarehouseId'] != null) {
              this._pageService.updateReadyForPickupLCLExpressMilkrun(bookingId, data['WarehouseId']).subscribe(x => {
                if (x['Success']) {
                  this.getData();
                }
              });
            }
            else {
              this.alertService.createAlert(`Distribution Center Not Available for this Customer at Pickup Pincode ${lclBookingDetails['PickupPincode']}`, 2)
            }
          }
          else {
            this._pageService.closeaddxpcntab(bookingId).subscribe(data => {
              if (data['Success']) {
                this.getData();
              }
            });
          }
        }
      });
    }
    else{
      this._pageService.updatedirectdeliveryReadypickup(bookingId).subscribe(data => {
        if (data['Success']) {
          this.getData();
        }
      })
    }
  }

  addXPCN(item) {
    if (item.canXPCNAdded) {
      let dialogRef = this.dialog.open(AddNewXPCNComponent, {
        data: item,
        height: 'auto',
        width: '500px',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data.isXpcnAdded) {
          this.getData()
        }
      });
    }
    else {
      this.alertService.createAlert("XPCN cannot be added for future bookings", 0)
    }
  }

  openMap(item, orderid, vehiclenumber) {
    let dialogRef = this.dialog.open(MapDialogComponent, {
      data: { data: item, isService: true, orderid: orderid, vehiclenumber: null },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
  }

  deleteXPCN(xpcnId) {
    this._pageService.getCancellationxpcnbyxpcnId(xpcnId).subscribe(data => {
      if (data != null && data['Success'])
        this.getData();
    });
  }

  onclickxpcn(xpcnid: any): void {
    const encryptedXpcnId = btoa(xpcnid.toString());
    this.route.navigate(['/xp/prepare-xpcn'], {
      queryParams: { x: encryptedXpcnId },
    });
  }

  deleteBooking(item){
    var list = {
      int_BookingId : item.BookingId,
      vc_bookingId : item.vc_bookingid
    }

    let dialogRef = this.dialog.open(DeleteBookingDialogComponent, {
      data: list,
      height: "auto",
      width: "600px",
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(data => {
      this.getData();
    });

  }

}




