import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cancelled-xpcn',
  templateUrl: './cancelled-xpcn.component.html',
  styleUrls: ['./cancelled-xpcn.component.scss']
})
export class CancelledXpcnComponent implements OnInit {
  filterToggle: boolean = true;
  CustomDate: boolean = false;
  filterObject: FormGroup;
  filterBy = '';
  keyword = '';
  List = [];
  excelData: any[];
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  endIndex: number;
  startIndex = 1;
  filterForm: FormGroup;
  constructor(private fb: FormBuilder, private _pageService: PagesService,
    private _datePipe: DatePipe, public _downloadExcelService: DownloadExcelService) {
    this.filterObject = this.fb.group({
      FilterType: new FormControl(''),
      FromDate: new FormControl(''),
      ToDate: new FormControl(''),
      StartIndex: new FormControl(''),
      GridSize: new FormControl(''),
      BranchId: new FormControl(''),
      FilterBy: new FormControl(''),
      Keyword: new FormControl(''),
      CustomerId: new FormControl('')
    })
    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      filterBy: new FormControl(""),
      Keyword: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      datefilterType: new FormControl("")

    });
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);


  }

  ngOnInit(): void {
    this.filterForm.controls["datefilterType"].setValue('YTD');

    this.getData();
  }
  getData() {

    this._pageService.getcancelledrecord(this.filterForm.value).subscribe(data => {
      if (data != null) {
        this.List = data['Data']['OrderAssignments'];
        this.totalSize = this.List[0]['total_records'];
      }

    })
  }

  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getData();

  }
  public exportAsExcel() {
    this.excelData = [];
    let list = [];

    this.filterForm.controls['EndIndex'].setValue(this.totalSize);
    this._pageService.getcancelledrecord(this.filterForm.value).subscribe(data => {
      if (data != null) {
        list = data['Data']['OrderAssignments'];
        if (list.length > 0) {
          list.forEach(element => {
            this.excelData.push({
              'Booking Date': this._datePipe.transform(element['booking_date'], 'dd-MM-y'),
              'XP Booking Id': element['vc_bookingid'],
              'Customer Name': element['Customer'],
              'Customer Pick-up Location': element['PickUpAddress'],
              'XPCN No': element['xpcn_code'],
              'Cancelled By': element['vc_cancelled_by'],
              'Remarks': element['vc_cancel_reason'],
              'Cancelled Date': this._datePipe.transform(element['dt_cancelledDate'], 'dd-MM-y'),


            })
          });
        }
        else {
          this.excelData.push({
            'Booking Date': '-',
            'XP Booking Id': '-',
            'Customer Name': '-',
            'Customer Pick-up Location': '-',
            'XPCN No': '-',
            'Cancelled By': '-',
            'Remarks': '-',
            'Cancelled Date': '-'

          })
        }
        this._downloadExcelService.exportAsExcelFile(this.excelData, 'cancelled-xpcn/booking');
      }
    })
  }
}


