import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/pages/login/login.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  sessionDetails: any;
  passwordMatch = true;
  isSubmitting = false;
  addEditForm:FormGroup;
  passwordChangeForm: FormGroup;
  isOneLetter = false;
  isOneCapitalLetter = false;
  isOneNumber = false;
  isOneSpecialCaharacter = false;
  isMinLength = false;
  isValidate = false;
  public TypeString:string="password";
  public TypeStringInNewPassword:string="password";
  public TypeStringInConfirmPassword:string="password";
  public isPassword:boolean=true;
  constructor(public dialogRef: MatDialogRef<any>, private _loginService :LoginService,
    public fb:FormBuilder,private alertService:AlertService) {
      this.addEditForm = this.fb.group({
        Email:new FormControl('', Validators.compose([Validators.required])),
        OldPassword:new FormControl('', Validators.compose([Validators.required])),
        NewPassword:new FormControl('', Validators.compose([Validators.required])),
        ConfirmPassword:new FormControl('', Validators.compose([Validators.required]))
      })
      this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
    }

  ngOnInit(): void {
    this.addEditForm.controls['Email'].setValue(this.sessionDetails['Email']);
  }
  close(): void {
    this.dialogRef.close();
  }

  updatePassword() {
    if (this.addEditForm.valid) {
      if (this.isOneLetter && this.isOneCapitalLetter && this.isOneNumber && this.isOneSpecialCaharacter && this.isMinLength) {
        if (this.addEditForm.controls['NewPassword'].value == this.addEditForm.controls['ConfirmPassword'].value) {
          this._loginService.changePassword(this.addEditForm.value).subscribe(data => {
            this.isSubmitting = true;
            if (data['Success']) {
              this.dialogRef.close();
            } else {
              this.isSubmitting = false;
            }
          });
        } else {
          this.alertService.createAlert('Passwords does not match', 0);
        }
      } else {
        this.alertService.createAlert('Invalid password criteria', 0);
      }
    }
  }

  onKeyPress(e) {
    debugger
    var pswd = e.value;
    //validate the length
    if (pswd.length < 8) {
      this.isMinLength = false;
    } else {
      this.isMinLength = true;
    }

    //validate letter
    if (pswd.match(/[A-z]/)) {
      if (pswd != '_') {
        this.isOneLetter = true;
      } else {
        this.isOneLetter = false;
      }
    } else {
      this.isOneLetter = false;
    }

    //validate uppercase letter
    if (pswd.match(/[A-Z]/)) {
      this.isOneCapitalLetter = true;
    } else {
      this.isOneCapitalLetter = false;
    }

    //validate special character
    if (pswd.match(/[!@#\$%\^&\_\+\<\>\.\,\=\:\;\'\?\(\)\[\]\\\/\|\*{}-]/)) {
      this.isOneSpecialCaharacter = true;
    } else {
      this.isOneSpecialCaharacter = false;
    }

    //validate number
    if (pswd.match(/\d/)) {
      this.isOneNumber = true;
    } else {
      this.isOneNumber = false;
    }

  }

  // noWhiteSpaceValidator(control: FormControl) {
  //   let isWhiteSpace = (control.value || '').trim().length === 0;
  //   let isValid = !isWhiteSpace;
  //   return isValid ? null : { 'whitespace': true };
  // }

  public ChangetextType(isPassword){
    if(isPassword){
      this.TypeString="password"
    }else{
      this.TypeString="text"
    }
  }

  public ChangetextTypeInConfirmPassword(isPassword){
    if(isPassword){
      this.TypeStringInConfirmPassword="password"
    }else{
      this.TypeStringInConfirmPassword="text"
    }
  }

  public ChangetextTypeInNewPassword(isPassword){
    if(isPassword){
      this.TypeStringInNewPassword="password"
    }else{
      this.TypeStringInNewPassword="text"
    }
  }
}
