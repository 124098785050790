import { Component, OnInit, ViewChild } from '@angular/core';
import { PagesService } from '../pages.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { MatOption } from '@angular/material/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
@Component({
  selector: 'app-mis',
  templateUrl: './mis.component.html',
  styleUrls: ['./mis.component.scss']
})
export class MisComponent implements OnInit {

  @ViewChild('allSelected') private allSelected: MatOption;

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  filterForm: FormGroup;
  downloadFilterForm: FormGroup;
  endIndex: number;
  startIndex = 1;
  appliedTopFilter: any
  filterObject: FormGroup;
  OrderList = [];
  filterStatus = [];
  excelData = [];
  filterBy = '';
  keyword = '';
  keywordDate = '';
  orderCount = 0;
  dateFilter = '';
  user;
  today = new Date();
  options = [
    //{value:'Select All',isDisabled:false},
    //{ key : 0,value: 'Select All', isDisabled: false },
    { key: 1, value: 'Material Not Ready', isDisabled: false },
    { key: 2, value: 'XPCN Finalized', isDisabled: false },
    { key: 3, value: 'Pickup Pending', isDisabled: false },
    { key: 4, value: 'Intransit - Pickup', isDisabled: false },
    { key: 5, value: 'Intransit - Linehaul', isDisabled: false },
    { key: 6, value: 'Out for Delivery', isDisabled: false },
    { key: 7, value: 'Delivered', isDisabled: false },
    // { key: 8, value: 'Cancelled Booking', isDisabled: false }
  ]

  public secondControlOptions: IMultiSelectOption[] = [
    { id: 'Material Not Ready', name: 'Material Not Ready'  },
    { id: 'XPCN Finalized', name: 'XPCN Finalized'},
    { id: 'Pickup Pending', name: 'Pickup Pending'},
    { id: 'Intransit - Pickup' , name: 'Intransit - Pickup'  },
    { id: 'Intransit - Linehaul', name: 'Intransit - Linehaul' },
    { id: 'Out for Delivery' , name: 'Out for Delivery' },
    { id: 'Delivered', name: 'Delivered'}
]

  constructor(private _pageService: PagesService, private fb: FormBuilder, public _downloadExcelService: DownloadExcelService,
    private _datePipe: DatePipe,
    public alertService: AlertService,) {

    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      filterBy: new FormControl(""),
      Keyword: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      datefilterType: new FormControl(""),
      PickupStatus: new FormControl(""),


    });
    this.downloadFilterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      ConsignorIds: new FormControl(""),
      PickupStatus: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl("")
    });
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
  }

  ngOnInit(): void {
    //this.filterForm.controls["datefilterType"].setValue('YTD');
   this.filterStatus = this.secondControlOptions.map(x=> x.name);

  }
  inputNotAllowed() {
    return false;
  }

  getData() {
    this._pageService.CPGetcustomermis(this.filterForm.value).subscribe((res) => {
      this.OrderList = res["Data"];
      if (this.OrderList != null && this.OrderList.length > 0) {
        this.totalSize = this.OrderList[0].TotalCount;
      }
    })
  }
  fromDate
  toDate
  dateFilterChanged(type) {

    this.filterForm.controls["datefilterType"].setValue(null);

    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.dateFilter = type;
    this.filterForm.controls["datefilterType"].setValue(this.dateFilter);

    if (type == 'Dates') {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } else {
        this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
        this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      }
    } else {
      this.filterForm.controls["FromDate"].setValue(null);
      this.filterForm.controls["ToDate"].setValue(null);

      this.fromDate = null;
      this.toDate = null;
    }

    this.getData();


  }
  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getData();

  }
  filter() {
    let valid = false;
    if ((this.fromDate == '' || this.fromDate == null)
      && (this.toDate == '' || this.toDate == null)
      && this.filterStatus.length == 0) {
      this.alertService.createAlert("Please select atleast one filter", 0);
    } else {
      if (this.fromDate != null) {
        this.filterForm.controls['FromDate'].setValue(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd'));
        if (this.toDate != null) {
          if (this.fromDate <= this.toDate) {
            this.filterForm.controls['ToDate'].setValue(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'));
            valid = true;
          } else {
            this.alertService.createAlert("To Date must be grater than From Date", 0);
          }
        } else {
          this.alertService.createAlert("Please select To Date", 0);
        }
      }

      if (this.filterStatus.length > 0) {
        this.filterForm.controls['PickupStatus'].setValue(this.filterStatus);
        valid = true;
      }
      if (valid)
        this.getData();
    }
  }
  clear() {
    this.startIndex = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.totalSize = 0;
    this.fromDate = null;
    this.toDate = null;

    this.filterStatus = [];
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["EndIndex"].setValue(this.pageSize);
    this.filterForm.controls["PickupStatus"].setValue(this.filterStatus);
    // this.getMIS(this.filterForm.value);
    this.OrderList = [];
  }
  public exportAsExcel() {
    this.excelData = [];
    let list = [];

    this.downloadFilterForm.controls['StartIndex'].setValue(1);
    this.downloadFilterForm.controls['EndIndex'].setValue(this.totalSize);
    this.downloadFilterForm.controls['PickupStatus'].setValue(this.filterForm.controls['PickupStatus'].value);
    this.downloadFilterForm.controls['FromDate'].setValue(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd'));
    this.downloadFilterForm.controls['ToDate'].setValue(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'));
    this._pageService.CPGetcustomermis(this.downloadFilterForm.value).subscribe(data => {
      if (data != null) {
        list = data['Data'];
        if (list.length > 0) {
          list.forEach(element => {
            this.excelData.push({

              'Booking Date': this._datePipe.transform(element['BookingDate'], 'dd-MM-y'),
              'Order Type': element['service_type'],
              'Booking ID':element['BookingIdCode'],
              'Order ID': element['vc_OrderId'],
              'XPCN': element['XPCNCode'],
              'Customer Doc/LR/PO ': element['vc_lr'],
              'Origin': element['Origin'],
              'Destination': element['Destination'],
              'Picked Date': this._datePipe.transform(element['PickedDate'], 'dd-MM-y'),
              'No of Boxes': element['NoOfBoxes'],
              'EDD': this._datePipe.transform(element['EDD'], 'dd-MM-y'),
              'Delivery Date': this._datePipe.transform(element['DeliveryDate'], 'dd-MM-y'),
              'Order Status': element['PickupStatus'],
              'Charged Weight': element['ChargedWeight'],
              'POD Status': element['PodDocUrl'],

            })
          });
        }
        else {
          this.excelData.push({
            'Booking Date': '-',
            'Order Type': '-',
            'Booking ID':'-',
            'Order ID': '-',
            'XPCN': '-',
            'Customer Doc/LR/PO ': '-',
            'Origin': '-',
            'Destination': '-',
            'Picked Date': '-',
            'EDD': '-',
            'Delivery Date': '-',
            'Order Status': '-',
            'Charged Weight': '-',
            'POD Status': '-'
          })
        }

        this._downloadExcelService.exportAsExcelFile(this.excelData, 'MIS');
      }
    })
  }

  disableSelect: boolean = false
  checkSelectAll(event) {
    if (event.checked == true) {
      this.options.forEach(element => {
        this.filterStatus.push(element.value)
      });
      this.disableSelect = true
    }
    else {
      this.filterStatus = [];
      this.disableSelect = false
    }

    //console.log(this.filterStatus, "asfwegfwefds")
  }


  tosslePerOne(all) {
    //console.log(this.allSelected.selected)
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.filterStatus.length == this.options.length)
      this.allSelected.select();

  }

  selectA: boolean = false

  toggleAllSelection() {
    //console.log(this.allSelected.selected)

    // if (this.allSelected.selected == true) {
    //   this.filterStatus = this.options.map(item => item.value)
    //   //console.log(this.filterStatus,"asfsgrw")
    //   //this.allSelected.select();
    // }
    // else {
    //   this.filterStatus = [];
    //   //this.allSelected.deselect();
    // }
    this.selectA = !this.selectA
    if (this.selectA == true) {
      this.filterStatus = this.options.map(item => item.value)
      //console.log(this.filterStatus,"asfsgrw")
      //this.allSelected.select();
    }
    else {
      this.filterStatus = [];
      //this.allSelected.deselect();
    }
  }
  public secondControlModel: number[];
  public secondControlSettings: IMultiSelectSettings = {
      checkedStyle: 'fontawesome',
      buttonClasses: 'btn btn-secondary btn-block',
      dynamicTitleMaxItems: 3,
      displayAllSelectedText: true,
      showCheckAll: true,
      showUncheckAll: true
  };
  public secondControlTexts: IMultiSelectTexts = {
      checkAll: 'Select all',
      uncheckAll: 'Unselect all',
      checked: 'item selected',
      checkedPlural: 'items selected',
      searchPlaceholder: 'Find',
      defaultTitle: 'Select Status',
      allSelected: 'All selected',
  };
 
  
}



