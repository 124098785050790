import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-delete-booking-dialog',
  templateUrl: './delete-booking-dialog.component.html',
  styleUrls: ['./delete-booking-dialog.component.scss']
})
export class DeleteBookingDialogComponent implements OnInit {
  EditOrder: FormGroup;


  constructor(private fb: FormBuilder, private _pageService: PagesService,
    public dialogRef: MatDialogRef<DeleteBookingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    {
      this.EditOrder = this.fb.group({
        BookingId: new FormControl(""),
        Reason: new FormControl(""),
      })
    }
  }


  Data: any;
  vc_BookingId: any;
  BookingId: any;
  ReasonArray: any = [];
  ngOnInit(): void {
    this.Data = this.data;


    //console.log('Suresh',this.Data)
    this.vc_BookingId = this.Data.vc_bookingId;
    this.BookingId = this.Data.int_BookingId;

    // this.EditOrder.controls['vc_BookingId'].setValue(this.Data.vc_bookingId);  

    this.ReasonArray = [
      {
        id: 1,
        Reason: "Denied By Customer"
      },
      {
        id: 2,
        Reason: "Disputed order "
      },
      {
        id: 3,
        Reason: "Material Not Ready "

      },
      {
        id: 4,
        Reason: "Weight Mismatch "
      },
      {
        id: 5,
        Reason: "Duplicate Record"

      },
      {
        id: 6,
        Reason: "Picked by other LSP"
      },
      {
        id: 7,
        Reason: "Consignor Not Informed"
      }
    ]
  }

  Reasonn: any;
  Update() {

    if (this.EditOrder.value.Reason != null && this.EditOrder.value.Reason != '') {
      this.Reasonn = this.EditOrder.value.Reason;

      this._pageService.EditLCLCancellation(this.BookingId, this.Reasonn).subscribe(data => {
        if (data['Success'] == true) {
          this.dialogRef.close();
        }
      })
    }
    else {
      this.EditOrder.controls['Reason'].markAsTouched();
    }

  }


  close() {
    this.dialogRef.close();
  }

}
