<div class="newMarginClass">
  <i class="fa fa-times" aria-hidden="true"
    style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
  <h3 mat-dialog-title>XPCN Preview </h3>
</div>
<div mat-dialog-content style="margin: 0px !important; padding: 0px !important">
  <div class="col-sm-12" id="pdf" style="padding-top: 15px;">

    <table style="width: 100%; border: 2px solid black; border-collapse: collapse; font-size: 11px !important">
      <tr>
        <td [attr.rowspan]="rowspanheader" style="text-align:center !important; vertical-align: middle !important; border-right: 2px solid black">
          <div class="p-0 m-0 text-center" *ngIf="xpcn.CustomerType == 'LSP LCL' || xpcn.CustomerType == 'Both (LSP)'; else elseBlock">
            <img style="height:90px; margin-bottom: 10px"
              src="{{xpcn.LogoURL}}">
            <p style="text-align:center !important">{{xpcn.CustomerName}}</p><br />
            <p style="text-align:center !important">{{xpcn.CustomerAddress}}</p><br />
            <p style="text-align: center !important">GSTIN : {{xpcn.GSTIN}}</p>
          </div>
          <ng-template class="p-0 m-0" #elseBlock>
            <img style="height:90px; margin-bottom: 10px"
              src="../../../../../../../assets/img/vendor/leaflet/logo_xpp.jpg">
            <p style="text-align:left !important">Instant Supply Chain Solutions Private Limited, 14th Floor, Ocus Medley,
              Dwarka Expressway, G99, Sector-99, Gurugram, Haryana 122001.</p><br />
            <p style="text-align: left !important">CIN: U63030HR2019PTC083859</p><br />
            <p style="text-align: left !important">GSTIN: 06AAFCI4823D1ZW</p>
          </ng-template>
        </td>
        <td colspan="3" style="text-align: center !important; padding: 0px !important">
          <h5 style="font-size: 16px !important; display: inline-block;"><b>Goods Consignment Note
              ({{xpcn.XPCNCode}})</b></h5><br>
          <img style="width: 250px !important" src="{{xpcn.BarcodeUrl}}" />
          <!-- <div *ngIf="xpcn.VACRemarkName == 'COD' || xpcn.VACRemarkName == 'DOD'" style="border: 2px solid black; margin: 0px; padding: 0px;width: 40px;
          position: relative;
          bottom: 27px;
          left: 2px;" class="pull-right">
            <h5 *ngIf="xpcn.VACRemarkName == 'COD'"><b>C</b></h5>
            <h5 *ngIf="xpcn.VACRemarkName == 'DOD'"><b>D</b></h5>
            <h5><b>O</b></h5>
            <h5><b>D</b></h5>
          </div> -->
        </td>
      </tr>
      <tr>
        <td>
          <b>Booking ID:</b>
          <p>{{xpcn.BookingId}}</p>
        </td>
        <td>
          <b>Order ID:</b>
          <p>{{xpcn.OrderIdCode}}</p>
        </td>
        <td>
          <b *ngIf="xpcn.CustomerName=='Qwik Supply Chain Pvt. Ltd.'">Picked Date:</b>
          <b *ngIf="xpcn.CustomerName!='Qwik Supply Chain Pvt. Ltd.'">Shipping Date:</b>
          <p>{{xpcn.strXPCNDate}}</p>
        </td>
      </tr>
      <tr>
        <td style="width: 25% !important;">
          <b>Customer Name:</b>
          <p>{{xpcn.CustomerName}}</p>
        </td>
        <td style="width: 25% !important;">
          <b>Booking Branch:</b>
          <p>{{xpcn.BranchName}}</p>
        </td>
        <td style="width: 25% !important;">
          <b>Destination Gateway:</b>
          <p>{{xpcn.Gateway}}</p>
        </td>
      </tr>
      <tr>
        <td>
          <b>Type of Service:</b>
          <p>{{xpcn.ServiceType}}</p>
        </td>
        <td>
          <b>Origin:</b>
          <p>{{xpcn.From}}</p>
        </td>
        <td>
          <b>Destination:</b>
          <p>{{xpcn.To}}</p>
        </td>
      </tr>
      <tr>
        <td *ngIf="xpcn.LR_PO != null">
          <b>Customer DOC/LR/PO</b>
          <p>{{xpcn.LR_PO}}</p>
        </td>
        <td *ngIf="xpcn.LR_PO == null">
          <b>Customer DOC/LR/PO</b>
          <p>{{xpcn.ASNNo}}</p>
        </td>
        <td>
        </td>
        <td>
        </td>
      </tr>
      <tr *ngIf="isConsignor == true">
        <td>
          <b>DC Code:</b>
          <p>{{xpcn.DCCode}}</p>
        </td>
        <td>
          <b>DC Site Loc:</b>
          <p>{{xpcn.DCLoc}}</p>
        </td>
        <td>
          <b>ASN No:</b>
          <p>{{xpcn.ASNNo}}</p>
        </td>
      </tr>
      <tr></tr>
      <tr>
        <td colspan="2" style="text-align: center !important; border: 1px solid black;">
          <b>Consignor/Shipper Details</b>
        </td>
        <td colspan="2" style="text-align: center !important; border: 1px solid black;">
          <b>Consignee/Receiver Details</b>
        </td>
      </tr>
      <tr>
        <td colspan="2" style="border: 1px solid black;">
          <p>GSTIN: {{xpcn.ConsignorGSTIN}}</p>
          <p>{{xpcn.ConsignorName}}</p>
          <p>{{xpcn.ConsignorState}}</p>
          <br>
          <p>::Dispatch From::</p>
          <p>{{xpcn.ConsignorAddress}}</p>
          <p>{{xpcn.ConsignorAddress2}}</p>
          <!-- <p>{{xpcn.From}}, {{xpcn.ConsignorState}}, {{xpcn.ConsignorPostcode}}</p> -->
        </td>
        <td colspan="2" style="border: 1px solid black;">
          <p>GSTIN: {{xpcn.ConsigneeGSTIN}}</p>
          <p>{{xpcn.ConsigneeName}}</p>
          <br>
          <p>::Ship To::</p>
          <p>{{xpcn.ConsigneeAddress}}</p>
          <p>{{xpcn.ConsigneeAddress2}}</p>
          <!-- <p>{{xpcn.To}}, {{xpcn.ConsigneeState}}, {{xpcn.ConsigneePostcode}}</p> -->
        </td>
      </tr>
      <tr>
        <td colspan="2" style="border: 1px solid black;">
          <b>Invoice/STN No. & Date: </b><br>
          <span *ngFor="let item of invoices; let i = index">{{item.InvoiceNo}}/{{item.Date | date : 'dd-MM-y'}}
            <span *ngIf="i < invoices.length -1">, </span></span>
        </td>
        <td colspan="2" style="border: 1px solid black;">
          <b>Ewaybill Details: </b><br>
          <span *ngFor="let item of waybills; let i = index">{{item.InvoiceNo}}
            <span *ngIf="i < waybills.length -1">, </span></span>
        </td>
      </tr>
      <tr>
      <tr *ngIf="isConsignor == false">
        <td>
          <b>Total Value of Invoice/STN:</b>
          <p>{{invoicesTotal}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>Goods Descriptions:</b>
          <p>{{goodsDesc}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>Quantity/Pieces</b>
          <p>{{xpcn.Quantity}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>Packages:</b>
          <p>{{totalBox}}</p>
        </td>
      </tr>
      <tr *ngIf="isConsignor == false">
        <td style="border: 1px solid black;">
          <b>Transit Risk:</b>
          <p>{{riskType}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>Freight Mode:</b>
          <p>{{freightMode}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>VAS:</b>
          <p>{{xpcn.isVAC == true ? "Yes" : "No"}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>Actual Weight :</b>
          <p>{{xpcn.Weight}}</p>
        </td>
      </tr>
      <tr *ngIf="isConsignor == false">
        <td colspan="4" style="border: 1px solid black;">
          <b>Charged Weight</b>
          <p>{{xpcn.xpcn_charged_weight}}</p>
        </td>
      </tr>
      <tr *ngIf="isConsignor == true">
        <td>
          <b>Total Value of Invoice/STN:</b>
          <p>{{invoicesTotal}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>Goods Descriptions:</b>
          <p>{{goodsDesc}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>Packages:</b>
          <p>{{totalBox}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>Transit Risk:</b>
          <p>{{riskType}}</p>
        </td>
      </tr>
      <tr *ngIf="isConsignor == true">
        <td style="border: 1px solid black;">
          <b>Freight Mode:</b>
          <p>{{freightMode}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>Charged Weight:</b>
          <p>{{xpcn.xpcn_charged_weight}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>Approx. Weight :</b>
          <p>{{xpcn.Weight}}</p>
        </td>
        <td style="border: 1px solid black;">
          <b>VAS:</b>
          <p>{{xpcn.isVAC == true ? "Yes" : "No"}}</p>
        </td>
      </tr>
      <tr *ngIf="isConsignor == true">
        <td colspan="4">
          <b>LBH | PKGS | V.WT: </b>
          <p *ngFor="let box of xpcn.BoxesListConsignor; let i = index">{{box.Length}}X{{box.Breadth}}X{{box.Height}} |
            {{box.Pkgs}} | {{box.Weight}}
            <span *ngIf="i < xpcn.BoxesListConsignor.length -1">, </span>
          </p>
          <p><b>Total:</b> {{totalBox}} | {{totalWt}}</p>
        </td>
      </tr>
      <!-- <tr>
        <td>
          <b>Actual Weight in Kgs:</b>
          <p>{{xpcn.Weight}}</p>
        </td>
        <td>
          <b>Volumetric Weight:</b>
          <p>{{xpcn.VolumetricWeight}}</p>
        </td>
        <td>
          <b>Charged Weight:</b>
          <p>{{xpcn.CHargedWeight}}</p>
        </td>
      </tr> -->
      <tr *ngIf="showFreight">
        <td colspan="4">
          <b style="text-decoration: underline;">FREIGHT DETAILS:</b>
        </td>
      </tr>
      <tr *ngIf="showFreight">
        <td>
          <b>Rate per Kg:</b>
          <p></p>
        </td>
        <td>
          <b>Basic Freight:</b>
          <p>{{xpcn.BasicFreight}}</p>
        </td>
        <td>
          <b>VAS Charges:</b>
          <p>{{xpcn.ValueAddedCharge}}</p>
        </td>
        <td>
          <b>Other Charges :</b>
          <p>{{xpcn.OtherCharges}}</p>
        </td>
      </tr>
      <tr *ngIf="showFreight">
        <td>
          <b>IGST:</b>
          <p>{{igst}}</p>
        </td>
        <td>
          <b>CGST:</b>
          <p>{{cgst}}</p>
        </td>
        <td>
          <b>SGST:</b>
          <p>{{sgst}}</p>
        </td>
        <td>
          <b>TOTAL:</b>
          <p>{{total}}</p>
        </td>
      </tr>

      <tr>
        <td colspan="4" style="border: 1px solid black;">
          <b> Special Remarks:</b>{{xpcn.Remarks}}
        </td>
      </tr>
      <tr>
        <td colspan="4" style="border: 1px solid black;">
          <p>
            Declaration: On behalf of my organisation, I certify that the above information mentioned In the XPCN is
            correct as per my knowledge and I undertake to make the Freight payment for this Order Id/XPCN as per the
            terms & conditions of XPCN and contractual agreement.
          </p>
          <p class="pull-right">
            {{xpcn.ConsignorName}}
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="4" style="border: 1px solid black;">
          <p>
            Terms & Conditions: This is a Non-Negotiable Goods Consignment Note. It is agreed that the goods described
            here in are accepted in "Said to Contain" basis as per the invoices/STNs and Ewaybills. The onus of proper
            documentation with respect to GST, GSTIN & Ewaybill, Excise Duty, Customs and any statutory laws lies with
            the parties i.e. Consignor and Consignee. ISCPL shall not be responsible/ Liable in any case of deficiency
            in the documents/ Statutory requirements.<br>
            Carrying terms and liabilities are as per the contractual agreement.
          </p>
        </td>
      </tr>

      <tr>
        <td colspan="4"
          style="text-align: center !important; background-color: yellow; border: 1px solid black; width: 50% !important">
          <b>PROOF OF DELIVERY (POD)</b>
        </td>
      </tr>
      <tr>
        <td colspan="4" style="text-align:center !important; border: 1px solid black;">
          Goods and Documents received in full and intact condition
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <b>Name: {{xpcn.mPODName}}</b>
        </td>
        <td colspan="2" rowspan="4" style="border-left: 2px solid black; vertical-align: bottom !important">

          <b>Sign & Stamp:</b>
          <img src='{{xpcn.mPODUrl}}' style="max-width: 175px">

        </td>
      </tr>
      <tr>
        <td colspan="2">
          <b>Mobile No: {{xpcn.mPODNum}}</b>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <b>Date & Time: {{xpcn.mPODDate | date:'dd/MM/yyyy hh:mm'}}</b>
        </td>
      </tr>
      <tr>
        <td>
          <b>Lat, Lng:</b>
          <p style="display:inline-block;">{{xpcn.MPODLatLong}}</p>
        </td>
        <td>
          <b>Place:</b>{{xpcn.MPODAddress}}
        </td>
      </tr>
      <!-- <tr style="border-top: 2px solid black">
        <td colspan="2">
          <b>Generated By:</b>
          <span>{{xpcn.CreatedBy}}</span>
          <span> | {{xpcn.CreatedDate}}</span>
        </td>
        <td>
          <b>Lat, Lng: {{xpcn.MPODLatLong}}</b>
        </td>
        <td>
          <b>Place: {{xpcn.MPODAddress}}</b>
        </td>
      </tr> -->
    </table>
    <b>Generated By:</b>
    <span>{{xpcn.CreatedBy}}</span>
    <span> | {{xpcn.CreatedDate}}</span>
    <br> <br>
    <p *ngIf="xpcn.CustomerType == 'LSP LCL' || xpcn.CustomerType == 'Both (LSP)'" style="font-weight: 700;">
      *Technically &
      Operationally Powered by XP India</p>
    <!-- <table *ngIf="waybills1.length >= 15" class="table table-bordered breakhere"
      style="width: 100%; border: 2px solid black; border-collapse: collapse;">
      <tr>
        <th>Sl No</th>
        <th>Invoice No</th>
        <th>EwayBill No</th>
        <th>EwayBill Issue Date</th>
        <th>EwayBill Expiry Date</th>
      </tr>
      <tr *ngFor="let item of waybills1; let i = index">
        <td>{{i + 1}}</td>
        <td>
          <span *ngIf="invoices1.length - 1 >= i">{{invoices1[i].InvoiceNo}}</span>
        </td>
        <td>{{item.InvoiceNo}}</td>
        <td>{{item.Date | date : 'dd-MM-y'}}</td>
        <td>{{item.ExpDate | date : 'dd-MM-y'}}</td>
      </tr>
    </table> -->
  </div>
</div>
<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
  <button class="btn btn-primary fa fa-download" (click)=" downloadPDF()" 
    style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
    <span style="font-family: Arial, Helvetica, sans-serif;"> Download</span>
  </button>
  <button class="btn btn-danger fa fa-times-circle" (click)="close()"
    style="margin-left: 10px ; background-color: #f44336 !important;">
    <span style="font-family: Arial, Helvetica, sans-serif;">
      Cancel</span></button>
</mat-dialog-actions>