import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  headers: { headers: any; };

  constructor(private _http : HttpClient) { }
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  GetAnalyticsServiceLevel(data){
    return this._http.post(environment.apiUrl + 'api/cstmr/get-servicelevel-analytics', data);
  }
  GetPayablesDashboard(data){
    return this._http.post(environment.apiUrl + 'api/cstmr/get-payables-dashboard', data);
  }
  GetAnalyticsOrders(data){
    return this._http.post(environment.apiUrl + 'api/cstmr/get-orders-analytics', data);
  }
  GetAnalyticsTrips(data){
    return this._http.post(environment.apiUrl + 'api/cstmr/get-trips-analytics', data);
  }
  GetAnalyticsPerformance(data){
    return this._http.post(environment.apiUrl + 'api/cstmr/get-Performance-analytics', data);
  }
}
