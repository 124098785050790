import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ChatService } from './chat.service';
import { BehaviorSubject } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  activeUserId;
  activeChat;
  currentMessage = new BehaviorSubject(null);

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  formData: FormData = new FormData();
  keyword = '';
  customerId;
  customers = [];
  sessionDetails: any;
  searchCtrl: any[] = [];
  fileToUpload: File | null = null;
  fileName = '';
  chatForm: FormGroup;
  message = '';
  users = [];
  chats = [];
  customerCount = 0;
  ffvCount = 0;
  driverCount = 0;
  userName = '';
  userType = 'User';

 

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private alertService: AlertService,
    private angularFireMessaging: AngularFireMessaging,
    private _chatService: ChatService
  ) {
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
    this.userName = this.sessionDetails['FirstName'] + ' ' + this.sessionDetails['LastName'];

    this.chatForm = this.formBuilder.group({
      'MessageBody': [null],
      'UserId1': [null],
      'UserName1': [null],
      'UserType1': [null],
      'Doc': [null],
      'FileName': [null],
    });
  }

  ngOnInit() {
   
      // this.chatForm.controls['UserType1'].setValue('Customer');
      this.receiveMessage();
      this.getRecentChat();
  }

  getRecentChat() {
   // console.log('recent');
    this._chatService.getRecentMessages('Customer').subscribe(data => {
      if (data != null && data['Data'] != null && data['Success']) {
       // console.log(data['Data'],'Suresh');
        this.users = data['Data']['Messages'];
        this.customerCount = data['Data']['CustomerCount'];
        this.ffvCount = data['Data']['FFVCount'];
        this.driverCount = data['Data']['DriverCount'];
        if ((this.activeChat == null || this.activeChat == undefined || this.activeChat == '')) {
          if (this.users != null && this.users.length > 0) {
            this.chatSelected(this.users[0]);
          }
        } else {
          this.chatSelected(this.activeChat);
        }
      }
    });
  }

  chatSelected(user) {
    this.activeChat = user.UserCode;
    this.activeUserId = user.UserName;
    this.chatForm.controls['UserId1'].setValue(user.UserId);
    this.chatForm.controls['UserName1'].setValue(user.UserName);
    this.chatForm.controls['UserType1'].setValue(user.UserType);
    this._chatService.getMessages(user.UserId, user.UserType).subscribe(data => {
      if (data != null && data['Success']) {
        this.chats = data['Data'];
       // console.log(this.chats,'Tomeshwar');
        // if (this.chats.length > 0) {
        //   this.users.filter(x => x['UserCode'] == this.activeChat && x['UserName'] == this.activeUserId)[0].MessageBody = this.chats[this.chats.length - 1].MessageBody;
        //   this.users.unshift(this.users.splice(this.users.findIndex(item => item['UserCode'] === this.activeChat && item['UserName'] == this.activeUserId), 1)[0])
        // }
        // setTimeout(() => { user.NewCount = 0 }, 3000);
      }
    });
  }

  receiveMessage() {
 
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        //console.log("new message received. ", payload);
        this.currentMessage.next(payload);
        //console.log(this.currentMessage);
      })
    this.getRecentChat();
    this.angularFireMessaging.messages
      .subscribe((message) => {
        //console.log(message, 'message triggered');
        this.getRecentChat();
      });
  }


  onSelectionChange(value) {
    if (value != null && value != '') {
      let user = this.customers.filter(x => x.CustomerId == value)[0];
      let code = user['CustomerCode'];
      let id = user['CustomerName'];
      if (this.users.filter(x => x['UserCode'] == code).length > 0) {

      } else {
        var newUser = {
          MessageBody: '',
          FileName: '-',
          FileUrl: '-',
          PicUrl: user['AccountNumber'],
          DateTime: '',
          UserId: user['CustomerId'],
          UserName: user['CustomerName'],
          UserType: user['CustomerType'],
          UserCode: user['CustomerCode'],
          NewCount: 0
        }
        this.users.push(newUser);
      }
      this.users.unshift(this.users.splice(this.users.findIndex(item => item['UserCode'] === code && item['UserName'] == this.activeUserId), 1)[0])
      this.activeChat = code;
      this.activeUserId = id;
      this.chatSelected(this.users.filter(x => x['UserCode'] == code && x['UserName'] == this.activeUserId)[0]);
    }
  }

  handleFileInput(files: FileList) {

    if (files != null && files.length > 0) {
      
      // const acceptedFileTypes = ['application/pdf'];
      const acceptedFileTypes = [ 'image/xlsx','/xlsx','image/csv','.csv','text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','video/3gpp'
       , 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
        'audio/mp3', 'audio/mp4', 'audio/wav', 'audio/avi', 'audio/mov','image/gif','image/png','application/pdf','image/x-eps',
        'video/mp3', 'video/mp4', 'video/wav', 'video/avi', 'video/mov',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'
      ];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 10000000) {
          this.fileName = files.item(0).name;
          this.formData.append('123', files.item(0));
        } else {
          this.fileName = '';
          this.alertService.createAlert('File size must be less than 10 MB', 0);
        }
      }
      else {
        this.fileName = '';
        this.alertService.createAlert('Wrong File Format', 0);
        this.chatForm.controls['Doc'].setValue(null);

        return;
     
      }
 
    }

 
   
  }

 

  removeFile() {
    this.formData.delete('123');
    this.chatForm.controls['Doc'].setValue(null);
    this.fileName = '';
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  sendMessage(values) {
    if ((this.chatForm.controls['MessageBody'].value != null && this.chatForm.controls['MessageBody'].value != '')
      || (this.fileName != null && this.fileName != '')) {
      this.formData.delete('FormData');
      this.formData.append('FormData', JSON.stringify(this.chatForm.value));
      this._chatService.sendMessage(this.formData).subscribe(data => {
        if (data != null) {
          if (data['Success'] != null) {
            if (data['Success']) {
              this.chatForm.controls['MessageBody'].setValue(null);
              this.chatForm.controls['Doc'].setValue(null);
              this.chats = data['Data'];
       
              this.users.filter(x => x['UserCode'] == this.activeChat && x['UserName'] == this.activeUserId)[0].MessageBody = this.chatForm.controls['MessageBody'].value;
              this.users.unshift(this.users.splice(this.users.findIndex(item => item['UserCode'] === this.activeChat && item['UserName'] == this.activeUserId), 1)[0])
              this.formData.delete('123');
              this.fileName = '';
            }
          }
        }
      });
    } else {
      // this.chatForm.controls['MessageBody'].markAsTouched();
    }
  }

}
