<div class="app"  [ngClass]="settings.theme.skin" 
                  [class.navbar-fixed]="settings.theme.navbarIsFixed"  
                  [class.sidebar-fixed]="settings.theme.sidebarIsFixed"  
                  [class.horizontal-menu]="settings.theme.menu == 'horizontal'" 
                  [class.compact]="settings.theme.menuType == 'compact'" 
                  [class.mini]="settings.theme.menuType == 'mini'" 
                  [class.menu-hide]="!settings.theme.showMenu">
    <div class="wrapper">
        <router-outlet></router-outlet>
        <div *ngIf="loaderMessage$" id="app-spinner">
            <!-- <i class='fas fa-spinner fa-spin'></i> -->
            <mat-spinner color="primary"></mat-spinner>
        </div>
    </div> 
</div>
    