import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderState = new Subject<any>();
  message$: Observable<any> = this.loaderState.asObservable();
  
  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  private isActionAdded = new Subject<any>();
  constructor() { 
  }



  showLoader() {
         this.loaderState.next(true);
  }
  
  hideLoader() {
      this.loaderState.next(false);
  }
  isActionAdded$: Observable<any> = this.loaderState.asObservable();

  actionAdded(){
    this.isActionAdded.next(true);
  }
  actionAddedStopped(){
   this.isActionAdded.next(false);
  }
}
