<div class="NewHeader">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close(false)"></i>
    <h1 mat-dialog-title>Book New Order</h1>
  </div>
  <div mat-dialog-content>
    <div class="col-sm-12">  
      {{data}}
    </div>
    <div class="col-sm-12">  
      Do you want to book another order?
    </div>
  </div>
  <mat-dialog-actions align="end" style="padding-bottom:10px">
    <button class="btn btn-primary pull-right fa fa-paper-plane" (click)="close(true)"
    style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
    <span style="font-family: Arial, Helvetica, sans-serif;"> Yes</span>
</button>
<button class="btn btn-danger pull-right  fa fa-times-circle"  (click)="close(false)"
    style="margin-left: 10px ; background-color: #f44336 !important;">
    <span style="font-family: Arial, Helvetica, sans-serif;">
     No</span></button>

  </mat-dialog-actions>