import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-generate-pickup-request',
  templateUrl: './generate-pickup-request.component.html',
  styleUrls: ['./generate-pickup-request.component.scss']
})
export class GeneratePickupRequestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
