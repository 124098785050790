import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-xpcn-xpls-list-performance',
  templateUrl: './shared-xpcn-xpls-list-performance.component.html',
  styleUrls: ['./shared-xpcn-xpls-list-performance.component.scss']
})
export class SharedXpcnXplsListPerformanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
