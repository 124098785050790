<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
    <h1 mat-dialog-title *ngIf="isEdit"> Edit E-Way Bill</h1>
    <h1 mat-dialog-title *ngIf="!isEdit"> Add E-Way Bill</h1>
</div>
<div mat-dialog-content>

    <form class="form-horizontal" novalidate [formGroup]="addEditForm">


        <div class="form-group">
            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 add-member-label inline-block">E-Way Bill
                No:</label>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 inline-block">
                <input type="text" class="form-control" maxlength="25" placeholder="Enter E-Way Bill No" required
                    formControlName="InvoiceNo">
                <div *ngIf="!addEditForm.controls.InvoiceNo.valid && (addEditForm.controls.InvoiceNo.dirty || addEditForm.controls.InvoiceNo.touched)"
                    class="error-text">
                    <div *ngIf="addEditForm.controls.InvoiceNo.errors.required">
                        E-Way Bill No is required
                    </div>
                </div>
            </div>
        </div>


        <div class="form-group">
            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 add-member-label inline-block">Issue Date:
            </label>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 inline-block">
                <div class="input-icon right">
                    <!-- <i class="fa fa-calendar" [owlDateTimeTrigger]="dt1"></i> -->
                    <input type="text" class="form-control" placeholder="Enter Date" [min]="minday" [max]="today"
                        (ngModelChange)="dateChanged()" formControlName="Date" required [owlDateTimeTrigger]="dt1"
                        [owlDateTime]="dt1" name="dt1">
                    <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                </div>
                <div *ngIf="!addEditForm.controls.Date.valid && (addEditForm.controls.Date.dirty || addEditForm.controls.Date.touched)"
                    class="error-text">
                    <div *ngIf="addEditForm.controls.Date.errors.required">
                        Issue Date is required
                    </div>
                </div>
            </div>
        </div>


        <div class="form-group">
            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 inline-block">Expiry Date: </label>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 inline-block">
                <div class="input-icon right">
                    <!-- <i class="fa fa-calendar" [owlDateTimeTrigger]="dt2"></i> -->
                    <input type="text" class="form-control" [min]="addEditForm.controls.Date.value"
                        placeholder="Enter Expiry Date" formControlName="ExpDate" [owlDateTimeTrigger]="dt2"
                        [owlDateTime]="dt2" name="dt2">
                    <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                </div>
                <!-- <div *ngIf="!addEditForm.controls.ExpDate.valid && (addEditForm.controls.ExpDate.dirty || addEditForm.controls.ExpDate.touched)" class="error-text">
                    <div *ngIf="addEditForm.controls.ExpDate.errors.required">
                        Expiry Date is required
                    </div>
                </div> -->
            </div>
        </div>



        <div class="form-group">
            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 add-member-label inline-block">Value
                (Rs):</label>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 inline-block">
                <input type="text" class="form-control" (keypress)="priceValidator($event)" placeholder="Enter Value"
                    required formControlName="InvoiceValue">
                <div *ngIf="!addEditForm.controls.InvoiceValue.valid && (addEditForm.controls.InvoiceValue.dirty || addEditForm.controls.InvoiceValue.touched)"
                    class="error-text">
                    <div *ngIf="addEditForm.controls.InvoiceValue.errors.required">
                        Value is required
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 inline-block">Upload Document: </label>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 margins inline-block">
                <input type="file" accept="application/pdf" placeholder="Upload File" formControlName="Doc"
                    (change)="handleFileInput($event.target.files)" style="margin-top:-3px!important; cursor: pointer;">
            </div>
        </div>


    </form>
</div>
<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
    <button class="btn btn-primary fa fa-save" (click)=" saveData()"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Save</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
</mat-dialog-actions>