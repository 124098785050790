import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerserviceService {
  headers: { headers: any; };
  getMis: any;

  constructor(private _http: HttpClient) { }
  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;

  }
  
  //to get Customer Fcl options
  getCustomerFclOptions(customerId, rateId) {
    return this._http.get(environment.apiUrl + 'api/get-customer-fclrates-options?customerId=' + customerId + '&rateId=' + rateId);
  }
  // to get customers
  getCustomerDetails() {
    return this._http.get(environment.apiUrl + 'api/cstmr/get-customer-details-web');
  }
  GetAddendum(CustomerId){
    return this._http.get(environment.apiUrl+'api/custmr/get-customers-contracts-addendum/' +CustomerId);
  }

  // to get customer Users
  getCustomerUsers(data) {
    return this._http.post(environment.apiUrl + 'api/cstmr/get-customer-users-web', data);
  }
  // to get customer location
  getCustomerLocation(data) {
    return this._http.post(environment.apiUrl + 'api/custmr/get-customer-location-web', data);
  }
  GetCustomersMasterContract(CustomerId) {
    return this._http.get(
      environment.apiUrl + "api/custmr/get-customers-master-contract/" + CustomerId
    );
  }
  GetFclRatesByAddendum(AddendumId) {
    return this._http.get(
      environment.apiUrl + "api/custmr/get-customer-fclrate-by-contract/" + AddendumId
    );
  }
  //To Get Customers Billing Office By Customer
  getBillingOffice(data) {
    return this._http.post(environment.apiUrl + 'api/custmr/get-CustomersBillingOfficeweb', data
    );
  }

  // to get Locations & usertypes for Customer Sub User
  getCustomerUsersLocationUsertypes() {
    return this._http.get(environment.apiUrl + 'api/get-customer-ffv-user-options');
  }
  UpsertCustomersContracts(data) {
    return this._http.post(
      environment.apiUrl + "api/custmr/upsert-customers-contracts",
      data
    );
  }

  //To Get Customers contract
  getContract(data) {
    return this._http.post(environment.apiUrl + 'api/custmr/get-customers-contracts', data
    );
  }
  GetDistance(data) {
    return this._http.post(environment.apiUrl + 'api/get-distance-from-origin', data);
  }
    // to get locations for fcl rate
    getLocationsForFCLRate(keyword) {
      return this._http.get(environment.apiUrl + 'api/get-locations-fcl-rate?keyword=' + keyword);
    }
      // to upsert FCL RATES
  upsertCustomerFclRates(data) {
    return this._http.post(environment.apiUrl + 'api/custmr/upsert-customer-fclrates', data);
  }
  
  //to get list of districts
  GetDistricts() {
    return this._http.get(environment.apiUrl + 'api/get-districts')
  }
  // to get the list of vehicle types. 
  getVehicleTypes() {
    return this._http.get(environment.apiUrl + 'api/admin/get-vehicle-types');
  }
  GetDedicatedDieselPriceByDistrict(DistrictId) {
    return this._http.get(environment.apiUrl + 'api/get-dedicated-diesel-price-by-district/' + DistrictId);
  }

  GetCentralPriceByEffectivity(data) {
    return this._http.post(environment.apiUrl + 'api/get-dynamic-diesel-price-by-filter', data);
  }
  GetDedicatedPriceByEffectivity(data) {
    return this._http.post(environment.apiUrl + 'api/get-dedicated-diesel-price-by-filter', data);
  }
  GetDynamicDieselPriceLatest() {
    return this._http.get(environment.apiUrl + 'api/get-dynamic-diesel-price-latest');
  }
  // to upsert customer User
  upsertCustomerUser(data) {
    return this._http.post(environment.apiUrl + 'api/custmr/upsert-customer-user', data);
  }

  getMisUser(data) {
    return this._http.post(environment.apiUrl + 'api/custmr/get_custmr_poc_mis', data
    );
  }
  // to get Locations & usertypes for Customer Sub User
  // to get the list of origins/destinations
  getOriginDestinations(keyword, isOrigin) {
    return this._http.get(environment.apiUrl + 'api/get-origin-destination?keyword=' + keyword + '&isOrigin=' + isOrigin);
  }
  GetFclrate(data) {
    return this._http.post(environment.apiUrl + 'api/custmr/get-customer-fclrate-values', data
    );
  }
  updateImpactStatusFclRate(fclrateid){
    return this._http.get(environment.apiUrl + 'api/update-impact-status?fclRateId=' + fclrateid);
  }
  statusChangeFclRate(data){
    return this._http.post(environment.apiUrl + 'api/update-customers-fclrate-status',data);
  }
  GetStatesDropdownlist() {
    return this._http.get(environment.apiUrl + 'api/get-stateslist')
  }
  getGoogleLocation(keyword) {
    return this._http.get(environment.apiUrl + 'api/get-google-location?keyword=' + keyword);
  }
    // to upsert customer location
    upsertCustomerLocation(data) {
      return this._http.post(environment.apiUrl + 'api/upsert-customer-location', data);
    }
    
  // to get the list of locations by districtid's
  GetPostcodesDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-postcodes-by-location?LocationId=' + data)
  }
  CheckBillingOfficeName(CustId, Office) {
    return this._http.get(
      environment.apiUrl + `custmr/api/check-billing-office/${CustId}/${Office}`
    );
  }
  getLatLong(origin) {
    return this._http.get(environment.apiUrl + 'api/get-lat-long?origin=' + origin);
  }
  // to get the list of locations by districtid's
  GetLocationsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-locationlistbystateid?DistrictId=' + data)
  }

  //to get the list of districts by stateid's
  GetDistrictsDropdownlist(data) {
    return this._http.get(environment.apiUrl + 'api/get-districtlistbystateid?StateId=' + data)
  }
// to upsert customer User
upsertCustomerMIS(data) {
  return this._http.post(environment.apiUrl + 'api/custmr/upsert-customer-mis-poc', data);
}
UpdateContractStatus(data) {
  return this._http.post(
    environment.apiUrl + "api/update-customers-contract-status",
    data
  );
}
 // to get Locations & usertypes for Customer Sub User
 getCustomerCustomerMISUsertypes() {
  return this._http.get(environment.apiUrl + 'api/get-customer-mis-user-options');
}
  //To Upsert Billing Office
  UpsertBillingOffice(data) {
    return this._http.post(
      environment.apiUrl + "api/custmr/upsert-CustomersBillingOffice",
      data
    );
  }
    //To Get State Code By State
    StateCodeByStateId(StateId) {
      return this._http.get(
        environment.apiUrl + "api/get-state-code-by-state-id/" + StateId
      );
    }
    
  //To Get List Of Zones linked With Branches
  ZonesByBranchesDropDownList(BranchId) {
    return this._http.get(
      environment.apiUrl + "api/get-zones-by-branch-id/" + BranchId
    );
  }
    //To Get List Of Branches Linked With States
    BranchesByStatesDropDownList(StateId) {
      return this._http.get(
        environment.apiUrl + "api/get-branches-list-by-states-id/" + StateId
      );
    }
     //To Get List Of States
  StatesDropDownList() {
    return this._http.get(environment.apiUrl + "api/get-stateslist");
  }
  getCustomerHaltingChargesBasedOnCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-cust-halting-charges-customer', data);
  } 
}
