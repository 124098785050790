<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
    <h3 mat-dialog-title>XPCN/XPLS List </h3>
</div>
<div fxLayout="row wrap" #resizedDiv style="margin-top:20px!important;">
    <div class="flex-p w-100">


        <div class="table-responsive">
            <table id="example2" class="table table-bordered">
                <thead class="tbl-head">
                    <tr class="tr-grid-header">
                        <th>XPCN / XPLS No</th>
                        <th>Date</th>
                        <th>From</th>
                        <th>To</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of list">
                        <td *ngIf="item.ServiceType == 'XPCN'">
                            <a style="cursor: pointer; color: #007ad9;"
                                (click)="openXPCNPreview(item)">{{item.XPCNCode}}</a>
                            <ng-container *ngFor="let ewb of item.XPCNList">
                                <br>
                                <a *ngIf="ewb.LookupName != null && ewb.LookupName != ''"
                                    style="cursor: pointer; color: #007ad9;" target="_blank"
                                    href="{{ewb.LookupName}}">{{ewb.CodeMasterName}}</a>
                            </ng-container>
                        </td>
                        <td *ngIf="item.ServiceType == 'XPLS'">
                            <a style="cursor: pointer; color: #007ad9;"
                                (click)="openXPLSPreview(item)">{{item.XPCNCode}}</a>
                            <ng-container *ngFor="let ewb of item.XPCNList">
                                <br>
                                <a *ngIf="ewb.LookupName != null && ewb.LookupName != ''"
                                    style="cursor: pointer; color: #007ad9;" target="_blank"
                                    href="{{ewb.LookupName}}">{{ewb.CodeMasterName}}</a>
                            </ng-container>
                        </td>
                        <td>{{item.XPCNDate | date: "dd-MM-y"}}</td>
                        <td>{{item.From}}</td>
                        <td>{{item.To}}</td>
                    </tr>
                    <tr *ngIf="list.length == 0">
                        <td class="text-center" colspan="4">No records found</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>