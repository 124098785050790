import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaderResponse } from '@angular/common/http';
import { tap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';
// import { MatSnackBar } from '@angular/material';
import { AlertService } from '../shared/services/alert.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorJWTService implements HttpInterceptor {
  loader: boolean;
  isLoaderActive: boolean;
  applicationId ;
  constructor(
    public _loaderService: LoaderService, 
    public alertService: AlertService
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // //Initialising Loader when the http request generated
    const user = JSON.parse(localStorage.getItem('userSession'));
    // console.log(request.url);
    let userToken = '';
    let userId = '';
    let userName = '';
    let userType = '';
    
    const apirUrl = environment.apiUrl;
    this.applicationId= apirUrl.includes('liveapi')?'18914':'2066';
    if (user != null && !(request.url.match(/maps.googleapis.com/g))) {
      if (request.url.includes('api/get-new-notifications') != true && request.url.includes('api/get-messages') != true
        && request.url.includes('api/send-message') != true) {
        this._loaderService.showLoader();
      }
      this._loaderService.showLoader();
      userToken = user['AuthToken'];
      userId = user['UserId'];
      userName = user['FirstName'] + ' ' + user['LastName'];
      userType = user['UserType'];
      request = request.clone({
        setHeaders: {
          AuthToken: userToken,
          UserId: userId,
          UserName: userName,
          UserType: userType,
          applicationId:this.applicationId
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          AuthToken: '',
          UserId: '',
          UserName: '',
          UserType: 'Customer',
          applicationId: this.applicationId
        }
      });
    }

    return next.handle(request).pipe(

      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.Success == true) {
            if (event.body.Message != null && event.body.Message.trim() != '') {
              this.alertService.createAlert(event.body.Message, 1);
              this.removeLoader();
            }
          } else if (event.body.Success == false) {
            if (event.body.Message != null && event.body.Message.trim() != '') {
              this.alertService.createAlert(event.body.Message, 0);
              this.removeLoader();
            }
          }
        }
      }
      )
    );


  }

  // getResponse(response: HttpResponse<any>): Observable<HttpEvent<any>> {
  //   return ;
  // }

  public removeLoader() {
    this._loaderService.hideLoader();
    this.loader = false;
    this.isLoaderActive = false;
  }

  public handleSuccess(res) {
    this.removeLoader();
  }

  public addLoader() {
    if (!this.loader) {
      if (this.isLoaderActive) {
        this._loaderService.showLoader();
        this.loader = true;
      }
    }
  }
}
