<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true" (click)="close()"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;cursor: pointer;"></i>
    <h2 mat-dialog-title>Change Password</h2>
</div>
<div mat-dialog-content>
    <form novalidate [formGroup]="addEditForm">
        <div class="row mb-3 mt-5">
            <div class="col-sm-3 form-group">Email Id:</div>
            <div class="col-sm-9">
                <input type="email" class="form-control" formControlName="Email" placeholder="" disabled readonly
                    style="cursor:not-allowed">
            </div>

        </div>

        <div *ngIf="TypeString == 'text'" class="form-group row mt-3 mb-3">

            <label class="control-label  col-sm-3  add-member-label">Current Password:</label>
            <div class="col-sm-9">
                <div class="input-icon right">
                    <i class="fa fa-eye-slash" style="cursor: pointer;  font-size: 14px!important;"
                        (click)="ChangetextType(isPassword = ! isPassword)"></i>
                    <input [type]=TypeString formControlName="OldPassword" class="form-control"
                        placeholder="Enter Current Password" required style="letter-spacing:normal!important">
                    <!-- <mat-icon  matSuffix style="cursor: pointer;  font-size: 14px!important;" (click)="ChangetextType(isPassword = ! isPassword)">remove_red_eye</mat-icon> -->
                    <div *ngIf="!addEditForm.controls.OldPassword.valid && (addEditForm.controls.OldPassword.dirty || addEditForm.controls.OldPassword.touched)"
                        class="error-text">
                        <div *ngIf="addEditForm.controls.OldPassword.errors.required">
                            Current Password is required
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div *ngIf="TypeString == 'password'" class="form-group row mt-4 mb-4">
            <label class="control-label col-sm-3  add-member-label">Current Password:</label>
            <div class="col-sm-9">
                <div class="input-icon right">
                    <i class="fa fa-eye" style="cursor: pointer;  font-size: 14px!important;"
                        (click)="ChangetextType(isPassword = ! isPassword)"></i>
                    <input maxlength="50" [type]=TypeString formControlName="OldPassword" class="form-control"
                        placeholder="Enter Current Password" required style="letter-spacing:normal!important">
                    <!-- <mat-icon  matSuffix style="cursor: pointer;  font-size: 14px!important;" (click)="ChangetextType(isPassword = ! isPassword)">remove_red_eye</mat-icon> -->
                    <div *ngIf="!addEditForm.controls.OldPassword.valid && (addEditForm.controls.OldPassword.dirty || addEditForm.controls.OldPassword.touched)"
                        class="error-text">
                        <div *ngIf="addEditForm.controls.OldPassword.errors.required">
                            Current password is required
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="TypeStringInNewPassword == 'password'" class="form-group overflows row mt-4">
            <ng-template [animation]="true" #myTooltip placement="right" class="position">

                <div style="text-align:left;">
                    <ul style="list-style: none;">
                        <li id="letter" [ngClass]="isOneLetter?'valid':'invalid'">At least
                            <strong>one letter</strong>
                        </li>
                        <li id="capital" [ngClass]="isOneCapitalLetter?'valid':'invalid'">At least
                            <strong>one capital letter</strong>
                        </li>
                        <li id="number" [ngClass]="isOneNumber?'valid':'invalid'">At least
                            <strong>one number</strong>
                        </li>
                        <li id="specialchar" [ngClass]="isOneSpecialCaharacter?'valid':'invalid'">At least
                            <strong>one special character</strong>
                        </li>
                        <li id="length" [ngClass]="isMinLength?'valid':'invalid'">Be at least
                            <strong>8 characters</strong>
                        </li>
                    </ul>
                </div>
            </ng-template>
            <label class="control-label col-sm-3 add-member-label" for="pwd">New Password: </label>
            <div class="col-sm-9">
                <div class="input-icon right">
                    <i class="fa fa-eye" style="cursor: pointer;  font-size: 14px!important;"
                        (click)="ChangetextTypeInNewPassword(isPassword = ! isPassword)"></i>
                    <input maxlength="50" [ngbTooltip]="myTooltip" tooltipPlacement='right'
                        [type]=TypeStringInNewPassword (keyup)="onKeyPress(addEditForm.controls.NewPassword)"
                        (keydown.space)="$event.preventDefault()" autocomplete="off" formControlName="NewPassword"
                        class="form-control" placeholder="Enter New Password" required
                        style="letter-spacing:normal!important">
                    <div *ngIf="!addEditForm.controls.NewPassword.valid && (addEditForm.controls.NewPassword.dirty || addEditForm.controls.NewPassword.touched)"
                        class="error-text">
                        <div *ngIf="addEditForm.controls.NewPassword.errors.required">
                            Password is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="TypeStringInNewPassword == 'text'" class="form-group overflows row">
            <ng-template [animation]="true" #myTooltip placement="right" class="position" style="padding-left: 22%;">

                <div style="text-align:left;">
                    <ul style="list-style: none;">
                        <li id="letter" [ngClass]="isOneLetter?'valid':'invalid'">At least
                            <strong>one letter</strong>
                        </li>
                        <li id="capital" [ngClass]="isOneCapitalLetter?'valid':'invalid'">At least
                            <strong>one capital letter</strong>
                        </li>
                        <li id="number" [ngClass]="isOneNumber?'valid':'invalid'">At least
                            <strong>one number</strong>
                        </li>
                        <li id="specialchar" [ngClass]="isOneSpecialCaharacter?'valid':'invalid'">At least
                            <strong>one special character</strong>
                        </li>
                        <li id="length" [ngClass]="isMinLength?'valid':'invalid'">Be at least
                            <strong>8 characters</strong>
                        </li>
                    </ul>
                </div>
            </ng-template>
            <label class="control-label col-lg-3 col-md-3 col-sm-3 col-xs-3 add-member-label" for="pwd">New Password:
            </label>
            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                <div class="input-icon right">
                    <i class="fa fa-eye-slash" style="cursor: pointer;  font-size: 14px!important;"
                        (click)="ChangetextTypeInNewPassword(isPassword = ! isPassword)"></i>
                    <input [ngbTooltip]="myTooltip" maxlength="50" tooltipPlacement='right'
                        [type]=TypeStringInNewPassword (keyup)="onKeyPress(addEditForm.controls.NewPassword)"
                        (keydown.space)="$event.preventDefault()" autocomplete="off" formControlName="NewPassword"
                        class="form-control" placeholder="Enter New Password" required
                        style="letter-spacing:normal!important">
                    <div *ngIf="!addEditForm.controls.NewPassword.valid && (addEditForm.controls.NewPassword.dirty || addEditForm.controls.NewPassword.touched)"
                        class="error-text">
                        <div *ngIf="addEditForm.controls.NewPassword.errors.required">
                            Password is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="TypeStringInConfirmPassword == 'password'" class="form-group row mt-4">
            <label class="control-label col-lg-3 col-md-3 col-sm-3 col-xs-3 add-member-label" for="pwd">Confirm
                Password: </label>
            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                <div class="input-icon right">
                    <i class="fa fa-eye" style="cursor: pointer;  font-size: 14px!important;"
                        (click)="ChangetextTypeInConfirmPassword(isPassword = ! isPassword)"></i>
                    <input maxlength="50" [type]=TypeStringInConfirmPassword autocomplete="off"
                        formControlName="ConfirmPassword" (keydown.space)="$event.preventDefault()" class="form-control"
                        placeholder="Confirm New Password" required style="letter-spacing:normal!important">
                    <div *ngIf="!addEditForm.controls.ConfirmPassword.valid && (addEditForm.controls.ConfirmPassword.dirty || addEditForm.controls.ConfirmPassword.touched)"
                        class="error-text">
                        <div *ngIf="addEditForm.controls.ConfirmPassword.errors.required">
                            Password is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="TypeStringInConfirmPassword == 'text'" class="form-group row mt-4">
            <label class="control-label col-lg-3 col-md-3 col-sm-3 col-xs-3 add-member-label" for="pwd">Confirm
                Password: </label>
            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                <div class="input-icon right">
                    <i class="fa fa-eye-slash" style="cursor: pointer;  font-size: 14px!important;"
                        (click)="ChangetextTypeInConfirmPassword(isPassword = ! isPassword)"></i>
                    <input maxlength="50" [type]=TypeStringInConfirmPassword autocomplete="off"
                        formControlName="ConfirmPassword" (keydown.space)="$event.preventDefault()" class="form-control"
                        placeholder="Confirm New Password" required style="letter-spacing:normal!important">
                    <div *ngIf="!addEditForm.controls.ConfirmPassword.valid && (addEditForm.controls.ConfirmPassword.dirty || addEditForm.controls.ConfirmPassword.touched)"
                        class="error-text">
                        <div *ngIf="addEditForm.controls.ConfirmPassword.errors.required">
                            Password is required
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </form>

</div>
<mat-dialog-actions align="center" style="padding-bottom: 10px ;margin-right: 12px;">
    <button class="btn btn-primary fa fa-refresh" (click)="updatePassword()"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white;">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Confirm</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
</mat-dialog-actions>