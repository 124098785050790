<div class="">
    <div class="row">
        <div class="col-sm-12">
            <button class="btn fa fa-search m-2" style="BACKGROUND: #6e7dd2; color:#fff"
                (click)="filterToggle = !filterToggle">
                <span>&nbsp;Date Filter</span>
            </button>
            <button class="btn pull-right m-2" style="BACKGROUND: #6e7dd2; color:#fff"(click)="exportAsExcel()">
                Download CSV
            </button>
        </div>
    </div>
    <mat-card class="filter col-lg-9 px-0 py-1" style="margin-top: 10px;" *ngIf="filterToggle">
        <mat-card-content>
            <div class="row">
                <div class="col-sm-12">
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('Today')">
                        Today
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('Week')">
                        Next 7 days
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('MTD')">
                        MTD
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="dateFilterChanged('YTD')">
                        YTD
                    </button>
                    <button type="button" mat-raised-button class="btn btn-secondary m-1"
                        (click)="CustomDate = !CustomDate">
                        Custom Date
                    </button>
                </div>
            </div>
            <div class="row col-sm-12 m-2 mb-3" *ngIf="CustomDate">
                <div class="col-lg-5 m-0 p-0">
                    <div class="col-sm-2 newMarginClass">
                        From:
                    </div>
                    <div class="col-sm-8">
                        <!-- <input type="text" class="form-control" placeholder="--Enter Registration--"> -->
                        <div class="input-icon right" mat-menu-item>
                            <!-- <i class="fa fa-calendar" [owlDateTimeTrigger]="dt1"></i> -->
                            <input class="form-control" placeholder="Select Date" class="form-control" id="db"
                                [(ngModel)]="fromDate" (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt1"
                                [owlDateTime]="dt1" name="dt1" required />
                            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 m-0 p-0">
                    <div class="col-sm-2 newMarginClass">
                        To:
                    </div>
                    <div class="col-sm-8">
                        <!-- <input type="text" class="form-control" placeholder="--Enter Registration--"> -->
                        <div class="input-icon right" mat-menu-item>
                            <!-- <i class="fa fa-calendar" [owlDateTimeTrigger]="dt2"></i> -->
                            <input class="form-control" placeholder="Select Date" class="form-control" id="db"
                                [(ngModel)]="toDate" (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt2"
                                [owlDateTime]="dt2" name="dt2" required />
                            <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                        </div>
                    </div>
                </div>
                <div class="col-lg-1 p-0 m-0 ">
                    <button type="button" class="add-hover btn btn-success pull-right mr-2 fa fa-search"
                        style="BACKGROUND: #6e7dd2; color:#fff" (click)="dateFilterChanged('Dates')">
                        Search
                    </button>
                </div>
                <div class="col-lg-1 p-0 m-0">
                    <button type="button" class="btn filter-hover fa fa-eraser"
                        style="background-color: #37474f; color: #fff !important;" (click)="datefilterclear()">
                        Clear
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <div class="card card-body mt-2">
        <div class="row mt-2">
            <div class="col">
                <div widget class="card border-0 box-shadow">
                    <div class="card-body widget-body">
                        <div class="row form-inline">
                            <div class="form-group col-xl-1 col-sm-6 col-12" style="color: black;">Filter By:</div>
                            <div class="form-group col-xl-2 col-sm-6 col-12">
                                <mat-select placeholder="Filter by" style="width: 100%; border: 1px solid black;"
                                    onselect="reset()" placeholder="Filter by" [(ngModel)]="filterBy"
                                    class="form-control">
                                    <mat-option value="any">Any</mat-option>
                                    <mat-option value="bookingId">Booking Id</mat-option>
                                    <!-- <mat-option value="customername">Customer Name</mat-option> -->
                                    <mat-option value="xpcn">XPCN No</mat-option>
                                    <mat-option value="po_lr">Customer Doc/LR/PO</mat-option>
                                </mat-select>
                            </div>
                            <div class="form-group col-sm-3">
                                <div class="col-sm-3  form-group " style="color: black;">
                                    Keyword:
                                </div>
                                <div class="col-sm-9 ">
                                    <input style="width: 90%; border: 1px solid black;" type="text"
                                        [(ngModel)]="keyword" class="form-control " placeholder="Search by Keyword">
                                </div>
                            </div>
                            <!-- <div class="form-group col-sm-3">
                                <div class="col-sm-3  form-group " style="color: black;">
                                    Date:
                                </div>
                                <div class="col-sm-9 ">
                                    <div class="input-icon right">
                                        <i class="fa fa-calendar" [owlDateTimeTrigger]="dt1"></i>
                                        <input type="text" class="form-control"
                                            style="min-width: 150px; margin-left: 10px;" placeholder="Select Date"
                                            [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" name="dt1" />
                                        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-group col-sm-3">
                                <button class="btn fa fa-search" style="BACKGROUND: #6e7dd2; color:#fff"
                                    (click)="filter()">
                                    <span> Search</span>
                                </button>
                                <button class="btn fa fa-eraser"
                                    style="margin-left: 10px ; background-color:#37474f; color: #fff; "
                                    (click)="clear()">
                                    <span> Clear</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 mt-2 p-0 ml-0 mr-0 ">
        <div class="table table-responsive">
            <table id="example3 " class="table table-bordered  p-0 m-0" matSort>

                <thead class="tbl-head m-0 p-0">

                    <tr class="tr-grid-header m-0 p-0" style="BACKGROUND: #6e7dd2;">
                        <th style="min-width: 60px;color: white;" scope="col">S No.</th>
                        <th scope="col" style="min-width: 150px;color: white;">Booking Date</th>
                        <th style="color: white;" scope="col">Customer Doc/LR/PO</th>
                        <th style="min-width: 150px;color: white;" scope="col">XP Booking Id</th>
                        <th scope="col" style="min-width: 190px;color: white;">Customer Name</th>
                        <th style="color: white;" scope="col">Consignor Name</th>
                        <th style="color: white;min-width: 190px;" scope="col">Customer Pick-up Location</th>
                        <th style="color: white;" scope="col">XPCN No</th>
                        <th scope="col" style="min-width: 150px;color: white;">XPCN Generation Date</th>
                        <th style="color: white;" scope="col">No of Packages</th>
                        <th style="color: white;min-width: 160px;" scope="col">Consignee Name</th>
                        <th style="color: white;" scope="col">Consignee Location</th>

                        <th scope="col" style="min-width: 150px;color: white;">Picked Date </th>
                        <th scope="col" style="min-width: 150px;color: white;">Delivered Date</th>
                        <th style="color: white;" scope="col">Delivery Days</th>
                        <th scope="col" style="min-width: 150px;color: white;">View POD</th>
                        <th scope="col" style="min-width: 150px;color: white;">Remarks</th>
                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let item of OrderList; let i=index">
                        <td>{{i+1}}</td>
                        <td>{{item.BookingDate | date: "dd-MM-y"}}</td>
                        <td>{{item.loding_doc}}</td>
                        <td>{{item.BookingIdCode}}</td>
                        <td>{{item.Customer}}</td>
                        <td>{{item.consignor}}</td>
                        <td>{{item.PickUpAddress}}</td>
                        <!-- <td class="inner-screen"> <a class="link" (click)="openLCLXPCNPreview(item.XPCNId)">{{item.XPCNNo}}</a></td> -->
                        <td>
                            <a class="link" (click)="openLCLXPCNPreview(item.XPCNId)">{{item.XPCNCode}}</a>
                        </td>
                        <td>{{item.XPCNDate | date: "dd-MM-y"}}</td>
                        <td>{{item.boxescount}}</td>
                        <td>{{item.consignee}}</td>
                        <td>{{item.consignee_location}}</td>
                        <td>{{item.PickedDate | date: "dd-MM-y"}}</td>
                        <td>{{item.DeliveryDate | date: "dd-MM-y"}}</td>
                        <td>{{item.DeliveryDays}}</td>
                        <td><a href="{{item.PodDocUrl}}" target="_blank"><button *ngIf="item.PodDocUrl != null"
                                    class="btn btn-success btn-sm">View POD</button></a>
                            <a><button *ngIf="item.PodDocUrl == null" disabled class="btn btn-success btn-sm">View
                                    POD</button></a>
                        </td>
                        <td>{{item.pod_remark}}</td>

                    </tr>
                    <tr *ngIf="OrderList.length == 0">
                        <td class="text-center" colspan="100">No Records Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <mat-paginator [length]="totalSize" [pageIndex]="currentPage" [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="handlePage($event)" showFirstLastButtons>
    </mat-paginator>
</div>