<div class="row">
    <div class="col-xs-12" style="padding: 0;">

        <button class="btn-primary pull-right" mat-raised-button (click)="backto()">
            Back
        </button>
    </div>
    <div class="col-xs-12" style="padding: 0px;margin-top: 10px;">
        <form class="form-horizontal col-sm-12" novalidate [formGroup]="addEditForm">
            <div class="row">

                <div class="col-xs-12">
                    <div class="col-xs-12 col-sm-6 col-md-3 bg-white"
                        style="display: inline-block; padding-bottom: 20px; padding-top: 25px;">
                        <div class="form-check form-check-inline">
                            Invoice Value Below Rs. 50K:&nbsp;&nbsp;
                            <mat-radio-group formControlName="InvValueBelow50k">
                                <mat-radio-button (click)="invValChanged(true)" class="margin"
                                    [value]="true">Yes</mat-radio-button>
                                <mat-radio-button (click)="invValChanged(false)" class="margin"
                                    style="margin-left: 10px" [value]="false">No
                                </mat-radio-button>
                            </mat-radio-group>
                            <!-- &nbsp;&nbsp; -->
                            <!-- <input class="form-check-input" type="radio" name="InvValueBelow50k" id="inlineRadio1"
                                value=true formControlName="InvValueBelow50k"
                                (change)="invValChanged($event.target.value)" />
                            <label class="form-check-label" for="inlineRadio1">Yes</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input class="form-check-input" type="radio" name="InvValueBelow50k" id="inlineRadio2"
                                value=false formControlName="InvValueBelow50k"
                                (change)="invValChanged($event.target.value)" />
                            <label class="form-check-label" for="inlineRadio2">No</label> -->
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 bg-white"
                        style="display: inline-block; padding-bottom: 20px;">
                        <div *ngIf="!isXPCNFinalized">
                            <input style="width: 250px; margin-top: 22px; display: inline-block;" type="text"
                                class="form-control col-sm-9 mr-2" placeholder="Enter EWaybill No"
                                formControlName="SearchEWB" maxlength="12" />
                            <button class="btn  add-hover btn-success" style="margin-top: -7px; display: inline-block;"
                                title="Search" (click)="searchEwaybill()">Search
                            </button>

                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label>Customer LR/CN:</label>
                            <input type="text" class="form-control" placeholder="Enter LR/CN number"
                                (keyup)="lrChanged($event.target.value)" formControlName="LR_PO">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label>Customer ASN/PO:</label>
                            <input type="text" class="form-control" placeholder="Enter ASN/PO number"
                                (keyup)="asnChanged($event.target.value)" formControlName="ASNNo">
                        </div>
                    </div>

                    <!-- Booking Id -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Booking Id:</label>
                            <input type="text" disabled class="form-control" placeholder="Booking Number"
                                [(ngModel)]="bookingId" [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <!-- XPCN No -->
                        <div class="form-group form-group-height margin-btn ">
                            <label class="">XPCN No.:</label>
                            <input type="text" disabled class="form-control" formControlName="XPCNCode"
                                placeholder="Enter XPCN No.">
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <!-- Order Date -->
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Order Date:</label>
                            <input type="text" disabled class="form-control" placeholder="Enter Order Date"
                                formControlName="strOrderDate">
                        </div>
                    </div>

                    <!-- Order Id -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Order Number:</label>
                            <input type="text" disabled class="form-control" placeholder="Enter Order Number"
                                formControlName="OrderIdCode">
                        </div>
                    </div>

                    <!-- Vehicle Number -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: none;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Vehicle Number:</label>
                            <input type="text" disabled class="form-control" placeholder="Enter Vehicle Number"
                                formControlName="VehicleNo">
                        </div>
                    </div>




                    <!-- Origin -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Origin:</label>
                            <input type="text" disabled class="form-control" placeholder="Enter Origin"
                                formControlName="From">
                        </div>
                    </div>

                    <!-- Destination -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Destination:</label>
                            <input type="text" disabled class="form-control" placeholder="Enter Destination"
                                formControlName="To">
                        </div>
                    </div>

                    <!-- Consignor -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Consignor:</label>
                            <input type="text" disabled class="form-control" placeholder="Enter Consignor"
                                formControlName="ConsignorName">
                        </div>
                    </div>

                    <!-- Consignee -->
                      <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Consignee:</label>
                            <input type="text" disabled class="form-control" placeholder="Enter Consignee"
                                formControlName="ConsigneeName">
                        </div>
                    </div>
                    <!-- <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Consignee:</label>
                            <mat-select type="text" (selectionChange)="FilterConsignee($event.value)"
                                formControlName="ConsigneeId" class="form-control" placeholder="--select consignee--">
                                <mat-form-field style="width: 100%;">
                                    <input matInput placeholder="&nbsp;&nbsp;Search Consignee"
                                        (input)="FilterConsigneeDetails($event.target.value)" />
                                </mat-form-field>
                                <mat-option *ngFor="let item of ConsigneeDetailsFilt" [value]="item.LookupId">
                                    {{item.LookupName}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div> -->

                    <!-- Goods Description -->

                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Goods Descriptions:</label>
                            <mat-select class="form-control" placeholder="Select Goods Description"
                                formControlName="GoodsDescId">


                                <mat-form-field style="width: 100%;">
                                    <input matInput placeholder="&nbsp;&nbsp;Search Goods Description"
                                        (input)="FilterDescription($event.target.value)" />
                                </mat-form-field>
                                <mat-option *ngFor="let item of goodsDescription" [value]="item.LookupId">
                                    {{item.LookupName}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="!addEditForm.controls.GoodsDescId.valid && (addEditForm.controls.GoodsDescId.dirty || addEditForm.controls.GoodsDescId.touched) || isFinalizeClicked"
                                class="error-text">
                                <div *ngIf="addEditForm.controls.GoodsDescId.errors?.required">
                                    Goods Description is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Package Types -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Package Type:</label>
                            <mat-select class="form-control" placeholder="Select Goods Descriptions"
                                formControlName="PackageTypeId">
                                <mat-option *ngFor="let item of packageTypes" [value]="item.LookupId">
                                    {{item.LookupName}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="!addEditForm.controls.PackageTypeId.valid && (addEditForm.controls.PackageTypeId.dirty || addEditForm.controls.PackageTypeId.touched) || isFinalizeClicked"
                                class="error-text">
                                <div *ngIf="addEditForm.controls.PackageTypeId.errors?.required">
                                    Package Type is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Boxes/Packages:</label>
                            <input type="text" class="form-control" (keypress)="onlyNumbers($event)" maxlength="6"
                                disabled placeholder="Enter Quantity" formControlName="NoOfPackages">
                            <div *ngIf="!addEditForm.controls.NoOfPackages.valid && (addEditForm.controls.NoOfPackages.dirty || addEditForm.controls.NoOfPackages.touched) || isFinalizeClicked"
                                class="error-text">
                                <div *ngIf="addEditForm.controls.NoOfPackages.errors?.required">
                                    Boxes/Packages is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Quantity -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Quantity as Pieces/Boxes:</label>
                            <input type="text" class="form-control" (keypress)="onlyNumbers($event)" maxlength="6"
                                placeholder="Enter Quantity" formControlName="Quantity">
                            <div *ngIf="!addEditForm.controls.Quantity.valid && (addEditForm.controls.Quantity.dirty || addEditForm.controls.Quantity.touched) || isFinalizeClicked"
                                class="error-text">
                                <div *ngIf="addEditForm.controls.Quantity.errors?.required">
                                    Quantity is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Actual Weight -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <div class="form-group form-group-height margin-btn">
                            <label class="">Actual Weight(Kg):</label>
                            <input type="text" class="form-control" (keypress)="priceValidator($event)" disabled
                                (keyup)="calculateTotalPkgsWt()" placeholder="Actual Weight(Kgs)"
                                formControlName="Weight">
                            <div *ngIf="!addEditForm.controls.Weight.valid && (addEditForm.controls.Weight.dirty || addEditForm.controls.Weight.touched) || isFinalizeClicked"
                                class="error-text">
                                <div *ngIf="addEditForm.controls.Weight.errors?.required">
                                    Actual Weight is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Volumetric Weight -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <label class="">Volumetric Weight:</label>
                        <input type="text" class="form-control" placeholder="Volumetric Weight(Kgs)" disabled
                            formControlName="VolumetricWeight">
                        <div *ngIf="!addEditForm.controls.VolumetricWeight.valid && (addEditForm.controls.VolumetricWeight.dirty || addEditForm.controls.VolumetricWeight.touched) || isFinalizeClicked"
                            class="error-text">
                            <div *ngIf="addEditForm.controls.VolumetricWeight.errors?.required">
                                Volumetric Weight is required
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <label class="">Minimum Charged Weight:</label>
                        <input type="text" class="form-control" disabled formControlName="MinChargedWeight">
                    </div>

                    <!-- Charged Weight -->
                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">
                        <label class="">Charged Weight:</label>
                        <input type="text" class="form-control" placeholder="Charged Weight(Kgs)" disabled
                            formControlName="ChargedWeight">
                        <div *ngIf="!addEditForm.controls.ChargedWeight.valid && (addEditForm.controls.ChargedWeight.dirty || addEditForm.controls.ChargedWeight.touched) || isFinalizeClicked"
                            class="error-text">
                            <div *ngIf="addEditForm.controls.ChargedWeight.errors?.required">
                                Charged Weight is required
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-3" style="display: inline-block;">

                    </div>

                </div>
            </div>


            <!-- <div class="pull-right">
                <div style="display: flex;">
                    <input name="boxes" min="0" (keyup)="addBox(noOfBoxesAdded,MULTIPLE)" id="boxes"
                        [(ngModel)]='noOfBoxesAdded' [ngModelOptions]="{standalone: true}"
                        [disabled]='boxesFromBackend > 0 ' style="width: 70px;margin-right: 8px;margin-top: 22px; display: inline-block !important;"
                        class="form-control text-center" type="number" name="quantity" value="0">

                    <button class="btn add-hover btn-success mr-5" style="margin-top: 21px; display: inline-block !important;" (click)="addBox(1,SINGLE)"
                        *ngIf="!isXPCNFinalized && boxesFromBackend > 0">Add</button>
                </div>

            </div>
            <div class="pull-right">
                <label for="boxes" style="margin-right: 15px;margin-top: 28px;">No of Boxes :</label>
            </div> -->


            <div class="col-sm-12" style="padding: 0px;">
                <!-- Consignor Details -->
                <div class="col-xs-6 col-sm-6" style="display: inline-block; padding-left: 0px;">
                    <div class="col-xs-12 bg-for-header bg-white" style="padding-bottom: 20px;">
                        <br>
                        <div class="form-group">
                            <label class="control-label col-xs-6 ">&nbsp;&nbsp;&nbsp;&nbsp;<b>Consignor
                                    Details:</b></label>
                        </div>


                        <!-- Consignor Name -->
                        <div class="form-group">
                            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label"
                                style="display: inline-block; vertical-align: top;">Name:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details"
                                style="display: inline-block; vertical-align: top;">
                                <input type="text" formControlName="NameOfConsignor" class="form-control"
                                    placeholder="Name" required>
                                <div *ngIf="!addEditForm.controls.NameOfConsignor.valid && (addEditForm.controls.NameOfConsignor.dirty || addEditForm.controls.NameOfConsignor.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.NameOfConsignor.errors?.required">
                                        Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignor Mobile Number -->
                        <div class="form-group">
                            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details">Mobile No:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <input type="text" formControlName="ConsignorMobile" (keypress)="onlyNumbers($event)"
                                    minlength="10" maxlength="10" class="form-control" placeholder="Enter Mobile No">
                                <div *ngIf="!addEditForm.controls.ConsignorMobile.valid && (addEditForm.controls.ConsignorMobile.dirty || addEditForm.controls.ConsignorMobile.touched) "
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsignorMobile.errors?.required">
                                        Mobile No is required
                                    </div>
                                    <div *ngIf="addEditForm.controls.ConsignorMobile.errors.minlength">
                                        Mobile No should contain 10 numbers
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignor Email ID -->
                        <div class="form-group">
                            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details">Email ID:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <input type="text" email maxlength="50" formControlName="ConsignorEmail"
                                    class="form-control" placeholder="Enter Email ID">

                            </div>
                        </div>

                        <!-- Consignor State -->
                        <div class="form-group">
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">State:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <mat-select formControlName="ConsignorStateId"
                                    (selectionChange)="stateChanged($event.value,CONSIGNOR)" required
                                    placeholder="--Select State--" class="form-control">
                                    <!-- <mat-option>
                                        <mat-select-search formControlName="ConsignorStateSearch"
                                            [placeholderLabel]="'--Select State--'" [noEntriesFoundLabel]="'Not found'">
                                        </mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of statesListConsignor |stringFilterBy:addEditForm.controls.ConsignorStateSearch.value:'StateName'"
                                        [value]="item.StateId">{{item.StateName}}</mat-option> -->
                                    <mat-option *ngFor="let item of statesListConsignor"
                                        [value]="item.StateId">{{item.StateName}}</mat-option>
                                </mat-select>
                                <div *ngIf="!addEditForm.controls.ConsignorStateId.valid && (addEditForm.controls.ConsignorStateId.dirty || addEditForm.controls.ConsignorStateId.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsignorStateId.errors?.required">
                                        State is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignor District -->
                        <div class="form-group">
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">District:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <mat-select formControlName="ConsignorDistrictId"
                                    (selectionChange)="districtChanged($event.value,CONSIGNOR)" required
                                    placeholder="--Select District--" class="form-control">
                                    <!-- <mat-option>
                                        <mat-select-search formControlName="ConsignorDistrictSearch"
                                            [placeholderLabel]="'--Select District--'"
                                            [noEntriesFoundLabel]="'Not found'">
                                        </mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of districtsListConsignor |stringFilterBy:addEditForm.controls.ConsignorDistrictSearch.value:'DistrictName'"
                                        [value]="item.DistrictId">{{item.DistrictName}}
                                    </mat-option> -->
                                    <mat-option *ngFor="let item of districtsListConsignor"
                                        [value]="item.DistrictId">{{item.DistrictName}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="!addEditForm.controls.ConsignorDistrictId.valid && (addEditForm.controls.ConsignorDistrictId.dirty || addEditForm.controls.ConsignorDistrictId.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsignorDistrictId.errors?.required">
                                        District is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignor Location -->
                        <div class="form-group">
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">Location:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <mat-select formControlName="ConsignorLocationId"
                                    (selectionChange)="locationChanged($event.value,CONSIGNOR)"
                                    placeholder="--Select Location--" class="form-control" name="ConsignorLocationName"
                                    required>
                                    <!-- <mat-option>
                                        <mat-select-search formControlName="ConsignorLocationSearch"
                                            [placeholderLabel]="'--Select Location--'"
                                            [noEntriesFoundLabel]="'Not found'">
                                        </mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of locationsListConsignor |stringFilterBy:addEditForm.controls.ConsignorLocationSearch.value:'LocationName'"
                                        [value]="item.LocationId">{{item.LocationName}}</mat-option> -->
                                    <mat-option *ngFor="let item of locationsListConsignor"
                                        [value]="item.LocationId">{{item.LocationName}}</mat-option>
                                </mat-select>
                                <div *ngIf="!addEditForm.controls.ConsignorLocationId.valid && (addEditForm.controls.ConsignorLocationId.dirty || addEditForm.controls.ConsignorLocationId.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsignorLocationId.errors?.required">
                                        Location is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignor Postcode -->
                        <div class="form-group">
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">Postcode:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <mat-select formControlName="ConsignorPostcodeId"
                                    (selectionChange)="postcodeChanged($event.value)" placeholder="--Select Postcode--"
                                    class="form-control" name="ConsignorPostcodeName" required>
                                    <!-- <mat-option>
                                        <mat-select-search formControlName="ConsignorPostcodeSearch"
                                            [placeholderLabel]="'--Select Postcode--'"
                                            [noEntriesFoundLabel]="'Not found'">
                                        </mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of postcodesListConsignor |stringFilterBy:addEditForm.controls.ConsignorPostcodeSearch.value:'PostcodeName'"
                                        [value]="item.PostcodeId">{{item.PostcodeName}}</mat-option> -->
                                    <mat-option *ngFor="let item of postcodesListConsignor"
                                        [value]="item.PostcodeId">{{item.PostcodeName}}</mat-option>
                                </mat-select>
                                <div *ngIf="!addEditForm.controls.ConsignorPostcodeId.valid && (addEditForm.controls.ConsignorPostcodeId.dirty || addEditForm.controls.ConsignorPostcodeId.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsignorPostcodeId.errors?.required">
                                        Postcode is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignor GSTIN -->
                        <div class="form-group">
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">GSTIN:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <input type="text" formControlName="ConsignorGSTIN" maxlength="15" class="form-control"
                                    placeholder="Enter GSTIN" required>
                                <div *ngIf="!addEditForm.controls.ConsignorGSTIN.valid && (addEditForm.controls.ConsignorGSTIN.dirty || addEditForm.controls.ConsignorGSTIN.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsignorGSTIN.errors?.required">
                                        GSTIN is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignor Address -->
                        <div class="form-group">
                            <label class="control-label col-sm-4 add-member-label cc_details">Address: </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <textarea class="form-control txtarea" maxlength="500" rows="2"
                                    formControlName="ConsignorAddress" placeholder="Enter Address" required></textarea>
                                <div *ngIf="!addEditForm.controls.ConsignorAddress.valid && (addEditForm.controls.ConsignorAddress.dirty || addEditForm.controls.ConsignorAddress.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsignorAddress.errors?.required">
                                        Address is required
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Consignee Details -->
                <div class="col-xs-6 col-sm-6" style="display: inline-block; padding: 0px;">
                    <div class="col-xs-12 bg-for-header bg-white" style="padding-bottom: 20px;">
                        <br>

                        <div class="form-group">
                            <label class="control-label col-xs-6 ">&nbsp;&nbsp;&nbsp;&nbsp;<b>Consignee
                                    Details:</b></label>
                        </div>

                        <!-- Consignee Name -->
                        <div class="form-group">
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">Name:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <input type="text" formControlName="NameOfConsignee"
                                    (keypress)="onlyAlphabetsWithSpace($event)" class="form-control" placeholder="Name"
                                    required>
                                <div *ngIf="!addEditForm.controls.NameOfConsignee.valid && (addEditForm.controls.NameOfConsignee.dirty || addEditForm.controls.NameOfConsignee.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.NameOfConsignee.errors?.required">
                                        Name is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignee Mobile Number -->
                        <div class="form-group">
                            <!-- <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details">Mobile No: </label> -->
                            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details"
                                [ngClass]=" mobReq ? 'add-member-label' : ''">Mobile No: </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <input type="text" formControlName="ConsigneeMobile" (keypress)="onlyNumbers($event)"
                                    minlength="10" maxlength="10" class="form-control" placeholder="Enter Mobile No">
                                <div *ngIf="!addEditForm.controls.ConsigneeMobile.valid && (addEditForm.controls.ConsigneeMobile.dirty || addEditForm.controls.ConsigneeMobile.touched)"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsigneeMobile.errors?.required">
                                        Mobile No is required
                                    </div>
                                    <div *ngIf="addEditForm.controls.ConsigneeMobile.errors.minlength">
                                        Mobile No should contain 10 numbers
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details error-text"
                                style="margin-left:248px"
                                *ngIf="addEditForm.controls.ConsigneeMobile.errors?.required && mobReq==true">
                                Mobile No is required
                            </div>

                        </div>

                        <!-- Consignee Email ID -->
                        <div class="form-group">
                            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details">Email ID:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <input type="text" email maxlength="50" formControlName="ConsigneeEmail"
                                    class="form-control" placeholder="Enter Email ID">

                            </div>
                        </div>

                        <!-- Consignee State -->
                        <div class="form-group">
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">State:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <mat-select formControlName="ConsigneeStateId"
                                    (selectionChange)="stateChanged($event.value,CONSIGNEE)" required
                                    placeholder="--Select State--" class="form-control">
                                    <!-- <mat-option>
                                        <mat-select-search formControlName="ConsigneeStateSearch"
                                            [placeholderLabel]="'--Select State--'" [noEntriesFoundLabel]="'Not found'">
                                        </mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of statesListConsignee |stringFilterBy:addEditForm.controls.ConsigneeStateSearch.value:'StateName'"
                                        [value]="item.StateId">{{item.StateName}}</mat-option> -->
                                    <mat-option *ngFor="let item of statesListConsignee"
                                        [value]="item.StateId">{{item.StateName}}</mat-option>
                                </mat-select>
                                <div *ngIf="!addEditForm.controls.ConsigneeStateId.valid && (addEditForm.controls.ConsigneeStateId.dirty || addEditForm.controls.ConsigneeStateId.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsigneeStateId.errors?.required">
                                        State is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignee District -->
                        <div class="form-group">
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">District:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <mat-select formControlName="ConsigneeDistrictId"
                                    (selectionChange)="districtChanged($event.value,CONSIGNEE)" required
                                    placeholder="--Select District--" class="form-control">
                                    <!-- <mat-option>
                                        <mat-select-search formControlName="ConsigneeDistrict"
                                            [placeholderLabel]="'--Select District--'"
                                            [noEntriesFoundLabel]="'Not found'">
                                        </mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of districtsListConsignee |stringFilterBy:addEditForm.controls.ConsigneeDistrict.value:'DistrictName'"
                                        [value]="item.DistrictId">{{item.DistrictName}}
                                    </mat-option> -->
                                    <mat-option *ngFor="let item of districtsListConsignee"
                                        [value]="item.DistrictId">{{item.DistrictName}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="!addEditForm.controls.ConsigneeDistrictId.valid && (addEditForm.controls.ConsigneeDistrictId.dirty || addEditForm.controls.ConsigneeDistrictId.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsigneeDistrictId.errors?.required">
                                        District is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignee Location -->
                        <div class="form-group">
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">Location:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <mat-select formControlName="ConsigneeLocationId"
                                    (selectionChange)="locationChanged($event.value,CONSIGNEE)"
                                    placeholder="--Select Location--" class="form-control" name="ConsigneeLocationName"
                                    required>
                                    <!-- <mat-option>
                                        <mat-select-search formControlName="ConsigneeLocationSearch"
                                            [placeholderLabel]="'--Select Location--'"
                                            [noEntriesFoundLabel]="'Not found'">
                                        </mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of locationsListConsignee |stringFilterBy:addEditForm.controls.ConsigneeLocationSearch.value:'LocationName'"
                                        [value]="item.LocationId">{{item.LocationName}}</mat-option> -->
                                    <mat-option *ngFor="let item of locationsListConsignee"
                                        [value]="item.LocationId">{{item.LocationName}}</mat-option>
                                </mat-select>
                                <div *ngIf="!addEditForm.controls.ConsigneeLocationId.valid && (addEditForm.controls.ConsigneeLocationId.dirty || addEditForm.controls.ConsigneeLocationId.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsigneeLocationId.errors?.required">
                                        Location is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignee Postcode -->
                        <div class="form-group">
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">Postcode:
                            </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <mat-select formControlName="ConsigneePostcodeId"
                                    (selectionChange)="postcodeChanged($event.value)" placeholder="--Select Postcode--"
                                    class="form-control" name="ConsigneePostcodeName" required>
                                    <!-- <mat-option>
                                        <mat-select-search formControlName="ConsigneePostcodeSearch"
                                            [placeholderLabel]="'--Select Postcode--'"
                                            [noEntriesFoundLabel]="'Not found'">
                                        </mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let item of postcodesListConsignee |stringFilterBy:addEditForm.controls.ConsigneePostcodeSearch.value:'PostcodeName'"
                                        [value]="item.PostcodeId">{{item.PostcodeName}}</mat-option> -->
                                    <mat-option *ngFor="let item of postcodesListConsignee"
                                        [value]="item.PostcodeId">{{item.PostcodeName}}</mat-option>
                                </mat-select>
                                <div *ngIf="!addEditForm.controls.ConsigneePostcodeId.valid && (addEditForm.controls.ConsigneePostcodeId.dirty || addEditForm.controls.ConsigneePostcodeId.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsigneePostcodeId.errors?.required">
                                        Postcode is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Consignee GSTIN -->
                        <div class="form-group row">
                            <!-- Input Field -->
                            <label
                                class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details add-member-label">GSTIN:
                            </label>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 cc_details">
                                <input type="text" formControlName="ConsigneeGSTIN" maxlength="15" class="form-control"
                                    placeholder="Enter GSTIN" required (keyup)="ValidateEnteredGISTIN()">
                                <div *ngIf="!addEditForm.controls.ConsigneeGSTIN.valid && (addEditForm.controls.ConsigneeGSTIN.dirty || addEditForm.controls.ConsigneeGSTIN.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsigneeGSTIN.errors?.required">
                                        GSTIN is required
                                    </div>
                                </div>
                            </div>

                            <!-- Checkbox -->
                            <label class="control-label col-lg-1 col-md-1 col-sm-1 col-xs-1 mt-1">URP: </label>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 ml-0 pl-0 mt-1">
                                <mat-checkbox (change)="isURPChecked($event)" formControlName="URPChecked"
                                    [disabled]="isXPCNFinalized"></mat-checkbox>
                            </div>
                        </div>

                        <!-- Consignee Address -->
                        <div class="form-group">
                            <label class="control-label col-sm-4 add-member-label cc_details">Address: </label>
                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 cc_details">
                                <textarea class="form-control txtarea" maxlength="500" rows="2"
                                    formControlName="ConsigneeAddress" placeholder="Enter Address" required></textarea>
                                <div *ngIf="!addEditForm.controls.ConsigneeAddress.valid && (addEditForm.controls.ConsigneeAddress.dirty || addEditForm.controls.ConsigneeAddress.touched) || isFinalizeClicked"
                                    class="error-text">
                                    <div *ngIf="addEditForm.controls.ConsigneeAddress.errors?.required">
                                        Address is required
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-xs-12">
                <br>
                <h4 class="flex-p bg-for-header">Invoice Details</h4>
                <br>
                <div class="row" style="margin-left: 0px; margin-right: 0px;">
                    <div class="col-sm-12 bg-white">
                        <br>
                        <!-- <button *ngIf="!isXPCNFinalized" class="btn-success pull-right ml-2"
                            (click)="downloadSampleExcel()" mat-raised-button style="margin-bottom: 10px;">
                            Download Sample
                        </button>
                        <button *ngIf="!isXPCNFinalized" class="btn-success pull-right ml-2" (click)="openUploadCSV()"
                            mat-raised-button style="margin-bottom: 10px;">
                            Upload CSV
                        </button> -->

                        <button *ngIf="!isXPCNFinalized" class="btn-success pull-right" (click)="addEditInvoices(null)"
                            mat-raised-button style="margin-bottom: 10px;">
                            Add Invoice
                        </button>


                        <!-- <button class="btn-danger pull-left" mat-mini-fab
                style="margin-bottom: 10px;" mwlConfirmationPopover [popoverTitle]="popoverTitle"
                [popoverMessage]="'Are you Sure you want to delete selected Records ?'" matTooltip="Delete Invoices"
                placement="left" (confirm)="DeleteInvoiceRecords()" (cancel)="cancelClicked = true" [appendToBody]="true">
                <mat-icon>delete</mat-icon>
  
              </button> -->


                        <div class="table-responsive">
                            <table id="example2" class="table table-bordered table-hover">
                                <thead class="tbl-head">
                                    <tr class="tr-grid-header">
                                        <th class="text-center">
                                            SL No

                                        </th>
                                        <th>Invoice No</th>
                                        <th>Issue Date</th>
                                        <th>Value (Rs)</th>
                                        <th class="text-center">View PDF</th>
                                        <th *ngIf="!isXPCNFinalized" class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of invoices;let i = index">
                                        <td class="text-center">
                                            {{i+1}}
                                        </td>
                                        <td>{{item.InvoiceNo}}</td>
                                        <td>{{item.Date | date : 'dd-MM-y'}}</td>
                                        <td>{{item.InvoiceValue}}</td>
                                        <td class="text-center">
                                            <a *ngIf="item.DocUrl != null && item.DocUrl != ''"
                                                style="margin-right: 10px; cursor: pointer;font-size:16px!important;color:#0987e8d1"
                                                matTooltip="View Document" href="{{item.DocUrl}}" target="_blank">
                                                <i class="fa fa-file"></i>
                                            </a>
                                            <span *ngIf="item.DocUrl == null || item.DocUrl == ''">-</span>
                                        </td>
                                        <td *ngIf="!isXPCNFinalized" class="text-center">
                                            <a matTooltip="Edit Invoice"
                                                style="margin-right: 10px; cursor: pointer;font-size:16px!important;color:#0987e8d1"
                                                (click)="addEditInvoices(item)">
                                                <i class="fa fa-edit"></i>
                                            </a>

                                            <a mwlConfirmationPopover [popoverTitle]="popoverTitle"
                                                [popoverMessage]="popoverMessage" matTooltip="Delete Invoice"
                                                placement="left" (confirm)="deleteInvoice(item)"
                                                (cancel)="cancelClicked = true" [appendToBody]="true">
                                                <i style="cursor: pointer;font-size:16px!important;"
                                                    class="fa fa-trash red"></i>
                                            </a>
                                        </td>

                                    </tr>
                                    <tr *ngIf="invoices == null || invoices.length == 0">
                                        <td colspan="6" class="text-center">No records found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12" *ngIf="!invValLessThan50">
                        <br>
                        <h4 class="flex-p bg-for-header">E-Way Bill Details</h4>
                        <br>
                        <div class="row" style="margin-left: 0px; margin-right: 0px;">
                            <div class="col-sm-12 bg-white">
                                <br>
                                <!-- <button *ngIf="!isXPCNFinalized" class="btn-success pull-right ml-2"
                                    (click)="downloadEwaySampleExcel()" mat-raised-button style="margin-bottom: 10px;">
                                    Download Sample
                                </button>
                                <button *ngIf="!isXPCNFinalized" class="btn-success pull-right ml-2"
                                    (click)="openUploadEwayCSV()" mat-raised-button style="margin-bottom: 10px;">
                                    Upload CSV
                                </button> -->
                                <button *ngIf="!isXPCNFinalized" class="btn-success  pull-right"
                                    (click)="addEditEWayBills(null)" mat-raised-button style="margin-bottom: 10px;">
                                    Add E-Way Bill
                                </button>


                                <button *ngIf="finalized == false" class="btn-danger pull-left" mat-mini-fab
                                    style="margin-bottom: 10px;" mwlConfirmationPopover [popoverTitle]="popoverTitle"
                                    [popoverMessage]="'Are you Sure you want to delete selected Records ?'"
                                    matTooltip="Delete Ewaybills" placement="left" (confirm)="DeleteEwayRecords()"
                                    (cancel)="cancelClicked = true" [appendToBody]="true">Delete
                                </button>
                            </div>
                            <div class="col-sm-12 bg-white">
                                <div class="table-responsive">
                                    <table id="example2" class="table table-bordered table-hover">
                                        <thead class="tbl-head">
                                            <tr class="tr-grid-header">
                                                <th class="text-center">
                                                    SL no.
                                                </th>
                                                <th>E-Way Bill No</th>
                                                <th>Issue Date</th>
                                                <th>Expiry Date</th>
                                                <th>Value (Rs)</th>
                                                <th class="text-center">View PDF</th>
                                                <th *ngIf="!isXPCNFinalized" class="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of waybills; let i = index">
                                                <td class="text-center">
                                                    {{i+1}}
                                                </td>
                                                <td>{{item.InvoiceNo}}</td>
                                                <td>{{item.Date | date : 'dd-MM-y'}}</td>
                                                <td>{{item.ExpDate | date : 'dd-MM-y'}}</td>
                                                <td>{{item.InvoiceValue}}</td>
                                                <td class="text-center">
                                                    <a *ngIf="item.DocUrl != null && item.DocUrl != ''"
                                                        style="margin-right: 10px; cursor: pointer;font-size:16px!important;color:#0987e8d1"
                                                        matTooltip="View Document" href="{{item.DocUrl}}"
                                                        target="_blank">
                                                        <i class="fa fa-file"></i>
                                                    </a>
                                                    <span *ngIf="item.DocUrl == null || item.DocUrl == ''">-</span>
                                                </td>
                                                <td *ngIf="!isXPCNFinalized" class="text-center">
                                                    <a matTooltip="Edit E-Way Bill"
                                                        style="margin-right: 10px; cursor: pointer;font-size:16px!important;color:#0987e8d1"
                                                        (click)="addEditEWayBills(item)">
                                                        <i class="fa fa-edit"></i>
                                                    </a>

                                                    <a mwlConfirmationPopover [popoverTitle]="popoverTitle"
                                                        [popoverMessage]="popoverMessage" matTooltip="Delete E-Way Bill"
                                                        placement="left" (confirm)="deleteWaybill(item)"
                                                        (cancel)="cancelClicked = true" [appendToBody]="true">
                                                        <i style="cursor: pointer;font-size:16px!important;"
                                                            class="fa fa-trash red"></i>
                                                    </a>
                                                </td>

                                            </tr>
                                            <tr *ngIf="waybills == null || waybills.length == 0">
                                                <td colspan="7" class="text-center">No records found</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12">
                        <br>
                        <h4 class="flex-p bg-for-header">Docket Items</h4>
                        <br>
                        <div class="row" style="margin-left: 0px; margin-right: 0px;">
                            <div class="col-sm-12 bg-white">
                                <br>
                                <div class="form-group col-sm-4 col-12"><b>Measured Unit:</b>
                                    <mat-radio-group formControlName="Unit">
                                        <mat-radio-button class="margin margins" style="margin-left: 10px;" value="Inch"
                                            (click)="unitChanged('Inch')"   >
                                            Inch
                                        </mat-radio-button>
                                        <mat-radio-button class="margin margins" style="margin-left: 10px;"
                                            value="Centimeter" (click)="unitChanged('Centimeter')">
                                            Centimeter
                                        </mat-radio-button>
                                        
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="col-sm-12 bg-white">
                                <div class="table-responsive">
                                    <table id="example2" class="table table-bordered table-hover">
                                        <thead class="tbl-head">
                                            <tr class="text-center">
                                                <th scope="col"></th>
                                                <th scope="col">L</th>
                                                <th scope="col">B</th>
                                                <th scope="col">H</th>
                                                <th scope="col">Boxes/Packages</th>
                                                <th scope="col">Actual Weight(Kgs)</th>
                                                <th scope="col">Volumetric Weight(Kgs)</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>

                                        <tbody *ngIf="!isXPCNFinalized">
                                            <tr *ngIf='boxDetails.length == 0'>
                                                <td colspan="100" class="text-center">No Boxes Found</td>
                                            </tr>
                                            <tr *ngFor='let item of boxDetails;let i=index'>
                                                <td scope="row" class="text-center">
                                                    <a *ngIf="item.HUNumber == null && i > 0" (click)="deleteBox(i)">
                                                        <i class="btn btn-sm btn-danger fa fa-times"
                                                            style="cursor: pointer;" aria-hidden="true"></i>
                                                    </a>
                                                    <a *ngIf="item.HUNumber != null || i == 0" disabled>
                                                        <i class="btn btn-sm btn-danger fa fa-times"
                                                            style="cursor: not-allowed;" aria-hidden="true"></i>
                                                    </a>
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.Length"
                                                        [ngModelOptions]="{standalone: true}" maxlength="5"
                                                        (keyup)="calculateBoxWeight(i)" (keypress)="onlyNumbers($event)"
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.Breadth"
                                                        [ngModelOptions]="{standalone: true}" maxlength="5"
                                                        (keyup)="calculateBoxWeight(i)" (keypress)="onlyNumbers($event)"
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.Height"
                                                        [ngModelOptions]="{standalone: true}" maxlength="5"
                                                        (keyup)="calculateBoxWeight(i)" (keypress)="onlyNumbers($event)"
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.Pkgs" [ngModelOptions]="{standalone: true}"
                                                        maxlength="5" (keyup)="calculateBoxWeight(i)"
                                                        (keypress)="onlyNumbers($event)"
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.ActWeight"
                                                        [ngModelOptions]="{standalone: true}" maxlength="5"
                                                        (keyup)="calculateBoxWeight(i)" (keypress)="onlyNumbers($event)"
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.Weight"
                                                        [ngModelOptions]="{standalone: true}" disabled
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <button class="btn btn-sm btn-success fa fa-plus"
                                                        (click)="addBox(1,SINGLE)" style="margin-left: 10px ;"></button>
                                                </td>
                                            </tr>
                                            <tr class="text-center">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><b>Total:</b></td>
                                                <td><b>{{totalPkgs}}</b></td>
                                                <td><b>{{totalWt}}</b></td>
                                                <td><b>{{totalVWt}}</b></td>
                                                <td></td>
                                            </tr>
                                        </tbody>

                                        <tbody *ngIf="isXPCNFinalized">
                                            <tr *ngIf='boxDetails.length == 0'>
                                                <td colspan="100" class="text-center">No Boxes Found</td>
                                            </tr>
                                            <tr *ngFor='let item of boxDetails;let i=index'>
                                                <td scope="row" class="text-center">
                                                    <!-- <a *ngIf="item.HUNumber == null && i > 0" (click)="deleteBox(i)">
                                                        <i class="btn btn-sm btn-danger fa fa-times"
                                                            style="cursor: pointer;" aria-hidden="true"></i>
                                                    </a>
                                                    <a *ngIf="item.HUNumber != null || i == 0" disabled>
                                                        <i class="btn btn-sm btn-danger fa fa-times"
                                                            style="cursor: not-allowed;" aria-hidden="true"></i>
                                                    </a> -->
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.Length"
                                                        [ngModelOptions]="{standalone: true}" maxlength="5" disabled
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.Breadth"
                                                        [ngModelOptions]="{standalone: true}" maxlength="5" disabled
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.Height"
                                                        [ngModelOptions]="{standalone: true}" maxlength="5" disabled
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.Pkgs" [ngModelOptions]="{standalone: true}"
                                                        maxlength="5" disabled style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.ActWeight"
                                                        [ngModelOptions]="{standalone: true}" maxlength="5" disabled
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <input [(ngModel)]="item.Weight"
                                                        [ngModelOptions]="{standalone: true}" maxlength="5" disabled
                                                        style="text-align: center; width: 100%;">
                                                </td>
                                                <td class="text-center">
                                                    <!-- <button class="btn btn-sm btn-success fa fa-plus"
                                                        (click)="addBox(1,SINGLE)" style="margin-left: 10px ;"></button> -->
                                                </td>
                                            </tr>
                                            <tr class="text-center">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><b>Total:</b></td>
                                                <td><b>{{totalPkgs}}</b></td>
                                                <td><b>{{totalWt}}</b></td>
                                                <td><b>{{totalVWt}}</b></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-xs-12">
                        <br>
                        <h4 class="flex-p bg-for-header">Payment Details</h4>
                        <br>
                        <div class="col-md-6 cc_details bg-white" style="border-right: 15px solid #f5f5f5;">
                            <br>
                            <div class="col-md-6" style="display: inline-block;">
                                <div class="form-group form-group-height margin-btn">
                                    <label class="">Risk Type:</label>
                                    <mat-select class="form-control" placeholder="Select Risk Type"
                                        formControlName="RiskTypeId">
                                        <mat-option *ngFor="let item of riskTypes" [value]="item.LookupId">
                                            {{item.LookupName}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="!addEditForm.controls.RiskTypeId.valid && (addEditForm.controls.RiskTypeId.dirty || addEditForm.controls.RiskTypeId.touched) || isFinalizeClicked"
                                        class="error-text">
                                        <div *ngIf="addEditForm.controls.RiskTypeId.errors?.required">
                                            Risk Type is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div *ngIf="isBreakBulk" class="col-md-6">
                                <div class="form-group form-group-height margin-btn">
                                    <label class="">Mode of Freight:</label>
                                    <mat-select [disabled]="true" class="form-control"
                                        placeholder="Select Mode of Freight" formControlName="ModeOfFreightId"
                                        (selectionChange)="onFreightChange($event.value)">
                                        <mat-option *ngFor="let item of freightModes" [value]="item.LookupId">
                                            {{item.LookupName}}
                                        </mat-option>
                                    </mat-select>
                                    <div *ngIf="!addEditForm.controls.ModeOfFreightId.valid && (addEditForm.controls.ModeOfFreightId.dirty || addEditForm.controls.ModeOfFreightId.touched) || isFinalizeClicked"
                                        class="error-text">
                                        <div *ngIf="addEditForm.controls.ModeOfFreightId.errors?.required">
                                            Mode of Freight is required
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br>
                            <div class="col-md-6" style="display: inline-block;">
                                <div class="form-group form-group-height margin-btn">
                                    <label class="">Charges Paid By:</label>
                                    <mat-select class="form-control" placeholder="Select"
                                        formControlName="ChargesPaidByFFV">
                                        <mat-option *ngFor="let item of chargesPaidBy"
                                            [value]="item.value">{{item.name}}</mat-option>
                                        <!-- <mat-option [value]="false">XP</mat-option> -->
                                    </mat-select>
                                    <div *ngIf="!addEditForm.controls.ChargesPaidByFFV.valid && (addEditForm.controls.ChargesPaidByFFV.dirty || addEditForm.controls.ChargesPaidByFFV.touched) || isFinalizeClicked"
                                        class="error-text">
                                        <div *ngIf="addEditForm.controls.ChargesPaidByFFV.errors?.required">
                                            Charges Paid By is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="col-md-6" style="display: inline-block;">

                                <div class="form-group form-group-height margin-btn">
                                    <label class="">Value Added Services: &nbsp;</label>
                                    <mat-radio-group formControlName="isVAC">
                                        <mat-radio-button (click)="VACSelectionChange(true)" class="margin"
                                            [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button (click)="VACSelectionChange(false)" class="margin"
                                            style="margin-left: 10px" [value]="false">No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>
                            <br>
                            <div class="col-md-6" *ngIf="isValueAddedCharges">
                                <div class="form-group form-group-height margin-btn"
                                    style="display: flex;margin-bottom: 0px !important;">

                                    <mat-select (selectionChange)="VACRemarkSelectionChange($event)"
                                        class="form-control" placeholder="Select VAS Reason"
                                        formControlName="VACRemarkId">
                                        <mat-option *ngFor="let item of vacRemarksList" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>



                                    <mat-checkbox (change)="isBOD_OR_DODChecked($event)" *ngIf="isCODDODCheckBoxEnabled"
                                        class="marginClasImportant mt-3 ml-3"
                                        formControlName="CODBODChecked"></mat-checkbox>


                                </div>
                                <div *ngIf="!addEditForm.controls.VACRemarkId.valid && (addEditForm.controls.VACRemarkId.dirty || addEditForm.controls.VACRemarkId.touched) || isFinalizeClicked"
                                    class="error-text" style="margin-top: -27px;">
                                    <br><br>
                                    <div *ngIf="addEditForm.controls.VACRemarkId.errors?.required">
                                        VAS Reason is required
                                    </div>
                                </div>



                            </div>
                            <br>
                            <div class="col-md-6"
                                *ngIf="isValueAddedCharges && isCODDODCheckBoxEnabled && !isCODORDODChecked">
                                <div class="form-group form-group-height margin-btn"
                                    style="display: flex;margin-bottom: 0px !important;">

                                    <input type="text" class="form-control" formControlName="AmountToCollect"
                                        placeholder="Enter Amount">
                                </div>
                                <div *ngIf="!addEditForm.controls.AmountToCollect.valid && (addEditForm.controls.AmountToCollect.dirty || addEditForm.controls.AmountToCollect.touched) || isFinalizeClicked"
                                    class="error-text" style="margin-top: -27px;">
                                    <div *ngIf="addEditForm.controls.AmountToCollect.errors?.required">
                                        Amount is required
                                    </div>
                                </div>

                            </div>
                            <br>
                            <div class="col-md-6"
                                *ngIf="isValueAddedCharges && isCODDODCheckBoxEnabled && isCODORDODChecked">
                                <div class="form-group form-group-height margin-btn"
                                    style="display: flex;margin-bottom: 0px !important;">
                                    <p style="color: #059e2e;">Amount same as Invoice Amount</p>
                                </div>

                            </div>
                            <br>
                            <div class="col-md-6" style="display: inline-block;">
                                <div>
                                    <label>Special Remark:</label>
                                </div>
                                <div>
                                    <div class="form-group form-group-height margin-btn"
                                        style="display: flex;margin-bottom: 0px !important;">
                                        <!-- <input type="text" class="form-control" formControlName="" placeholder="Enter Remark..."> -->
                                        <textarea class="form-control txtarea" maxlength="500" rows="2"
                                            formControlName="SpecialRemarks"
                                            placeholder="Enter Remark Here....."></textarea>
                                    </div>
                                </div>
                                <br>
                            </div>
                            <br>
                            <div style="border-top: 15px solid whitesmoke; width: 105%; margin-left: -15px;"></div>
                            <br>
                            <h5><u>Order Details</u></h5>
                            <br>
                            <div class="form-group">
                                <label class="control-label col-lg-2 col-md-2 col-sm-2 col-xs-2 cc_details">From:
                                </label>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details">
                                    <input type="text" formControlName="From" class="form-control" disabled>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-lg-2 col-md-2 col-sm-2 col-xs-2 cc_details">To:
                                </label>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details">
                                    <input type="text" formControlName="To" class="form-control" disabled>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-lg-2 col-md-2 col-sm-2 col-xs-2 cc_details">No Of Boxes:
                                </label>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details">
                                    <input type="text" formControlName="NoOfPackages" class="form-control" disabled>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-lg-2 col-md-2 col-sm-2 col-xs-2 cc_details">Charged
                                    Weight:
                                </label>
                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 cc_details">
                                    <input type="text" formControlName="ChargedWeight" class="form-control" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 bg-white" style="display: inline-block;">
                            <div class="col-xs-6">
                                <br>
                                <div class="form-group form-group-height margin-btn">
                                    <label class="">Payment Mode:</label>
                                    <mat-radio-group formControlName="ModeOfFreightId"
                                        *ngIf="BillingMethods =='Credit'">
                                        <mat-radio-button *ngFor="let item of freightModes" class="margin margins"
                                            style="margin-left: 10px;" [value]="item.LookupId"
                                            (click)="onFreightChange(item.LookupId)">
                                            {{ item.LookupName }}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <mat-radio-group formControlName="ModeOfFreightId"
                                        *ngIf="BillingMethods !='Credit'">
                                        <mat-radio-button *ngFor="let item of freightModes" class="margin margins"
                                            style="margin-left: 10px;" [value]="item.LookupId"
                                            [disabled]="BillingMethods != 'Credit' && (item.LookupName == 'Credit')"
                                            (click)="onFreightChange(item.LookupId)">
                                            {{ item.LookupName }}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <br>
                                <button *ngIf="isXPCNFinalized === false" class="btn-primary pull-right" mat-raised-button (click)="GetFreightDetails()"
                                    style="margin-bottom: 10px;">
                                    Get Freight
                                </button>
                            </div>
                            <div class="col-xs-12" *ngIf="CreditCode == true">
                                <div class="form-group form-group-height margin-btn">
                                    <label class="">Credit Code:</label>
                                    <input type="text" class="form-control" maxlength="25"
                                        placeholder="Enter Credit Code" formControlName="CreditCode">
                                    <div *ngIf="freight == 'Credit' && !addEditForm.controls.CreditCode.valid && (addEditForm.controls.CreditCode.dirty || addEditForm.controls.CreditCode.touched) || isFinalizeClicked"
                                        class="error-text">
                                        <div *ngIf="addEditForm.controls.CreditCode.errors?.required">
                                            Credit Code is required
                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>
                            <div class="col-sm-12" style="display: inline-block; margin-top: -10px;"
                                *ngIf="FreightDisplaytype == true">
                                <div class="table-responsive">
                                    <table id="example2" class="table table-bordered table-hover">
                                        <thead class="tbl-head">
                                            <tr class="tr-grid-header">
                                                <th>Freight as per Order ID</th>
                                                <th>Amount</th>
                                                <!-- (Base Freight: {{rate}}) -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Base Freight:</td>
                                                <td> <input type="number" class="form-control"
                                                        formControlName="BasicFreight" (keyup)="calculateTotal()"
                                                        placeholder="Enter Amount"></td>
                                            </tr>
                                            <tr>
                                                <td>CN Charge:</td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter CN Charge" formControlName="CNCharges">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>FOV Charge:</td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter FOV Charge" formControlName="FOVCharge">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Risk Charge:</td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter Risk Charge" formControlName="RiskCharges">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>SDA Charge:</td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter SDA Charge" formControlName="ODACharge">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>To Pay Service Charge:</td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter To Pay Service Charge"
                                                        formControlName="ToPayCharge">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Green Tax/City/State Charge:</td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter Green Tax/City/State Charge"
                                                        formControlName="GreenCharge">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Handling Charge:</td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter Handling Charge"
                                                        formControlName="HandlingCharges">
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td>COD/DOD Charge:</td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter COD/DOD Charge"
                                                        formControlName="CODDODCharge">
                                                </td>
                                            </tr> -->
                                            <!-- <tr>
                                                <td>Appointment Delivery Charge:</td>
                                                <td>
                                                    <input type="text" class="form-control"
                                                        (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter Appointment Delivery Charge"
                                                        formControlName="DelCharge">
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td>Value Added Charge:</td>
                                                <td>
                                                    <input type="text" class="form-control" disabled
                                                        placeholder="Enter VAS" formControlName="ValueAddedCharge">
                                                    <div *ngIf="!addEditForm.controls.ValueAddedCharge.valid && (addEditForm.controls.ValueAddedCharge.dirty || addEditForm.controls.ValueAddedCharge.touched)"
                                                        class="error-text">
                                                        <div
                                                            *ngIf="addEditForm.controls.ValueAddedCharge.errors?.required">
                                                            VAS is required
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Other Charges:</td>
                                                <td> <input type="number" class="form-control"
                                                        formControlName="OtherCharges" (keyup)="calculateTotal()"
                                                        placeholder="Enter Amount"></td>
                                            </tr>
                                            <tr>
                                                <td> <b>Sub Total:</b></td>
                                                <td> <b>{{sub_total}}</b></td>
                                            </tr>
                                            <tr>
                                                <td>Fuel Surcharge:</td>
                                                <td>
                                                    <input type="text" class="form-control" (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter Fuel Surcharge" formControlName="FuelCharge">
                                                </td>
                                            </tr>
                                            <tr *ngIf="fuelsurchargeApplicableOn != null && fuelsurchargeApplicableOn != ''">
                                                <td>Fuel Surcharge Applicable on:</td>
                                                <td>
                                                    <span>
                                                        {{fuelsurchargeApplicableOn}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Total Charges</b></td>
                                                <td>{{totalWithourGST.toFixed(2)}}</td>
                                            </tr>
                                            <tr>
                                                <td>GST ({{gst}}%)</td>
                                                <td>
                                                    <input type="text" class="form-control" disabled
                                                        (keypress)="priceValidator($event)" (keyup)="calculateTotal()"
                                                        placeholder="Enter GST" formControlName="GSTLCL">
                                                    <div *ngIf="!addEditForm.controls.GST.valid && (addEditForm.controls.GST.dirty || addEditForm.controls.GST.touched)"
                                                        class="error-text">
                                                        <div *ngIf="addEditForm.controls.GST.errors?.required">
                                                            GST is required
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Total Freight</b></td>
                                                <td>{{total.toFixed(2)}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12" style="margin-top: 20px;">
                            <div class="col-sm-3"></div>

                            <!-- <div class="pull-right">
                                <label class="control-label col-sm-5 add-member-label"
                                    style="display: inline-block;">Qty Sticker Used: </label>
                                <input [attr.disabled]="isXPCNFinalized? 'disabled': null"
                                    style="width: 250px; display: inline-block;" type="text"
                                    class="form-control col-sm-6 mr-2"
                                    (focusout)="ValidateEnterBoxes(this.FROM_INPUT)"
                                    placeholder="Enter used sticker quantity" formControlName="UsedStickerNo" />
                                <div class="error-text" *ngIf="InputRequred">
                                    <p>Quantity of used sticker is required</p>
                                </div>

                            </div> -->
                            <!-- <div class="col-xs-12">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="table-responsive">
                                            <table id="example2" class="table table-bordered2 table-hover">
                                                <thead class="tbl-head">
                                                    <tr class="tr-grid-header">
                                                        <th colspan="7" class="text-center">
                                                            Boxes Details
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td style="width: 200px;" class="text-center">S.No
                                                        </td>
                                                        <td style=" text-align: center !important;">Length</td>
                                                        <td style=" text-align: center !important;">Breadth </td>
                                                        <td style=" text-align: center !important;">Height </td>
                                                        <td style=" text-align: center !important;">Actual Weight
                                                            (KG)</td>
                                                        <td style=" text-align: center !important;"> HU Number</td>
                                                        <td style=" text-align: center !important;"
                                                            *ngIf='boxDetails.length > 0'>Action</td>
                                                    </tr>
                                                    <tr *ngIf='boxDetails.length == 0'>
                                                        <td colspan="7" class="text-center">No Boxes Found</td>
                                                    </tr>
                                                    <tr *ngFor='let item of boxDetails;let i=index'>
                                                        <td class="text-center"><mat-checkbox
                                                                class="marginClasImportant"
                                                                [disabled]="isBoxesCheckBox == true && item.isSelected == false? 'disabled':null"
                                                                [(ngModel)]="item.isSelected"
                                                                (change)="checkboxChanged($event,item)"
                                                                [ngModelOptions]="{standalone: true}"
                                                                name="list_name"
                                                                value="{{item.BoxId}}"></mat-checkbox>
                                                            <b
                                                                style="margin-left: 10px;">{{i+1}}/{{boxDetails.length}}</b>
                                                        </td>
                                                        <td class="text-center">
                                                            <input [(ngModel)]="item.Length"
                                                                [ngModelOptions]="{standalone: true}"
                                                                style="width:200px;text-align: center;">

                                                        </td>
                                                        <td class="text-center">
                                                            <input [(ngModel)]="item.Breadth"
                                                                [ngModelOptions]="{standalone: true}"
                                                                style="width:200px;text-align: center;">
                                                        </td>
                                                        <td class="text-center">
                                                            <input [(ngModel)]="item.Height"
                                                                [ngModelOptions]="{standalone: true}"
                                                                style="width:200px;text-align: center;">
                                                        </td>
                                                        <td class="text-center">
                                                            <input [(ngModel)]="item.Weight"
                                                                [ngModelOptions]="{standalone: true}"
                                                                style="width:200px;text-align: center;">
                                                        </td>

                                                        <td style=" text-align: center !important;">
                                                            <p *ngIf="item.HUNumber == null">Not Generated</p>
                                                            <p *ngIf="item.HUNumber != null" class="inner-screen"
                                                                (click)="onNavigate(item)">
                                                                {{item.HUNumber}}</p>
                                                        </td>
                                                        <td style=" text-align: center !important;">
                                                            <a *ngIf="item.HUNumber == null" mwlConfirmationPopover
                                                                [popoverTitle]="popoverTitle"
                                                                [popoverMessage]="popoverMessage" placement="top"
                                                                (confirm)="deleteBox(i)"
                                                                (cancel)="cancelClicked = true"
                                                                [appendToBody]="true">
                                                                <i class="fa fa-trash"
                                                                    style="color: red;cursor: pointer;"
                                                                    aria-hidden="true"></i>
                                                            </a>
                                                            <a *ngIf="item.HUNumber != null">
                                                                <i class="fa fa-trash"
                                                                    style="color: grey;cursor: pointer;"
                                                                    aria-hidden="true"></i>
                                                            </a>
                                                        </td>
                                                    </tr>



                                                </tbody>
                                            </table>

                                            <button class="btn btn-success pull-right mb-3"
                                                [disabled]="isSaveBoxesClicked" (click)="saveBoxes()"
                                                *ngIf="!isXPCNFinalized">Save Boxes</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-sm-12" style="padding:0px ;">
                                <button *ngIf="!isXPCNFinalized" class="btn-success pull-right" mat-raised-button
                                    style="margin-bottom: 10px;" (click)="finalizeXPCN(FINALIZE);">
                                    Finalise
                                </button>



                                <button class="btn-primary pull-right" mat-raised-button
                                    style="margin-bottom: 10px;margin-right: 10px;" (click)="openLCLXPCNPreview()">
                                    Preview
                                </button>


                                <button *ngIf="!isXPCNFinalized" class="btn-primary pull-right" mat-raised-button
                                    style="margin-bottom: 10px;margin-right: 10px;" (click)="onSaveXPCN()">Save
                                </button>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>