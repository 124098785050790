<div class="MarginClass">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
    <h3 mat-dialog-title>XPLS Preview </h3>
</div>
<div mat-dialog-content style="margin: 0px !important; padding: 0px !important">
    <div class="col-sm-12" id="pdf" style="padding-top: 15px;">
        <table style="width: 100%; border: 2px solid black; margin-bottom: 10px;">
            <tr>
                <td rowspan="2"
                    style="text-align: center !important; vertical-align: middle !important; border-right: 2px solid black;">
                    <img style="height:115px;margin-top:-12px;margin-left:5px; position: relative; top: 10px;"
                        src="../../../../../../../assets/img/vendor/leaflet/logo_xpp.jpg">
                </td>
                <td colspan="2" style="text-align: center !important;">
                    <h4><b>Vehicle Loading Slip (XPLS)</b></h4>
                </td>
                <td colspan="1">
                    <p><b style="font-size: 17px;">XPLS: {{xpls.XPLSCode}} <br> XPLS Date: {{xpls.XPLSDate != null ?
                            (xpls.XPLSDate | date : 'dd-MM-y') : ''}}</b></p>
                </td>
            </tr>
            <tr>
                <td>
                    <b>Booking ID:</b>
                    <p>{{xpls.BookingId}}</p>
                </td>
                <td>
                    <b>Order ID:</b>
                    <p>{{xpls.OrderIdCode}}</p>
                </td>
                <td>
                    <b>Order Date:</b>
                    <p>{{xpls.strOrderDate}}</p>
                </td>
            </tr>
            <tr>
                <td style="width: 25% !important; font-size: 12px; border-right: 2px solid black; border-bottom: 2px solid black;"
                    rowspan="4">
                    <p>Instant Supply Chain Solutions Private Limited, 14th Floor, Ocus Medley, Dwarka Expressway, G99,
                        Sector-99,
                        Gurugram, Haryana 122001.</p><br>
                    <p>www.xpindia.in</p><br>
                    <p>CIN: U63030HR2019PTC083859</p><br>
                    <p>GSTIN: 06AAFCI4823D1ZW</p>
                </td>
                <td style="width: 25% !important;">
                    <b>Freight Mode:</b>
                    <p>{{freightMode}}</p>
                </td>
                <td style="width: 25% !important;">
                    <b>Booking Branch:</b>
                    <p>{{xpls.BranchName}}</p>
                </td>
                <td style="width: 25% !important;">
                    <b>Delivery Branch:</b>
                    <p>{{xpls.Destination_branch}}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <b>Service Class:</b>
                    <p>{{xpls.ServiceClass}}</p>
                </td>
                <td>
                    <b>Type of Service:</b>
                    <p>{{xpls.ServiceType}}</p>
                </td>
                <td>
                    <b>Despatch Date:</b>
                    <p>{{xpls.strDepartedOriginDate}}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <b>Vehicle Type:</b>
                    <p>{{xpls.VehicleType}}</p>
                </td>
                <td>
                    <b>Payload:</b>
                    <p>{{xpls.ServiceType == 'LCL Aggregation' ? '-' : xpls.Payload}}</p>
                </td>
                <td>
                    <b>Vehicle No:</b>
                    <p>{{xpls.VehicleNo}}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <b>Origin:</b>
                    <p>{{xpls.From}}, {{xpls.OriginPostcode}}</p>
                </td>
                <td>
                    <b>Destination:</b>
                    <p>{{xpls.To}}, {{xpls.DestinationPostcode}}</p>
                </td>
                <td>
                    <b>Via Points:</b>
                    <p>{{viaPoints}}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <b>LSP Name:</b>
                    <p>{{lspName}}</p>
                </td>
                <td>
                    <b>LSP GSTIN:</b>
                    <p>{{lspGSTIN}}</p>
                </td>
                <td>
                    <b>Transit Risk:</b>
                    <p>{{riskType}}</p>
                </td>
                <td>
                    <b>Quantity / Weight / OTL:</b>
                    <p>{{xpls.NoOfPackages}} / {{xpls.Weight}} / {{xpls.OTL}}</p>
                </td>
            </tr>
            <tr *ngIf="xpls.ServiceType == 'LCL Aggregation'">
                <td>
                    <b>PO Number:</b>
                    <p>{{xpls.PONumber}}</p>
                </td>
                <td>
                    <b>Vendor Id:</b>
                    <p>{{xpls.VendorId}}</p>
                </td>
                <td>
                    <b>Vendor Name:</b>
                    <p>{{xpls.VendorName}}</p>
                </td>
                <td>
                    <b>Vendor Location:</b>
                    <p>{{xpls.VendorLocation}}</p>
                </td>
            </tr>
            <tr>
                <td colspan="4" style="border: 1px solid black;">
                    <b>Special Remarks :</b> {{xpls.SpecialRemarks}}
                </td>
            </tr>
            <tr>
                <td colspan="4" style="padding: 0px !important;">
                    <table class="innerTbl" style="width: 100%; font-size: 10px;">
                        <tr>
                            <td style="padding: 3px !important;">
                                <b>LSP Waybill No</b>
                            </td>
                            <td style="padding: 3px !important; min-width: 65px !important">
                                <b>Date</b>
                            </td>
                            <!-- <td style="padding: 3px !important;">
                  <b>From</b>
                </td>
                <td style="padding: 3px !important;">
                  <b>To</b>
                </td> -->
                            <td style="padding: 3px !important;">
                                <b>Consignor Name</b>
                            </td>
                            <td style="padding: 3px !important;">
                                <b>Consignor Address</b>
                            </td>
                            <td style="padding: 3px !important;">
                                <b>Consignee Name</b>
                            </td>
                            <td style="padding: 3px !important;">
                                <b>Consignee Address</b>
                            </td>
                            <!-- <td style="padding: 3px !important;">
                  <b>Quantity</b>
                </td>
                <td style="padding: 3px !important;">
                  <b>Weight</b>
                </td> -->
                            <td style="padding: 3px !important;">
                                <b>Value</b>
                            </td>
                            <td style="padding: 3px !important;">
                                <b>EWaybill No.</b>
                            </td>
                        </tr>
                        <tr *ngFor="let item of invoices">
                            <td style="padding: 3px !important;">{{item.LSPNo}}</td>
                            <td style="padding: 3px !important;">{{item.Date != null ? (item.Date | date : 'dd-MM-y') :
                                ''}}</td>
                            <!-- <td style="padding: 3px !important;">{{xpls.From}}</td>
                <td style="padding: 3px !important;">{{xpls.To}}</td> -->
                            <td style="padding: 3px !important;">{{item.ConsignorName}}</td>
                            <td style="padding: 3px !important;">{{item.ConsignorAddress}}</td>
                            <td style="padding: 3px !important;">{{item.ConsigneeName}}</td>
                            <td style="padding: 3px !important;">{{item.ConsigneeAddress}}</td>
                            <!-- <td style="padding: 3px !important;">{{xpls.NoOfPackages}}</td>
                <td style="padding: 3px !important;">{{xpls.Weight}}</td> -->
                            <td style="padding: 3px !important;">{{item.InvoiceValue}}</td>
                            <td style="padding: 3px !important;">{{item.ewb}}</td>
                        </tr>
                        <tr *ngIf="invoices.length == 0">
                            <td style="padding: 3px !important;"></td>
                            <td style="padding: 3px !important;"></td>
                            <!-- <td style="padding: 3px !important;">{{xpls.From}}</td>
                <td style="padding: 3px !important;">{{xpls.To}}</td> -->
                            <td style="padding: 3px !important;"></td>
                            <td style="padding: 3px !important;"></td>
                            <td style="padding: 3px !important;"></td>
                            <td style="padding: 3px !important;"></td>
                            <!-- <td style="padding: 3px !important;">{{xpls.NoOfPackages}}</td>
                <td style="padding: 3px !important;">{{xpls.Weight}}</td> -->
                            <td style="padding: 3px !important;"></td>
                            <td style="padding: 3px !important;"></td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr *ngIf="showFreight == true">
                <td colspan="4">
                    <b style="text-decoration: underline;">FREIGHT DETAILS:</b>
                </td>
            </tr>
            <tr *ngIf="showFreight == true">
                <td>
                    <b>Basic Freight:</b>
                    <p>{{xpls.Rate}}</p>
                </td>
                <td>
                    <b>Handling Charges:</b>
                    <p>{{xpls.HandlingCharges}}</p>
                </td>
                <td>
                    <b>Halting Charges:</b>
                    <p>{{xpls.HaltingCharges}}</p>
                </td>
                <td>
                    <b>Other Charges:</b>
                    <p>{{xpls.OtherCharges}}</p>
                </td>
            </tr>
            <tr *ngIf="showFreight == true">
                <td>
                    <b>CGST:</b>
                    <p>{{cgst}}</p>
                </td>
                <td>
                    <b>SGST:</b>
                    <p>{{sgst}}</p>
                </td>
                <td>
                    <b>IGST:</b>
                    <p>{{igst}}</p>
                </td>
                <td>
                    <b>GRAND TOTAL:</b>
                    <p>{{total}}</p>
                </td>
            </tr>
            <tr>
                <td colspan="4" style="border-top: 1px solid black;">
                    <p>This is a Non-Negotiable Goods Consignment Note. It is agreed that the goods described here in
                        are accepted
                        in “Said to contain” basis as per the Invoices/STNs and Ewaybills. The onus of proper
                        documentation with
                        respect to GST, GSTIN & Ewaybill, Excise Duty, Customs and any statutory laws lies with the
                        parties i e.
                        Consignor and consignee. ISCPL shall not be responsible/ liable in any case of deficiency in the
                        documents/statutory requirements.<br>
                        Carrying terms and liabilities are as per the contractual agreement.</p>
                </td>
            </tr>
        </table>
    </div>
</div>
<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
    <button class="btn btn-primary fa fa-download" (click)=" downloadPDF()"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Download</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
</mat-dialog-actions>