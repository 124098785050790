<div class="sidebar-outer">
    <!-- <a class="logo d-flex align-items-center" routerLink="/" (click)="closeSubMenus()" *ngIf="settings.theme.sidebarIsFixed">
        <span class="start">start</span><span class="ng">NG</span>
    </a> -->
    
    <a class="logo text-center" routerLink="/xp/generate-pickup-request" style="height: 85px;">
        <img src="../../assets/img/vendor/leaflet/XP_logo_trans.png" class="logo" alt="XP"
          style="height: 85px !important; width: 135px;">
      </a>
    
    <!-- <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <a class="logo" routerLink="/xp/analytics" (click)="closeSubMenus()">
              <img src="../../assets/img/vendor/leaflet/XP_logo_trans.png" class="logo" alt="XP"
                style="width: 60px !important; margin-left: 4px;">
            </a>
          </div>
          <div class="flip-card-back">
            <a class="logo" routerLink="/xp/analytics" (click)="closeSubMenus()">
              <img src="../../assets/img/vendor/leaflet/truck.png" class="logo" alt="XP"
                style=" width: 80px !important;">
            </a>
          </div>
        </div>
      </div> -->
    <app-vertical-menu [menuItems]="menuItems"></app-vertical-menu>    
</div>