<div class="col-sm-12" *ngIf="access == true">
    <div class="row">
        <div class="col-sm-12 mb-4 ml-0 p-0">
            <div class=" row ml-0 p-0">


                <div class="col-sm-2 ">
                    <a routerLinkActive="active" type="button" class="btn btn mb-1 xp fa fa-map xp"
                        style="background-color: #0077bd;color: white; height: 38px;"
                        [routerLink]="['./placement-tracking']">
                        <span style="font-family: Arial, Helvetica, sans-serif;"> Placement Tracking
                        </span><span *ngIf="count">({{count[0]?.value}})</span></a>
                </div>
                <div class="col-sm-2">
                    <a routerLinkActive="active" type="button" class="btn  btn mb-1 xp fa fa-hourglass-2 xp"
                        style="background-color: #0077bd;color: white; height: 38px; width: 100%;"
                        [routerLink]="['./waiting-for-loading']">
                        <span style="font-family: Arial, Helvetica, sans-serif;"> Waiting For Loading </span>
                        <span *ngIf="count">({{count[4]?.value}})</span></a>
                </div>
                <div class="col-sm-2">
                    <a routerLinkActive="active" type="button" class="btn  btn mb-1 xp fa fa-truck xp"
                        style="background-color: #0077bd;color: white;height: 38px;" [routerLink]="['./in-transit']">
                        <span style="font-family: Arial, Helvetica, sans-serif;"> In-Transit </span>
                        <span *ngIf="count">({{count[1]?.value}})</span></a>
                </div>
                <div class="col-sm-2">
                    <a routerLinkActive="active" type="button" class="btn  btn mb-1 xp fa fa-chain-broken xp"
                        style="background-color: #0077bd;color: white;height: 38px;" [routerLink]="['./halted']">
                        <span style="font-family: Arial, Helvetica, sans-serif;"> Halted </span>
                        <span *ngIf="count">({{count[5]?.value}})</span></a>
                </div>
                <div class="col-sm-2">
                    <a routerLinkActive="active" type="button" class="btn  btn mb-1 xp fa fa-check-circle xp"
                        style="background-color: #0077bd;color: white;height: 38px;" [routerLink]="['./arrived']">
                        <span style="font-family: Arial, Helvetica, sans-serif;">  Wating For Unloading </span>
                        <span style="margin-left: 2px;" *ngIf="count"> ({{count[2]?.value}})</span></a>
                </div>
                <div class="col-sm-2">

                    <a routerLinkActive="active" type="button" class="btn  btn mb-1 xp fa fa-bar-chart xp"
                        style="background-color: #0077bd;color: white;height: 38px;" [routerLink]="['./performance']">
                        <span style="font-family: Arial, Helvetica, sans-serif;"> Performance </span>
                        <span *ngIf="count">({{count[3]?.value}}%)</span></a>
                </div>


            </div>
        </div>
    </div>

    <div class="row ">
        <div class="col-sm-12">
            <router-outlet></router-outlet>
        </div>

    </div>
</div>


<div *ngIf="access == false">
    <p class="text-center dj">You don't have permission to access this screen, please contact administrator.</p>
</div>

