import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { environment } from 'src/environments/environment';
import { PagesService } from '../pages.service';
import { MapDialogComponent } from '../shared/map-dialog/map-dialog.component';
import { XpcnPreviewDialogComponent } from '../shared/xpcn-preview-dialog/xpcn-preview-dialog.component';

@Component({
  selector: 'app-track-xpcn',
  templateUrl: './track-xpcn.component.html',
  styleUrls: ['./track-xpcn.component.scss']
})
export class TrackXpcnComponent implements OnInit {

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  filterForm: FormGroup;
  endIndex: number;
  startIndex = 1;
  OrderList = [];
  filterBy = '';
  keyword = '';
  keywordDate = '';
  dateFilter = '';
  user;
  readonly DISTRIBUTION_CENTER = "Distribution Center"

  public popoverTitle: string = 'Confirm Ready For Pickup';
  public popoverMessage: string = 'Are you sure you?';

  filterToggle: boolean = true;
  CustomDate: boolean = false;
  excelData: any[];


  constructor(private fb: FormBuilder,
    private _pageService: PagesService,
    private _datePipe: DatePipe,
    public alertService: AlertService,
    public dialog: MatDialog,
    public route: Router,
    public _downloadExcelService: DownloadExcelService
  ) {
    this.user = JSON.parse(localStorage.getItem('userSession'));
    this.filterForm = this.fb.group({
      OrderBy: new FormControl(""),
      StartIndex: new FormControl(""),
      EndIndex: new FormControl(""),
      filterBy: new FormControl(""),
      Keyword: new FormControl(""),
      FromDate: new FormControl(""),
      ToDate: new FormControl(""),
      KeywordDate: new FormControl(""),
      datefilterType: new FormControl("")
    });
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
  }

  ngOnInit(): void {
    this.filterForm.controls["datefilterType"].setValue('YTD');
    this.getData();
  }

  getData() {
    this._pageService.CPGetXPCNForTrack(this.filterForm.value).subscribe((res) => {
      this.OrderList = res["Data"];
      if (this.OrderList != null && this.OrderList.length > 0) {
        this.totalSize = this.OrderList[0].TotalRecords;
      }
    })
  }


  openLCLXPCNPreview(XPCNID) {
    this._pageService.getLCLXPCNDetailsForFinzalization(XPCNID).subscribe(data => {
      if (data != null && data['Data'] != null) {

        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;

        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;

        // window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); 
        // this._router.navigate([]).then(result => {  window.open('/xp/operation/operationOne/booking-menu/finalise-xpcn/generate-xpcn', '_blank'); });
        let dialogRef = this.dialog.open(XpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }

  filter() {
    if (this.filterBy != '' && this.filterBy != null) {
      this.filterForm.controls['filterBy'].setValue(this.filterBy);
      if (this.filterBy != 'asndate' && this.filterBy != 'update' && this.filterBy != 'xpcndate') {
        if (this.keyword.trim() != '' && this.keyword != null) {
          this.filterForm.controls['Keyword'].setValue(this.keyword.trim());
        } else {
          this.alertService.createAlert("Please enter keyword", 0);
          return;
        }
        this.keywordDate = null;
      } else {
        this.keyword = null;
        if (this.keywordDate != '' && this.keywordDate != null) {
          this.filterForm.controls['KeywordDate'].setValue(this._datePipe.transform(this.keywordDate, 'yyyy-MM-dd'));
        } else {
          this.alertService.createAlert("Please select date", 0);
          return;
        }
      }
      this.getData();
    } else {
      this.alertService.createAlert("Please select filter by", 0);
      return;
    }
  }

  clear() {
    this.startIndex = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.totalSize = 0;
    this.filterBy = '';
    this.keyword = null;
    this.keywordDate = null;
    this.filterForm.controls['filterBy'].setValue(null);
    this.filterForm.controls['Keyword'].setValue(null);
    this.filterForm.controls["StartIndex"].setValue(this.startIndex);
    this.filterForm.controls["EndIndex"].setValue(this.pageSize);
    this.getData();
  }

  public handlePage(e: any, isReset = false) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    if (this.currentPage == 0) {
      this.startIndex = 1;
    } else {
      this.startIndex = (this.currentPage * this.pageSize) + 1;
    }
    this.filterForm.controls['StartIndex'].setValue(this.startIndex);
    this.filterForm.controls['EndIndex'].setValue(this.pageSize);
    this.getData();
  }

  printStickers(id, code, width) {
    window.open(environment.apiUrl + 'InvoiceView/DownloadConsignorStickers?id=' + id + "&code=" + code + "&width=" + width, "_blank");
  }

  fromDate
  toDate

  dateFilterChanged(type) {

    this.filterForm.controls["datefilterType"].setValue(null);

    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.dateFilter = type;
    this.filterForm.controls["datefilterType"].setValue(this.dateFilter);

    if (type == 'Dates') {
      if (new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')) > new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd'))) {
        this.alertService.createAlert('From date cannot be grater than to date', 0);
      } else {
        this.filterForm.controls["FromDate"].setValue(new Date(this._datePipe.transform(this.fromDate, 'yyyy-MM-dd')));
        this.filterForm.controls["ToDate"].setValue(new Date(this._datePipe.transform(this.toDate, 'yyyy-MM-dd')));
      }
    } else {
      this.filterForm.controls["FromDate"].setValue(null);
      this.filterForm.controls["ToDate"].setValue(null);

      this.fromDate = null;
      this.toDate = null;
    }

    this.getData();

  }

  datefilterclear() {
    this.fromDate = null;
    this.toDate = null;
    this.filterForm.controls["datefilterType"].setValue('MTD');
    this.filterForm.controls["FromDate"].setValue(null);
    this.filterForm.controls["ToDate"].setValue(null);

    this.getData();
  }

  public exportAsExcel() {
    this.excelData = [];
    let list = [];

    this.filterForm.controls['EndIndex'].setValue(this.totalSize);
    this._pageService.CPGetXPCNForTrack(this.filterForm.value).subscribe(data => {
      if (data != null) {
        list = data['Data'];
        if (list.length > 0) {
          list.forEach(element => {
            this.excelData.push({
              'Booking Date': this._datePipe.transform(element['booking_date'], 'dd-MM-y'),
              'Customer Doc/LR/PO': element['loding_doc'],
              'Booking Id': element['vc_bookingid'],
              'Customer Name': element['Customer'],
              'Consignor Name': element['consignor'],
              'Customer Pick-up Location': element['PickUpAddress'],
              'XPCN No': element['XPCNNo'],
              'XPCN Generation Date': this._datePipe.transform(element['XPCNDate'], 'dd-MM-y'),
              'No Of Packages': element['int_Boxes'],
              'Consignee Name': element['consignee'],
              'Consignee Location': element['consignee_location'],
              'Status': element['Status'],
              'Picked Date ': this._datePipe.transform(element['PickedDate'], 'dd-MM-y'),
              'EDD': this._datePipe.transform(element['Promised_edd'], 'dd-MM-y'),
              'Current Location': element['VehicleLocation'],
            })
          });
        }
        else {
          this.excelData.push({
            'Booking Date': '-',
            'Customer Doc/LR/PO': '',
            'Booking Id': '-',
            'Customer Name': '-',
            'Consignor Name': '-',
            'Customer Pick-up Location': '-',
            'XPCN No': '-',
            'XPCN Generation Date': '-',
            'No Of Packages': '-',
            'Consignee Name': '-',
            'Consignee Location': '-',
            'Status': '-',
            'Picked Date ': '-',
            'EDD': '-',
            'Current Location': '-',
          })
        }
        this._downloadExcelService.exportAsExcelFile(this.excelData, 'Print & Track Your XPCN');
      }
    })
  }

  openMap(item, orderid, vehiclenumber, type) {
    let newitem = {
      Latitude: null,
      Longitude: null
    };
    if(type == 'vehicle') {
      newitem.Latitude = item.VehicleLatitude;
      newitem.Longitude = item.VehicleLongitude;
    } else {
      newitem.Latitude = item.Latitude;
      newitem.Longitude = item.Longitude;
    }
    let dialogRef = this.dialog.open(MapDialogComponent, {
      data: { data: newitem, isService: true, orderid: orderid, vehiclenumber: null },
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
  }

  onclickxpcn(xpcnid: any): void
   {
    const encryptedXpcnId = btoa(xpcnid.toString());
    this.route.navigate(['/xp/prepare-xpcn'], {
      queryParams: { x: encryptedXpcnId },
    });
  }
}