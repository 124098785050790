<div class="">
    <div class="row">
        <div class="col-sm-12">
            <button class="btn pull-right m-2" style="background-color:  #6e7dd2; color:#fff" (click)="exportAsExcel()">
                Download CSV
            </button>
        </div>
    </div>
    <div class="card card-body ">
        <div class="row mt-2">
            <div class="col">
                <div widget class="card border-0 box-shadow">
                    <div class="card-body widget-body">
                        <div class="row form-inline">
                            <div class="form-group col-sm-4">
                                <div class="col-sm-4  form-group no-padding" style="color: black;">
                                    From Booking Date:
                                </div>
                                <div class="col-sm-8 no-padding">
                                    <div class="input-icon right">
                                        <i class="fa fa-calendar" [owlDateTimeTrigger]="dt1"></i>
                                        <input type="text" [(ngModel)]="fromDate" class="form-control" [max]=today
                                            style="min-width: 150px; margin-left: 10px;" placeholder="Select From Date"
                                            (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1"
                                            name="dt1" />
                                        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group col-sm-4">
                                <div class="col-sm-4  form-group no-padding" style="color: black;">
                                    To Booking Date:
                                </div>
                                <div class="col-sm-8 no-padding">
                                    <div class="input-icon right">
                                        <i class="fa fa-calendar" [owlDateTimeTrigger]="dt2"></i>
                                        <input type="text" [(ngModel)]="toDate" class="form-control" [max]=today
                                            style="min-width: 150px; margin-left: 10px;" placeholder="Select To Date"
                                            (keydown)="inputNotAllowed()" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2"
                                            name="dt2" />
                                        <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group col-sm-4">
                                <div class="col-sm-4  form-group no-padding" style="color: black;">
                                    Order Status:
                                </div>
                                <div class="col-sm-8 no-padding">
                                    <!-- <mat-select style="width: 100%; border: 1px solid black;" multiple #select1
                                        placeholder="Select Pickup Status" [(ngModel)]="filterStatus"
                                        [disabled]="disableSelect" class="form-control">
                                        <mat-option *ngFor="let o of options" [disabled]="o.isDisabled"
                                            value="{{o.value}}">{{o.value}}</mat-option>
                                    </mat-select> -->

                                    <!-- <mat-option value="All" [(ngModel)]="allSelected1"
                                            [ngModelOptions]="{standalone: true}"
                                            (click)="toggleAllSelection1()">All</mat-option> -->
                                    <!-- <mat-option value="9">Material Not Ready</mat-option>
                                        <mat-option value="8">XPCN Finalized Pickup Pending</mat-option>
                                        <mat-option value="7">Pickup Intransit</mat-option>
                                        <mat-option value="6">Cooling at Origin Gateway</mat-option>
                                        <mat-option value="5">Cooling at Gateway - Intransit</mat-option>
                                        <mat-option value="4">Cooling at Gateway - Delivery</mat-option>
                                        <mat-option value="3">Out for Delivery </mat-option>
                                        <mat-option value="2">Intransit - Linehaul</mat-option>
                                        <mat-option value="1">Delivered</mat-option></mat-select> -->

                                    <!-- <mat-select placeholder="Order Status" [(ngModel)]="filterStatus" multiple>
                                        <mat-option #allSelected (click)="toggleAllSelection()">Select All</mat-option>
                                        <mat-option *ngFor="let filters of options" [value]="filters.value"
                                            (click)="tosslePerOne(allSelected.viewValue)">
                                            {{filters.value}}
                                        </mat-option>
                                    </mat-select> -->
                                    <ss-multiselect-dropdown [options]="secondControlOptions" [texts]="secondControlTexts" [settings]="secondControlSettings" [(ngModel)]="filterStatus"></ss-multiselect-dropdown>

                                    <!-- <mat-form-field floatLabel="never" class="dashboard-select">
                                        <mat-placeholder class="dashboard-select-placeholder">Please select an
                                            option</mat-placeholder>
                                        <mat-select [(value)]="selectedValues"
                                            (selectionChange)="selectionChange($event)" formControlName="dashboardValue"
                                            multiple>
                                            <mat-option [value]="displayDashboardValues[0]"
                                                (click)="toggleAllSelection()">{{ displayDashboardValues[0].viewValue
                                                }}</mat-option>
                                            <mat-divider></mat-divider>
                                            <div *ngFor="let dashboardPosition of displayDashboardValues">
                                                <mat-option class="dashboard-select-option"
                                                    *ngIf="dashboardPosition.key>0" [value]="dashboardPosition">
                                                    {{ dashboardPosition.viewValue }}
                                                </mat-option>
                                            </div>
                                        </mat-select>
                                    </mat-form-field> -->

                                </div>
                                <!-- <div class="col-sm-2 m-0 p-0">
                                    <span class="example-list-section">
                                        <mat-checkbox class="example-margin" (change)="checkSelectAll($event)">
                                            Select All
                                        </mat-checkbox>
                                    </span>
                                </div> -->

                            </div>
                        </div>
                        <div class="row form-inline">
                            <div class="form-group col-sm-10">
                            </div>
                            <div class="form-group col-sm-2" style="margin-top: 10px; float: right !important;">
                                <button class="btn fa fa-search"
                                    style="background-color: #3E50B4; color:#fff; float: right !important;"
                                    (click)="filter()">
                                    <span> Search</span>
                                </button>
                                <button class="btn fa fa-eraser"
                                    style="margin-left: 10px ; background-color:#778992; color: #fff; float: right !important;"
                                    (click)="clear()">
                                    <span> Clear</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 mt-2 p-0 ml-0 mr-0 ">
        <div class="table table-responsive">
            <table id="example3 " class="table table-bordered text-center  p-0 m-0" matSort>
                <thead class="tbl-head m-0 p-0">
                    <tr class="tr-grid-header m-0 p-0" style="BACKGROUND: #6e7dd2;">
                        <th style="color: white;min-width: 150px" scope="col">Sr. No</th>
                        <th style="color: white;min-width: 150px" scope="col">Booking Date</th>
                        <th style="color: white;min-width: 150px" scope="col">Order Type</th>
                        <th style="color: white;min-width: 150px" scope="col">Booking ID </th>
                        <th style="color: white;min-width: 150px" scope="col">Order ID</th>
                        <th style="color: white;min-width: 150px" scope="col">XPCN</th>
                        <th style="color: white;min-width: 150px" scope="col">Customer Doc/LR/PO</th>
                        <th style="color: white;min-width: 150px" scope="col">Origin</th>
                        <th style="color: white;min-width: 150px" scope="col">Destination</th>
                        <th style="color: white;min-width: 150px" scope="col">Picked Date</th>
                        <th style="color: white;min-width: 150px" scope="col">No of boxes</th>
                        <th style="color: white;min-width: 150px" scope="col">EDD</th>
                        <th style="color: white;min-width: 150px" scope="col">Delivery Date</th>
                        <th style="color: white;min-width: 150px" scope="col">Order Status</th>
                        <th style="color: white;min-width: 150px" scope="col">Charged Weight</th>
                        <th style="color: white;min-width: 150px" scope="col">POD Status</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of OrderList; let i=index">
                        <td>{{i+1}}</td>
                        <td>{{item.BookingDate | date: "dd-MM-y"}}</td>
                        <td>{{item.service_type}}</td>
                        <td>{{item.BookingIdCode}}</td>
                        <td>{{item.vc_OrderId}}</td>
                        <td>{{item.XPCNCode}}</td>
                        <td>{{item.vc_lr}}</td>
                        <td>{{item.Origin}}</td>
                        <td>{{item.Destination}}</td>
                        <td>{{item.PickedDate | date: "dd-MM-y"}}</td>
                        <td>{{item.NoOfBoxes}}</td>
                        <td>{{item.EDD | date: "dd-MM-y"}}</td>
                        <td>{{item.DeliveryDate | date: "dd-MM-y"}}</td>
                        <td>{{item.PickupStatus}}</td>
                        <td>{{item.ChargedWeight}}</td>
                        <td>{{item.PodDocUrl}}</td>
                    </tr>
                    <tr *ngIf="OrderList.length == 0">
                        <td class="text-center" colspan="100">No Records Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<mat-paginator [length]="totalSize" [pageIndex]="currentPage" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="handlePage($event)" showFirstLastButtons>
</mat-paginator>