<div class="wrapper">
    <div class="col-md-12 bg-white mail-view-container" style="width:100%;margin:auto">


        <div class="input-group headers">
            <span class="input-group-prepend">
                <span class="input-group-text bg-transparent border-0"><img
                        src="../../../assets/img/profile/xpindia.jpg" width="58px" style="border-radius:50%"></span>
            </span>
            <span class="form-control border-0 textHeaders"> XP India</span>

        </div>

        <div class="mesgs">

            <div class="msg_history" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                <div class="bg-image bgimage">
                    <div class="incoming_msg" *ngFor="let chat of chats">

                        <div
                            [ngClass]="{'received_msg': chat.UserType === 'User', 'outgoing_msg': chat.UserType !== 'User'}">
                            <div
                                [ngClass]="{'received_withd_msg': chat.UserType === 'User', 'sent_msg': chat.UserType !== 'User'}">
                                <div
                                    [ngClass]="{'received_body': chat.UserType === 'User', 'sent_body': chat.UserType !== 'User'}">
                                    <b>{{chat.UserName}}</b>: {{chat.MessageBody}}
                                    <div *ngIf="chat.FileUrl != '-'">
                                        <a class="btn btn-default"
                                            style="border: 1px solid; text-align: left; width: 100%; margin-top: 5px; margin-bottom: 5px; background: #f3f0f0;"
                                            title="Click to View" href="{{chat.FileUrl}}" target="_blank"><i
                                                class="fa fa-paperclip" aria-hidden="true"></i> {{chat.FileName}}</a>
                                    </div>
                                </div>
                                <span class="time_date">{{chat.DateTime}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <form [formGroup]="chatForm" (ngSubmit)="sendMessage(chatForm.value)">
                <p *ngIf="fileName != ''" class="col-sm-6" style="padding: 7px; margin:5px;margin: 5px;
            background-color: #007ad9;
            vertical-align: middle !important;
            color: white;
            border-radius: 10px;" formControlName="Doc">
                    <span>{{fileName}}</span>
                    <i class="fa fa-times" aria-hidden="true"
                        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px; cursor: pointer;"
                        (click)="removeFile()"></i>
                </p>
                <div class="type_msg row col-sm-12">
                    <div class="input_msg_write col-sm-10">
                        <input type="text" class="form-control form-control-lg bg-light ng-valid ng-pristine ng-touched"
                            maxlength="250" placeholder="Enter Message..." style="width:108%"
                            formControlName="MessageBody" />
                    </div>
                    <div class="col-sm-2" style="margin-top: 7px;">
                        <button type="submit"
                            class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light pull-right">
                            <i _ngcontent-gth-c125="" class="fa fa-paper-plane-o"></i>
                        </button>
                        <input type="file" class="hide" name="uploadFile" #uploader
                            (change)="handleFileInput($event.target.files)">
                     
                        <button class="pull-right" type="button" (click)="uploader.click()" #fileInput style=" font-size: 23px;
                            width: 66px;
                            height: 37px;
                            border-radius: 10%;
                            margin-right: 13px;
                            background-color:#ffffff;
                            border: medium none;">
                            <i class='fa fa-paperclip' style="background-color:white ;"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>