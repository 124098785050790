<div class="ml-3">
    <div class="row mt-3" style="margin-bottom:15px;">
        <div class="col-xs-12">
            <div class="pills w-100">
                <ul class="nav nav-pills">
                    <li routerLinkActive="active">
                        <a type="button" class="btn btn-secondary">
                            <i class="bi bi-journal-text fa fa-list-alt"></i> Book LCL Order
                        </a>
                    </li>
                    &nbsp;
                    <li routerLinkActive="active">
                        <a type="button" class="btn btn-info fa fa-list-alt">
                            Manage Orders
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- Service Type && Customer Type  Start -->
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-3">
                    <input class="form-control" type="text">
                    &nbsp;
                </div>
                <div class="col-sm-3">
                    <!-- <input class="form-control" type="text">
                    &nbsp; -->
                    <div class="form-group">
                        <!-- <mat-select [(ngModel)]="selectedServiceType" placeholder="Select Service Type" class="form-control"
                        (selectionChange)='selectServiceType($event)'>
                        <mat-option *ngFor="let item of serviceTypes" [value]="item.LookupName">{{item.LookupName}}</mat-option>
                      </mat-select> -->
                      <div class="text-danger ml-3" >
                        Please select service type
                      </div>
                    </div>
                    <div class="col-sm-1"></div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="card">
        <div class="card-body">
            <form class="form mt-5">
                <div class="form-group">
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Pickup Date </label>
                        </div>
                        <div class="col-sm-3">
                            <input name="dt1" required type="date" class="form-control" value="" id="input-12">
                        </div>
                    </div>
                </div>
                <!-- Select Pickup request From Time -->
                <div class="form-group">
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Pickup From Time </label>
                        </div>
                        <div class="col-sm-3">
                            <input name="dt1" required type="date" class="form-control" value="" id="input-12">
                        </div>
                    </div>
                </div>
                <!-- Select Pickup request To Time -->
                <div class="form-group">
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Pickup To Time </label>
                        </div>
                        <div class="col-sm-3">
                            <input name="dt1" required type="date" class="form-control" value="" id="input-12">
                        </div>
                    </div>
                </div>
                <!-- Select Origin Address -->
                <div class="form-group">
                    <div class="row mt-3 ml-1 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Origin Address</label>
                        </div>
                        <div class="col-sm-10">
                            <div class="row">
                                <div class="col-sm-5">
                                    <!-- Origin Locations Dropdown -->

                                    <mat-select style="width: 349px!important;"
                                        placeholder="Select Origin/Pincode/Location/District"
                                        class="form-control ml-3  mr-3" name="LookupName">
                                        <mat-option>
                                            <!-- <mat-select-search [(ngModel)]="OriginLocationSearch"
                        (ngModelChange)="originLocationSearch(OriginLocationSearch,'Origin')"
                        [ngModelOptions]="{standalone: true}"
                        matTooltip="{{OriginLocationSearch == null || (OriginLocationSearch != null && OriginLocationSearch.length < 3) ? 'Enter at least 3 letters' : ''}}"
                        [placeholderLabel]="'Enter Origin/Pincode/Location/District'"
                        [noEntriesFoundLabel]="'Not found'"></mat-select-search> -->
                                        </mat-option>

                                    </mat-select>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <!-- Select Origin Location -->
                <div class="row mt-3 ml-3 mr-3">
                    <div class="col-sm-2 mr-0" style="vertical-align: middle;">
                        <label> </label>
                    </div>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-sm-5">
                                <!-- Origin Locations Dropdown -->
                                <mat-select style="width: 349px!important;" placeholder="Select Origin Pickup Location"
                                    class="form-control ml-2  mr-2" name="LookupName">
                                    <mat-option>
                                        <!-- <mat-select-search [(ngModel)]="OriginLocationSearch"
                        (ngModelChange)="originLocationSearch(OriginLocationSearch,'Origin')"
                        [ngModelOptions]="{standalone: true}"
                        matTooltip="{{OriginLocationSearch == null || (OriginLocationSearch != null && OriginLocationSearch.length < 3) ? 'Enter at least 3 letters' : ''}}"
                        [placeholderLabel]="'Enter Origin/Pincode/Location/District'"
                        [noEntriesFoundLabel]="'Not found'"></mat-select-search> -->
                                    </mat-option>

                                </mat-select>

                            </div>
                            <div class="col-sm-1 mt-2" style="padding: 0px;">
                                OR
                            </div>
                            <div class="col-sm-6" style="padding: 0px;">
                                <div class="row">
                                    <div class="col-sm-9" style="padding: 0px;">
                                        <!-- Origin Location Map -->
                                        <input style="width: 349px!important;" type="text"
                                            class="form-control ml-3  mr-3 " placeholder="Origin Location from Map"
                                            disabled />
                                    </div>
                                    <mat-icon
                                        style="color: #337ab7;height: 25px;padding-top: 4px;cursor: pointer;font-size: x-large;margin-left: -131px; "
                                        class="fa fa-map-marker"></mat-icon>
                                    <mat-icon style="color: #d83833;height: 25px;padding-top: 4px;cursor: pointer;"
                                        class="fa fa-ban"></mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Select Via1 Address -->
                <div class="form-group">
                    <div class="row mt-3 ml-1 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Via1 Address</label>
                        </div>
                        <div class="col-sm-10">
                            <div class="row">
                                <div class="col-sm-5">
                                    <!-- Origin Locations Dropdown -->

                                    <mat-select style="width: 349px!important;"
                                        placeholder="Select Via1/Pincode/Location/District"
                                        class="form-control ml-3  mr-3" name="LookupName">
                                        <mat-option>
                                            <!-- <mat-select-search [(ngModel)]="OriginLocationSearch"
        (ngModelChange)="originLocationSearch(OriginLocationSearch,'Origin')"
        [ngModelOptions]="{standalone: true}"
        matTooltip="{{OriginLocationSearch == null || (OriginLocationSearch != null && OriginLocationSearch.length < 3) ? 'Enter at least 3 letters' : ''}}"
        [placeholderLabel]="'Enter Origin/Pincode/Location/District'"
        [noEntriesFoundLabel]="'Not found'"></mat-select-search> -->
                                        </mat-option>

                                    </mat-select>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <!-- Select Origin Location -->
                <div class="row mt-3 ml-3 mr-3">
                    <div class="col-sm-2 mr-0" style="vertical-align: middle;">
                        <label> </label>
                    </div>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-sm-5">
                                <!-- Origin Locations Dropdown -->
                                <mat-select style="width: 349px!important;" placeholder="Select Via1 Drop Location"
                                    class="form-control ml-2  mr-2" name="LookupName">
                                    <mat-option>
                                        <!-- <mat-select-search [(ngModel)]="OriginLocationSearch"
        (ngModelChange)="originLocationSearch(OriginLocationSearch,'Origin')"
        [ngModelOptions]="{standalone: true}"
        matTooltip="{{OriginLocationSearch == null || (OriginLocationSearch != null && OriginLocationSearch.length < 3) ? 'Enter at least 3 letters' : ''}}"
        [placeholderLabel]="'Enter Origin/Pincode/Location/District'"
        [noEntriesFoundLabel]="'Not found'"></mat-select-search> -->
                                    </mat-option>

                                </mat-select>

                            </div>
                            <div class="col-sm-1 mt-2" style="padding: 0px;">
                                OR
                            </div>
                            <div class="col-sm-6" style="padding: 0px;">
                                <div class="row">
                                    <div class="col-sm-9" style="padding: 0px;">
                                        <!-- Origin Location Map -->
                                        <input style="width: 349px!important;" type="text"
                                            class="form-control ml-3  mr-3 " placeholder="Origin Location from Map"
                                            disabled />
                                    </div>
                                    <mat-icon
                                        style="color: #337ab7;height: 25px;padding-top: 4px;cursor: pointer;font-size: x-large;margin-left: -131px; "
                                        class="fa fa-map-marker"></mat-icon>
                                    <mat-icon style="color: #d83833;height: 25px;padding-top: 4px;cursor: pointer;"
                                        class="fa fa-ban"></mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Select via2 Address -->
                <div class="form-group">
                    <div class="row mt-3 ml-1 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Via2 Address</label>
                        </div>
                        <div class="col-sm-10">
                            <div class="row">
                                <div class="col-sm-5">
                                    <!-- Origin Locations Dropdown -->

                                    <mat-select style="width: 349px!important;"
                                        placeholder="Select Via2/Pincode/Location/District"
                                        class="form-control ml-3  mr-3" name="LookupName">
                                        <mat-option>
                                            <!-- <mat-select-search [(ngModel)]="OriginLocationSearch"
        (ngModelChange)="originLocationSearch(OriginLocationSearch,'Origin')"
        [ngModelOptions]="{standalone: true}"
        matTooltip="{{OriginLocationSearch == null || (OriginLocationSearch != null && OriginLocationSearch.length < 3) ? 'Enter at least 3 letters' : ''}}"
        [placeholderLabel]="'Enter Origin/Pincode/Location/District'"
        [noEntriesFoundLabel]="'Not found'"></mat-select-search> -->
                                        </mat-option>

                                    </mat-select>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <!-- Select via2 Location -->
                <div class="row mt-3 ml-3 mr-3">
                    <div class="col-sm-2 mr-0" style="vertical-align: middle;">
                        <label> </label>
                    </div>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-sm-5">
                                <!-- Origin via2 Dropdown -->
                                <mat-select style="width: 349px!important;" placeholder="Select Via2 Drop Location"
                                    class="form-control ml-2  mr-2" name="LookupName">
                                    <mat-option>
                                        <!-- <mat-select-search [(ngModel)]="OriginLocationSearch"
        (ngModelChange)="originLocationSearch(OriginLocationSearch,'Origin')"
        [ngModelOptions]="{standalone: true}"
        matTooltip="{{OriginLocationSearch == null || (OriginLocationSearch != null && OriginLocationSearch.length < 3) ? 'Enter at least 3 letters' : ''}}"
        [placeholderLabel]="'Enter Origin/Pincode/Location/District'"
        [noEntriesFoundLabel]="'Not found'"></mat-select-search> -->
                                    </mat-option>

                                </mat-select>

                            </div>
                            <div class="col-sm-1 mt-2" style="padding: 0px;">
                                OR
                            </div>
                            <div class="col-sm-6" style="padding: 0px;">
                                <div class="row">
                                    <div class="col-sm-9" style="padding: 0px;">
                                        <!-- Origin Location Map -->
                                        <input style="width: 349px!important;" type="text"
                                            class="form-control ml-3  mr-3 " placeholder="Origin Location from Map"
                                            disabled />
                                    </div>
                                    <mat-icon
                                        style="color: #337ab7;height: 25px;padding-top: 4px;cursor: pointer;font-size: x-large;margin-left: -131px; "
                                        class="fa fa-map-marker"></mat-icon>
                                    <mat-icon style="color: #d83833;height: 25px;padding-top: 4px;cursor: pointer;"
                                        class="fa fa-ban"></mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Select Destination Address -->
                <div class="form-group">
                    <div class="row mt-3 ml-1 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Destintion Address</label>
                        </div>
                        <div class="col-sm-10">
                            <div class="row">
                                <div class="col-sm-5">
                                    <!-- Origin Locations Dropdown -->

                                    <mat-select style="width: 349px!important;"
                                        placeholder="Select Destination/Pincode/Location/District"
                                        class="form-control ml-3  mr-3" name="LookupName">
                                        <mat-option>
                                            <!-- <mat-select-search [(ngModel)]="OriginLocationSearch"
                        (ngModelChange)="originLocationSearch(OriginLocationSearch,'Origin')"
                        [ngModelOptions]="{standalone: true}"
                        matTooltip="{{OriginLocationSearch == null || (OriginLocationSearch != null && OriginLocationSearch.length < 3) ? 'Enter at least 3 letters' : ''}}"
                        [placeholderLabel]="'Enter Origin/Pincode/Location/District'"
                        [noEntriesFoundLabel]="'Not found'"></mat-select-search> -->
                                        </mat-option>

                                    </mat-select>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <!-- Select Destination Location -->
                <div class="row mt-3 ml-3 mr-3">
                    <div class="col-sm-2 mr-0" style="vertical-align: middle;">
                        <label> </label>
                    </div>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-sm-5">
                                <!-- Origin Locations Dropdown -->
                                <mat-select style="width: 349px!important;"
                                    placeholder="Select Destination Drop Location" class="form-control ml-2  mr-2"
                                    name="LookupName">
                                    <mat-option>
                                        <!-- <mat-select-search [(ngModel)]="OriginLocationSearch"
                        (ngModelChange)="originLocationSearch(OriginLocationSearch,'Origin')"
                        [ngModelOptions]="{standalone: true}"
                        matTooltip="{{OriginLocationSearch == null || (OriginLocationSearch != null && OriginLocationSearch.length < 3) ? 'Enter at least 3 letters' : ''}}"
                        [placeholderLabel]="'Enter Origin/Pincode/Location/District'"
                        [noEntriesFoundLabel]="'Not found'"></mat-select-search> -->
                                    </mat-option>

                                </mat-select>

                            </div>
                            <div class="col-sm-1 mt-2" style="padding: 0px;">
                                OR
                            </div>
                            <div class="col-sm-6" style="padding: 0px;">
                                <div class="row">
                                    <div class="col-sm-9" style="padding: 0px;">
                                        <!-- Origin Location Map -->
                                        <input style="width: 349px!important;" type="text"
                                            class="form-control ml-3  mr-3 " placeholder="Origin Location from Map"
                                            disabled />
                                    </div>
                                    <mat-icon
                                        style="color: #337ab7;height: 25px;padding-top: 4px;cursor: pointer;font-size: x-large;margin-left: -131px; "
                                        class="fa fa-map-marker"></mat-icon>
                                    <mat-icon style="color: #d83833;height: 25px;padding-top: 4px;cursor: pointer;"
                                        class="fa fa-ban"></mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Select Vehicle Type -->
                <div class="form-group">
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Vehicle Type </label>
                        </div>
                        <div class="col-sm-5">
                            <mat-select style="width: 270px!important;" placeholder="Select Vehicle Type"
                                class="form-control ml-3  mr-3" name="LookupName">
                            </mat-select>
                        </div>
                    </div>
                </div>
                <!-- Select Delivery Region -->
                <div class="form-group">
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Delivery Region </label>
                        </div>
                        <div class="col-sm-5">
                            <mat-select style="width: 270px!important;" placeholder="Select Delivery Region"
                                class="form-control ml-3  mr-3" name="ZoneName">

                            </mat-select>
                        </div>
                    </div>
                </div>
                <!-- Select Delivery State -->
                <div class="form-group">
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Delivery State </label>
                        </div>
                        <div class="col-sm-5">
                            <mat-select style="width: 270px!important;" placeholder="Select Delivery Region"
                                class="form-control ml-3  mr-3" name="ZoneName">

                            </mat-select>
                        </div>
                    </div>
                </div>
                <!-- Enter Tentative Date -->
                <div class="form-group">
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Tentative Weight </label>
                        </div>
                        <div class="col-sm-5">
                            <input min="0" style="width: 270px!important;" type="number" class="form-control ml-3  mr-3"
                                formControlName="TentativeWeight" placeholder="Enter Tentative Weight" required />
                        </div>
                    </div>
                </div>
                <!-- Enter No of bookings  -->
                <div class="form-group">
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>No of bookings </label>
                        </div>
                        <div class="col-sm-10">
                            <div class="row">
                                <div class="col-sm-5">
                                    <input min="1" max="10" style="width: 270px!important;" type="number"
                                        class="form-control ml-3  mr-3" formControlName="total_bookings"
                                        placeholder="Enter no of bookigs" required />
                                </div>
                                <div class="col-sm-2 mt-2" style="padding: 0px; color: #246ade;">
                                    [ Max 10 bookings ]
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          
  
                <!-- Enter POC Name -->
                <div class="form-group">
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>POC Name </label>
                        </div>
                        <div class="col-sm-5">
                            <input style="width: 270px!important;" type="text" class="form-control ml-3  mr-3"
                                formControlName="POCName" placeholder="Enter POC Name" required />
                        </div>
                    </div>
                </div>
                 <!-- Enter POC Number -->
                 <div class="form-group">
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>POC Number </label>
                        </div>
                        <div class="col-sm-5">
                            <input maxlength="10" style="width: 270px!important;" type="text" 
                            class="form-control ml-3  mr-3" formControlName="POCNumber" placeholder="Enter POC Number" required />
            
             </div>
                    </div>
                 </div>
                   <!-- Enter Freight -->
                   <div class="form-group" >
                    <div class="row mt-3 ml-3 mr-3">
                        <div class="col-sm-2 add-member-label mr-0" style="vertical-align: middle;">
                            <label>Freight </label>
                          </div>
                          <div class="col-sm-5">
                            <input min="0" style="width: 270px!important;" type="text" 
                              class="form-control ml-3  mr-3" formControlName="FreightAmount" placeholder="Enter Freight" />
                          </div>
                    </div>
                   </div>
                    <!-- Enter Remarks -->
                    <div class="form-group">
                        <div class="row mt-3 ml-3 mr-3">
                            <div class="col-sm-2  mr-0" style="vertical-align: middle;">
                                <label>Remarks </label>
                            </div>
                            <div class="col-sm-5">
                                <textarea style="width: 270px!important;padding: 5px;" class=" ml-3  mr-3" formControlName="Remarks"
                                  placeholder="Enter Remarks" cols="20"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-success" style="background-color: #f58640 !important; border-color: #f58640 !important;">Book Order</button>
                      </div>
            </form>
        </div>
    </div>
     <!-- Book Order Form End -->
</div>