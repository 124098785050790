import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utilization',
  templateUrl: './utilization.component.html',
  styleUrls: ['./utilization.component.scss']
})
export class UtilizationComponent implements OnInit {
  public single: any[];
  public multi: any[]; 
  piedataAverage: any[];
  bardatabranch: any[];
access :boolean=false;
  viewAverage: any[] = [600, 400];
  showXAxisAverage = true;
  showYAxisAverage = true;
  gradientAverage = false;
  showLegendAverage = true;
  showXAxisLabelAverage = false;
  showYAxisLabelAverage = true;
  yAxisLabelAverage = 'Hours';
  showDataLabelAverage = true;
  colorSchemeAverage = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#ff6361', '#a05195', '#665191']
  };

  public isCollapsed = false;
  viewb: any[] = [1400, 450];
  showXAxisb = true;
  showDataLabelb = true;
  showYAxisb = true;
  gradientb = false;
  showLegendb = true;
  showXAxisLabelb = true;
  xAxisLabelb = 'Branches';
  showYAxisLabelb = true;
  yAxisLabelb = 'Hours';
  colorSchemeb = {
    // domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#ff6361', '#a05195', '#665191']
    domain: []
  };

  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = false;
  public showXAxisLabel = true;
  public xAxisLabel = 'Country';
  public showYAxisLabel = true;
  public yAxisLabel = 'Hours';
  public colorScheme = {
    domain: ['#2F3E9E', '#D22E2E', '#378D3B', '#0096A6', '#F47B00', '#606060']
  };

  statusRating: any[] = [
    { hours: '24', Rating: 5, Grade: "Excellent", color: '#5AA454' },
    { hours: '48', Rating: 4, Grade: "Good", color: ' #C7B42C' },
    { hours: '62', Rating: 3, Grade: "Average", color: ' #ff8c00' },
    { hours: '96', Rating: 2, Grade: "Poor", color: '#ff6361' },
    { hours: '>96', Rating: 1, Grade: "Very Poor", color: '#FF0000' }

  ]

  statusRatingColors: any[] = ['#5AA454', '#C7B42C', '#ff8c00', '#ff6361', '#FF0000']
  constructor() { 
    this.multi = [{
      name: 'Trips',
      value: 10
    },
    {
      name: 'OTA',
      value: 20
    }, {
      name: 'OTP',
      value: 30
    },
    {
      name: 'POD',
      value: 40
    }, {
      name: 'Service Level',
      value: 50
    }];
  }

  ngOnInit(): void {
    this.setDataForAverage();
    this.setDataForBranchGraph()
  }

  infoClicked: boolean = false;
  onInfoClick(value : string) {
    if(value === 'info'){
      this.infoClicked = true
    }
    else if(value === 'cancel'){
      this.infoClicked = false
    }

    console.log(this.infoClicked)
    
  }

  setDataForAverage() {
    this.piedataAverage = [
      { name: "Loading", value: 6 },
      { name: "Unloading", value: 10 }
    ]

  }

  setDataForBranchGraph() {
    //create local variable of type array
    var bardataBranchHelper: any[]
   
    bardataBranchHelper = [
      { name: "GURGOAN", value: 98 },
      { name: "HYDERABAD", value: 93 },
      { name: "NO GPS", value: 85 },
      { name: "BANGALORE", value: 80 },
      { name: "CHENNAI", value: 76 },
      { name: "KOLKATA", value: 72 },
      { name: "PUNE", value: 68 },
      { name: "AHMEDABAD", value: 61 },
      { name: "AMBALA", value: 59 },
      { name: "COCHIN", value: 57 },
      { name: "COIMBATORE", value: 50 },
      { name: "DELHI", value: 45 },
      { name: "GOA", value: 39 },
      { name: "HUBLI", value: 32 },
      { name: "INDORE", value: 23 }
    ];
    bardataBranchHelper = bardataBranchHelper.reverse()

    bardataBranchHelper.forEach(element => {
      if (element['value'] > 96) {
        this.colorSchemeb.domain.push("#FF0000")
      }
      else if (element['value'] > 62 && element['value'] <= 96) {
        this.colorSchemeb.domain.push("#ff6361")
      }
      else if (element['value'] > 48 && element['value'] <= 62) {
        this.colorSchemeb.domain.push("#ff8c00")
      }
      else if (element['value'] > 24 && element['value'] <= 48) {
        this.colorSchemeb.domain.push("#C7B42C")
      }
      else {
        this.colorSchemeb.domain.push("#5AA454")
      }
    })

    this.bardatabranch = bardataBranchHelper
  }
  branchTableVisible: boolean
  customerTableVisible: boolean
  filterToggle: boolean;
  filterToggle1: boolean;
  chartsClicked(chartType: string) {
    if (chartType == 'branch') {
      this.branchTableVisible = true
      this.customerTableVisible = false
    }
    else if (chartType == 'customer') {
      this.branchTableVisible = false
      this.customerTableVisible = true
    }

  }
  customerTable: any[] = []
  setcustomerTable() {

    this.customerTable = [
      {
        Branch: "Indore", zone: "NORTH 2", VehicleNumber: "HR55AC9043", VehicleType: "32MXL 14MT", CapacityType: "Dedicated",
        OrderId: 101, Customer: "-", XPCN: "XPCN/DLI01/129", DateTimein: "	27-07-2022 11:57", DateTimeout: "27-07-2022 13:00", ActivityLoadingorunloading: "Loading", TimeTaken: "1:00", Status: "3"}
      // },
      // {
      //   Branch: "ALLAHABAD", zone: "NORTH 2", VehicleNumber: "HR55AC9044", VehicleType: "32MXL 14MT", CapacityType: "Dedicated",
      //   OrderId: 102, Customer: "SPROUTLIFE FOODS PRIVATE LIMITED", XPCN: "XPCN/DLI01/129", DateTimein: "29-07-2022 13:17", DateTimeout: "29-07-2022 14:57", ActivityLoadingorunloading: "Loading", TimeTaken: "3:00", Status: "2"
      // },
      // {
      //   Branch: "NO GPS", zone: "NORTH 2", VehicleNumber: "HR55AC9043", VehicleType: "32MXL 14MT", CapacityType: "Dedicated",
      //   OrderId: 103, Customer: "The Indian Express Private Limited", XPCN: "XPCN/DLI01/129", DateTimein: "30-07-2022 14:57", DateTimeout: "30-07-2022 15:57", ActivityLoadingorunloading: "Unloading", TimeTaken: "2:00", Status: "1"
      // },
      // {
      //   Branch: "Goa", zone: "NORTH 2", VehicleNumber: "HR55AC9043", VehicleType: "32MXL 14MT", CapacityType: "Dedicated",
      //   OrderId: 104, Customer: "CASA DESIGN STUDIO", XPCN: "XPCN/DLI01/129", DateTimein: "01-08-2022 10:57", DateTimeout: "01-08-2022 13:00", ActivityLoadingorunloading: "Unloading", TimeTaken: "1:00", Status: "1"
      // },
      // {
      //   Branch: "Indore", zone: "NORTH 2", VehicleNumber: "HR55AC9043", VehicleType: "32MXL 14MT", CapacityType: "Dedicated",
      //   OrderId: 105, Customer: "	MOV Enterprises", XPCN: "XPCN/DLI01/129", DateTimein: "02-08-2022 10:00", DateTimeout: "02-08-2022 11:57", ActivityLoadingorunloading: "loading", TimeTaken: "2:00", Status: "5"
      // }
    ]
  }

}
