import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-uploadcsvdialog',
  templateUrl: './uploadcsvdialog.component.html',
  styleUrls: ['./uploadcsvdialog.component.scss']
})
export class UploadcsvdialogComponent implements OnInit {
  formData: FormData = new FormData();
  isSubmitting = false;
  addEditForm: FormGroup;
  capacityOfVehicle = "";
  showDownloadCSV = false;
  orders = [];
  excelData = [];
  isSuccess = false;
  constructor(public fb: FormBuilder,
    public dialogRef: MatDialogRef<UploadcsvdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    public _downloadExcelService: DownloadExcelService,private _share:SharedService) {

    this.addEditForm = this.fb.group({
      Type: new FormControl(''),
      Id: new FormControl(''),
      Doc: new FormControl('', Validators.compose([Validators.required]))
    });
  }

  ngOnInit(): void {
    if (this.data != null) {
      this.addEditForm.controls['Type'].setValue(this.data['type']);
      if (this.data['type'] == 'FFV Vehicles' || this.data['type'] == 'FFV Driver'
        || this.data['type'] == 'Fcl-Rates-Customer' || this.data['type'] == 'mis pocs'
        || this.data['type'] == 'XPCN Invoice Details' || this.data['type'] == 'XPCN Eway Bill Details'
      ) {
        this.addEditForm.controls['Id'].setValue(this.data['id']);
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  saveData() {
    if (this.addEditForm.valid) {
      this.showDownloadCSV = false;
      this.isSubmitting = true;
      this.formData.delete('FormData');
      this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
      if (this.addEditForm.controls['Type'].value == 'Price Master - Customer' || this.addEditForm.controls['Type'].value == 'Price Master - FFV') {
        this._share.uploadPriceMasterCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;

              }
            }
          }
        });
      } else if (this.addEditForm.controls['Type'].value == 'Diesel Master - Dedicated') {
        this._share.uploadDieselMasterCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      } else if (this.addEditForm.controls['Type'].value == 'Diesel Master - Dynamic') {
        this._share.uploadDynamicDieselMasterCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      } else if (this.addEditForm.controls['Type'].value == 'Post Codes') {
        this._share.uploadPostCodeCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              // if (data['Success']) {
              this.dialogRef.close(data['Data']);
              // } else {
              //   this.isSubmitting = false;
              // }
            }
          }
        });
      } else if (this.addEditForm.controls['Type'].value == 'FFV Vehicles') {
        this._share.uploadFFVVehicleCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      } else if (this.addEditForm.controls['Type'].value == 'FFV Driver') {
        this._share.uploadFFvDriverCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'Capacity') {
        this._share.uploadDynamicCapacity(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'Mark-Attendance') {
        this._share.uploadMarkAttendance(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'TDS-Payable-Others') {
        this._share.uploadPayableOthers(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'GST-Working-Input-Others') {
        this._share.uploadInputOthers(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'Fcl-Rates-Customer') {
        this._share.uploadFclRatesCsv(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'mis pocs') {
        this._share.uploadMISPOCsCsv(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'Quotation') {
        this._share.uploadQuotationCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'Adhoc Pricing - Customer') {
        this._share.uploadCustomerAdhocPriceingCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Success']);
              } else {
                this.isSubmitting = false;
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'XPCN Invoice Details') {
        this._share.uploadCustomerInvoiceDetailsCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
                this.dialogRef.close(data['Data']);
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'XPCN Eway Bill Details') {
        this._share.uploadCustomerEwayBillDetailsCSV(this.formData).subscribe(data => {
          if (data != null) {
            if (data['Success'] != null) {
              if (data['Success']) {
                this.dialogRef.close(data['Data']);
              } else {
                this.isSubmitting = false;
                this.dialogRef.close(data['Data']);
              }
            }
          }
        });
      }
      else if (this.addEditForm.controls['Type'].value == 'Book New Order') {
        this._share.uploadBookNewOrderCSV(this.formData).subscribe(data => {
          if (data != null) {
            this.isSubmitting = false;
            if (data['Data'] != null && data['Data'].length > 0) {
              this.showDownloadCSV = true;
              this.orders = data['Data'];
            }
            if (data['Success'] != null) {
              if (data['Success']) {
                this.isSuccess = true;
              } else {
                this.isSuccess = false;
              }
            }
          }
        });
      }
    } else {
      this.addEditForm.controls['Doc'].markAsTouched();
    }
  }

  handleFileInput(files: FileList) {
    this.formData.delete('123');
    if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/vnd.ms-excel', '.csv'];
      // const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'application/vnd.ms-excel', 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'];
      let filename = files.item(0).name.toLowerCase();
      let fileExt = filename.slice(filename.length - 3, filename.length);

      if (acceptedFileTypes.includes(files.item(0).type) || fileExt === "csv") {
        if (files.item(0).size <= 10000000) {
          this.formData.delete('123');
          this.formData.append('123', files.item(0));
        } else {
          this.alertService.createAlert('File size must be less than 10 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format', 0);
        this.addEditForm.controls['Doc'].setValue(null);
        return;
      }
    }
  }

  downloadCSV() {
    this.excelData = [];
    if (this.orders.length > 0) {
      if (this.isSuccess) {
        this.orders.forEach(element => {
          this.excelData.push({
            'Customer Code': element['CustomerCode'],
            'PO Number': element['PONumber'],
            'Order Date': element['OrderDateStr'],
            'FFV Code': element['FFVCode'],
            'Vehicle Type': element['VehicleType'],
            'Vehicle Number': element['VehicleNumber'],
            'Driver Number': element['DriverNumber'],
            'Vendor Id': element['VendorId'],
            'Vendor Name': element['VendorName'],
            'Origin': element['Origin'],
            'Pickup Location': element['PickupLocation'],
            'Origin State': element['OriginState'],
            'Origin Pincode': element['OriginPincode'],
            'Destination': element['Destination'],
            'Drop Location': element['DropLocation'],
            'Destination State': element['DestinationState'],
            'Destination Pincode': element['DestinationPincode'],
            'POC Name': element['POCName'],
            'POC Number': element['POCNumber'],
            'Total Weight': element['WeightStr'],
            'Total Boxes': element['BoxesStr'],
            'Invoice Number': element['InvoiceNumber'],
            'Invoice Date': element['InvoiceDateStr'],
            'Invoice Value': element['InvoiceValueStr'],
            'EWay Bill Number': element['EWBNo'] + '#',
            'Order Id': element['OrderId'],
            'XPCN/XPLS Number': element['XPCNNo']
          })
        });
      } else {
        this.orders.forEach(element => {
          this.excelData.push({
            'Customer Code': element['CustomerCode'],
            'PO Number': element['PONumber'],
            'Order Date': element['OrderDateStr'],
            'FFV Code': element['FFVCode'],
            'Vehicle Type': element['VehicleType'],
            'Vehicle Number': element['VehicleNumber'],
            'Driver Number': element['DriverNumber'],
            'Vendor Id': element['VendorId'],
            'Vendor Name': element['VendorName'],
            'Origin': element['Origin'],
            'Pickup Location': element['PickupLocation'],
            'Origin State': element['OriginState'],
            'Origin Pincode': element['OriginPincode'],
            'Destination': element['Destination'],
            'Drop Location': element['DropLocation'],
            'Destination State': element['DestinationState'],
            'Destination Pincode': element['DestinationPincode'],
            'POC Name': element['POCName'],
            'POC Number': element['POCNumber'],
            'Total Weight': element['WeightStr'],
            'Total Boxes': element['BoxesStr'],
            'Invoice Number': element['InvoiceNumber'],
            'Invoice Date': element['InvoiceDateStr'],
            'Invoice Value': element['InvoiceValueStr'],
            'EWay Bill Number': element['EWBNo'] + '#',
            'Error': element['Error']
          })
        });
      }
    }
    this._downloadExcelService.exportAsExcelFile(this.excelData, 'Book New Order Result');
  }



}



