import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { MessagingService } from './services/messaging-service';
import { environment } from 'src/environments/environment';
import { LoaderService } from './services/loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  message;
  loaderMessage$: any;
    public settings: Settings;
    constructor(public appSettings:AppSettings,
      private _messageService: MessagingService,
      public _loaderService: LoaderService,
                public translate: TranslateService){
      this.settings = this.appSettings.settings; 
      translate.addLangs(['en','de','fr','ru','tr']);
      translate.setDefaultLang('en'); 
      translate.use('en'); 
    }    

    ngOnInit() {
      if (environment.production) {
        if (location.protocol === 'http:') {
          window.location.href = location.href.replace('http', 'https');
        }
      }
      this._messageService.requestPermission();
      this._messageService.receiveMessage();
      this.message = this._messageService.currentMessage;
      // console.log(this.message + ' from app comp');
  
      // this.settings.loadingSpinner = true;
      this._loaderService.message$.subscribe(
        data => {
          this.loaderMessage$ = data;
        }
      )
    }
}
