import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  constructor() { }
  userData: any = [];
  access : boolean = false;
  ngOnInit(): void {
    this.userData=JSON.parse(localStorage.getItem('userSession'));
    console.log(this.userData);
    if(this.userData['Role'] === "Financial" || this.userData['Administrator'] === true || this.userData['Role'] === "Operations & Financial")
    {
      this.access = true;
    }
    else{
      this.access = false;
    }
  }

}
