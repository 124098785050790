import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var google :any;
@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss']
})
export class MapDialogComponent implements OnInit {
  lat: number;
  lng: number;
  lat1: number;
  lng1: number;
  lat2: number;
  lng2: number;

  // lat: number = 28.466944400453272;
  // lng: number =  76.96920093547962;
  // zoom: number = 16;
  isService = false;
  OrderId : any;
  VehicleNumber : any;

  constructor(public dialogRef: MatDialogRef<MapDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: number) { }

  ngOnInit(): void {
    if (this.data != null) {
      if(this.data['isService']==true ){
        this.isService= true;
        //console.log("map",this.isService)
        this.VehicleNumber=this.data['vehiclenumber'];
        this.OrderId=this.data['orderid'];
      }
      else{
        this.isService=false;
        
      }
      if (this.data['data']['Latitude'] != null && this.data['data']['Longitude'] != null) {
        this.lat = Number(this.data['data']['Latitude']);
        this.lng = Number(this.data['data']['Longitude']);
       
       
      }

      if (this.data['data']['Latitude1'] != null && this.data['data']['Longitude1'] != null) {
        this.lat1 = Number(this.data['data']['Latitude1']);
        this.lng1 = Number(this.data['data']['Longitude1']);
       
      }

      if (this.data['data']['Latitude2'] != null && this.data['data']['Longitude2'] != null) {
        this.lat2 = Number(this.data['data']['Latitude2']);
        this.lng2 = Number(this.data['data']['Longitude2']);
        
      }
      
    }
  }
  close(): void {
    this.dialogRef.close();
  }
  onMapReady($event) {
    let trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap($event);
    }
}
