<div class="MarginClass">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
    <h3 mat-dialog-title>XPCN Preview </h3>
</div>
<div mat-dialog-content style="margin: 0px !important; padding: 0px !important">
    <div class="col-sm-12" id="pdf" style="padding-top: 15px;">
      <table style="width: 100%; border: 2px solid black; margin-bottom: 10px;">
        <tr>
          <td rowspan="2"
            style="text-align: center !important; vertical-align: middle !important; border-right: 2px solid black;">
            <img style="height:115px;margin-top:-12px;margin-left:5px; position: relative; top: 10px;"
              src="../../../../../../../assets/img/vendor/leaflet/logo_xpp.jpg">
          </td>
          <td colspan="2" style="text-align: center !important;">
            <h4 *ngIf="xpcn.CustomerGST != 18"><b>Goods Consignment Note (XPCN)</b></h4>
          </td>
          <td>
            <b>XPCN:</b>
            <p><b style="font-size: 17px;">{{xpcn.XPCNCode}}</b></p>
          </td>
        </tr>
        <tr>
          <td>
            <b>Booking ID:</b>
            <p>{{xpcn.BookingId}}</p>
          </td>
          <td>
            <b>Order ID:</b>
            <p>{{xpcn.OrderIdCode}}</p>
          </td>
          <td>
            <b>Order Date:</b>
            <p>{{xpcn.strOrderDate}}</p>
          </td>
        </tr>
        <tr>
          <td *ngIf="rows == 5" style="width: 25% !important; font-size: 12px; border-right: 2px solid black;" rowspan="5">
            <p>Instant Supply Chain Solutions Private Limited, 14th Floor, Ocus Medley, Dwarka Expressway, G99, Sector-99,
              Gurugram, Haryana 122001.</p><br>
            <p>www.xpindia.in</p><br>
            <p>CIN: U63030HR2019PTC083859</p><br>
            <p>GSTIN: 06AAFCI4823D1ZW</p>
          </td>
          <td *ngIf="rows == 6" style="width: 25% !important; font-size: 12px; border-right: 2px solid black;" rowspan="6">
            <p>Instant Supply Chain Solutions Private Limited, 14th Floor, Ocus Medley, Dwarka Expressway, G99, Sector-99,
              Gurugram, Haryana 122001.</p><br>
            <p>www.xpindia.in</p><br>
            <p>CIN: U63030HR2019PTC083859</p><br>
            <p>GSTIN: 06AAFCI4823D1ZW</p>
          </td>
          <td style="width: 25% !important;">
            <b>Freight Mode:</b>
            <p>{{freightMode}}</p>
          </td>
          <td style="width: 25% !important;">
            <b>XPCN Date:</b>
            <p>{{xpcn.strXPCNDate}}</p>
          </td>
          <td style="width: 25% !important;">
            <b>Booking Branch:</b>
            <p>{{xpcn.BranchName}}</p>
          </td>
        </tr>
        <tr>
          <td>
            <b>Type of Service:</b>
            <p>{{xpcn.ServiceType}}</p>
          </td>
          <td>
            <b>Service Class:</b>
            <p>{{xpcn.ServiceClass}}</p>
          </td>
          <td>
            <b>Despatch Date:</b>
            <p>{{xpcn.strDepartedOriginDate}}</p>
          </td>
        </tr>
        <tr>
          <td>
            <b>Vehicle Type:</b>
            <p>{{xpcn.VehicleType}}</p>
          </td>
          <td>
            <b>Payload:</b>
            <p>{{rows == 6 ? '-' : xpcn.Payload}}</p>
          </td>
          <td>
            <b>Vehicle No:</b>
            <p>{{xpcn.VehicleNo}}</p>
          </td>
        </tr>
        <tr>
          <td>
            <b>Origin:</b>
            <p>{{xpcn.From}}</p>
          </td>
          <td>
            <b>Destination:</b>
            <p>{{xpcn.To}}</p>
          </td>
          <td>
            <b>Via Points:</b>
            <p>{{viaPoints}}</p>
          </td>
        </tr>
        <tr>
          <td>
            <b>Delivery Branch:</b>
            <p>{{xpcn.Destination_branch}}</p>
          </td>
          <td>
            <b>Customer Name:</b>
            <p>{{xpcn.CustomerName}}</p>
          </td>
          <td *ngIf="rows == 6">
            <b>PO Number:</b>
            <p>{{xpcn.PONumber}}</p>
          </td>
          <td *ngIf="rows == 5">
          </td>
        </tr>
        <tr *ngIf="rows == 6">
          <td>
            <b>Vendor Id:</b>
            <p>{{xpcn.VendorId}}</p>
          </td>
          <td>
            <b>Vendor Name:</b>
            <p>{{xpcn.VendorName}}</p>
          </td>
          <td>
            <b>Vendor Location:</b>
            <p>{{xpcn.VendorLocation}}</p>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="text-align: center !important; border: 1px solid black;">
            <b>Consignor/Shipper Details</b>
          </td>
          <td colspan="2" style="text-align: center !important; border: 1px solid black;">
            <b>Consignee/Receiver Details</b>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="border: 1px solid black;" *ngIf="xpcn.TransactionType == 2">
            <p>GSTIN: {{xpcn.ConsignorGSTIN}}</p>
            <p>{{xpcn.ConsignorName}}</p>
            <p>{{xpcn.ConsignorState}}</p>
            <br>
            <p>::Dispatch From::</p>
            <p>{{xpcn.ConsignorAddress}}</p>
            <p>{{xpcn.ConsignorAddress2}}</p>
            <p>{{xpcn.From}}, {{xpcn.ConsignorState}}, {{xpcn.ConsignorPostcode}}</p>
          </td>
          <td colspan="2" style="border: 1px solid black;" *ngIf="xpcn.TransactionType != 2">
            <p>{{xpcn.ConsignorName}}, {{xpcn.ConsignorAddress}}, {{xpcn.ConsignorContact}}</p>
          </td>
          <td colspan="2" style="border: 1px solid black;" *ngIf="xpcn.TransactionType == 2">
            <p>GSTIN: {{xpcn.ConsigneeGSTIN}}</p>
            <p>{{xpcn.ConsigneeName}}</p>
            <!-- <p>{{xpcn.ConsigneeState}}</p> -->
            <br>
            <p>::Ship To::</p>
            <p>{{xpcn.ConsigneeAddress}}</p>
            <p>{{xpcn.ConsigneeAddress2}}</p>
            <p>{{xpcn.To}}, {{xpcn.ConsigneeState}}, {{xpcn.ConsigneePostcode}}</p>
          </td>
          <td colspan="2" style="border: 1px solid black;" *ngIf="xpcn.TransactionType != 2">
            <p>{{xpcn.ConsigneeName}}, {{xpcn.ConsigneeAddress}}, {{xpcn.ConsigneeContact}}</p>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="border: 1px solid black;">
            <b>GSTIN:</b>
            <span *ngIf="xpcn.TransactionType != 2"> {{xpcn.ConsignorGSTIN}}</span>
          </td>
          <td colspan="2" style="border: 1px solid black;">
            <b>GSTIN:</b>
            <span *ngIf="xpcn.TransactionType != 2"> {{xpcn.ConsigneeGSTIN}}</span>
          </td>
        </tr>
        <tr *ngIf="invoices.length <= 10">
          <td colspan="2">
            <b>Invoice/STN No. & Date: </b><br>
            <span *ngFor="let item of invoices; let i = index"
              style="font-size: 13px !important;">{{item.InvoiceNo}}/{{item.Date | date : 'dd-MM-y'}}<span
                *ngIf="i < invoices.length -1">, </span></span>
          </td>
          <td>
            <b>Total Value of Invoice/STN:</b>
            <p>{{invoicesTotal}}</p>
          </td>
          <td>
            <b>Actual Weight in Kgs:</b>
            <p>{{xpcn.Weight}}</p>
          </td>
        </tr>
        <tr *ngIf="invoices.length > 10">
          <td colspan="3">
            <b>Invoice/STN No. & Date: </b><br>
            <span *ngFor="let item of invoices; let i = index"
              style="font-size: 13px !important;">{{item.InvoiceNo}}/{{item.Date | date : 'dd-MM-y'}}<span
                *ngIf="i < invoices.length -1">, </span></span>
            <span *ngIf="invoicesCount > 15"> and {{invoicesCount - 15}} others</span>
          </td>
          <td>
            <b>Total Value of Invoice/STN:</b>
            <p>{{invoicesTotal}}</p>
            <b>Actual Weight in Kgs:</b>
            <p>{{xpcn.Weight}}</p>
          </td>
        </tr>
        <tr *ngIf="waybills.length <= 10">
          <td colspan="2">
            <b>Ewaybill Details: </b><br>
            <span *ngFor="let item of waybills; let i = index" style="font-size: 13px !important;">{{item.InvoiceNo}}<span
                *ngIf="i < waybills.length -1">, </span></span>
          </td>
          <td>
            <b>Goods Descriptions:</b>
            <p>{{goodsDesc}}</p>
          </td>
          <td>
            <b>Quantity and type of packing:</b>
            <p>{{xpcn.NoOfPackages}} {{packageType}}</p>
          </td>
        </tr>
        <tr *ngIf="waybills.length > 10">
          <td colspan="3">
            <b>Ewaybill Details: </b><br>
            <span *ngFor="let item of waybills; let i = index" style="font-size: 13px !important;">{{item.InvoiceNo}}<span
                *ngIf="i < waybills.length -1">, </span></span>
            <span *ngIf="waybillsCount > 15"> and {{waybillsCount - 15}} others</span>
          </td>
          <td>
            <b>Goods Descriptions:</b>
            <p>{{goodsDesc}}</p>
            <b>Quantity and type of packing:</b>
            <p>{{xpcn.NoOfPackages}} {{packageType}}</p>
          </td>
        </tr>
        <tr *ngIf="waybills.length <= 10">
          <td colspan="2">
            <b>Validity of EWaybill: </b><br>
            <span *ngFor="let item of waybills; let i = index" style="font-size: 13px !important;">{{item.ExpDate | date :
              'dd-MM-y'}}<span *ngIf="i < waybills.length -1">, </span></span>
          </td>
          <td>
            <b>Transit Risk:</b>
            <p>{{riskType}}</p>
          </td>
          <td>
            <b>OTL:</b>
            <p>{{xpcn.OTL}}</p>
          </td>
        </tr>
        <tr *ngIf="waybills.length > 10">
          <td colspan="3">
            <b>Validity of EWaybill: </b><br>
            <span *ngFor="let item of waybills; let i = index" style="font-size: 13px !important;">{{item.ExpDate | date :
              'dd-MM-y'}}<span *ngIf="i < waybills.length -1">, </span></span>
            <span *ngIf="waybillsCount > 15"> and {{waybillsCount - 15}} others</span>
          </td>
          <td>
            <b>Transit Risk:</b>
            <p>{{riskType}}</p>
            <b>OTL:</b>
            <p>{{xpcn.OTL}}</p>
          </td>
        </tr>
        <tr *ngIf="showFreight == true">
          <td colspan="4">
            <b style="text-decoration: underline;">FREIGHT DETAILS:</b>
          </td>
        </tr>
        <tr *ngIf="showFreight == true">
          <td>
            <b>Basic Freight:</b>
            <p>{{xpcn.Rate}}</p>
          </td>
          <td>
            <b>Handling Charges:</b>
            <p>{{xpcn.HandlingCharges}}</p>
          </td>
          <td>
            <b>Halting Charges:</b>
            <p>{{xpcn.HaltingCharges}}</p>
          </td>
          <td>
            <b>Other Charges:</b>
            <p>{{xpcn.OtherCharges}}</p>
          </td>
        </tr>
        <tr *ngIf="showFreight == true">
          <td>
            <b>CGST:</b>
            <p>{{cgst}}</p>
          </td>
          <td>
            <b>SGST:</b>
            <p>{{sgst}}</p>
          </td>
          <td>
            <b>IGST:</b>
            <p>{{igst}}</p>
          </td>
          <td>
            <b>GRAND TOTAL:</b>
            <p>{{total}}</p>
          </td>
        </tr>
  
        <tr>
          <td colspan="4" style="border: 1px solid black;">
            <b> Special Remarks:</b>{{xpcn.SpecialRemarks}}
          </td>
        </tr>
  
        <tr>
          <td colspan="4" style="border: 1px solid black;">
            <p style="font-size: 12px;">Declaration: On behalf of my organisation , I certify that the above information
              mentioned In the XPCN is correct as per my knowledge and I undertake to make the Freight payment for this
              Order Id/XPCN as per the terms & conditions of XPCN and contracual agreement.
            </p>
            <p>
              <span class="pull-right">{{xpcn.CustomerName}}</span>
            </p>
          </td>
        </tr>
  
        <tr>
          <td colspan="4" style="text-align: center !important; background-color: yellow; border: 1px solid black;">
            <b>PROOF OF DELIVERY (POD)</b>
          </td>
  
        </tr>
        <tr style="height: 60px;">
          <td colspan="3" style="border: 1px solid black;">
            Goods and Documents received in full and intact condition
          </td>
          <td style="border: 1px solid black;">
            <b>SIGNATURE:</b>
            <img src="{{xpcn.mPODUrl}}" *ngIf="xpcn.mPODUrl != ''" style="max-width: 175px !important">
          </td>
        </tr>
        <tr>
          <td>
            <b>NAME:</b> {{xpcn.Name}}
          </td>
          <td>
            <b>TELEPHONE:</b> {{xpcn.Number}}
          </td>
          <td>
            <b>STAMP:</b>
          </td>
          <td>
            <b>DATE:</b> {{xpcn.mPODDate != null ? (xpcn.mPODDate | date : 'dd-MM-y, hh:mm a') : ''}}
          </td>
        </tr>
  
        <tr>
          <td colspan="4" style="border: 1px solid black;">
            <!-- <b>XPCN: {{xpcn.XPCNCode}}</b> -->
            <b>Delivery Remarks: </b> {{xpcn.Remarks}}
          </td>
        </tr>
  
        <tr>
          <td colspan="4" style="border-top: 1px solid black;">
            <p style="font-size: 12px;">This is a Non-Negotiable Goods Consignment Note. It is agreed that the goods
              described here in are accepted in “Said to contain” basis as per the Invoices/STNs and Ewaybills. The onus
              of proper documentation with respect to GST, GSTIN & Ewaybill, Excise Duty, Customs and any statutory laws
              lies with the parties i e. Consignor and consignee. ISCPL shall not be responsible/ liable in any case of
              deficiency in the documents/statutory requirements.<br>
              Carrying terms and liabilities are as per the contractual agreement.</p> <br><br>
            <p>*(Auto-Generated XPCN)</p>
          </td>
        </tr>
      </table>
  
      <table *ngIf="waybills1.length >= 15" class="table table-bordered breakhere"
        style="width: 100%; border: 2px solid black; border-collapse: collapse;">
        <tr>
          <th>Sl No</th>
          <th>Invoice No</th>
          <th>EwayBill No</th>
          <th>EwayBill Issue Date</th>
          <th>EwayBill Expiry Date</th>
        </tr>
        <tr *ngFor="let item of waybills1; let i = index">
          <td>{{i + 1}}</td>
          <td>
            <span *ngIf="invoices1.length - 1 >= i">{{invoices1[i].InvoiceNo}}</span>
          </td>
          <td>{{item.InvoiceNo}}</td>
          <td>{{item.Date | date : 'dd-MM-y'}}</td>
          <td>{{item.ExpDate | date : 'dd-MM-y'}}</td>
        </tr>
      </table>
    </div>
  </div>
<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
    <button class="btn btn-primary fa fa-download" (click)=" downloadPDF()"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Download</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
</mat-dialog-actions>
