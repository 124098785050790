import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-add-new-xpcn',
  templateUrl: './add-new-xpcn.component.html',
  styleUrls: ['./add-new-xpcn.component.scss']
})
export class AddNewXPCNComponent implements OnInit {

  public popoverTitle: string = 'Confirm Add XPCN';
  public popoverMessage: string = 'Are you sure you want to Add XPCN ?';
  xpcnDetails : any[] =[]
  isXPCNAdded: boolean = false
  canXPCNAdded: boolean = true
  showErrorMessage: boolean = false
  cancelClicked: any;
  bookingId;

  constructor(public alertService: AlertService, public dialogRef: MatDialogRef<AddNewXPCNComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public _pageService: PagesService) {

    dialogRef.disableClose = true;
  }
ngOnInit(): void {
  this.bookingId = this.data.BookingId;
  this.xpcnDetails = this.data.XPCNDetails;
  console.log(this.xpcnDetails);
}


addLCLOrderAndXPCN() {
  if (this.bookingId != null) {
    this._pageService.addOrderAndXPCNLCL(this.bookingId).subscribe(res => {
      if (res['Success']) {
        console.log(res['Data']);
        this.isXPCNAdded = true
        this.xpcnDetails.push(res['Data'])
        // this.alertService.createAlert(res['Message'], 1)
      }
      // else {
      //   this.alertService.createAlert(res['Message'], 0)
      // }
    })
  }
  // else {
  //   this.alertService.createAlert("Something went wrong", 0)
  // }
}

close() {
  this.dialogRef.close({ isXpcnAdded: this.isXPCNAdded })
}

}