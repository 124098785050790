import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs';
import { AppSettings } from 'src/app/app.settings';
import { AnalyticsService } from './analytics.service';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  Spinner: boolean = false;
  filterToggle: boolean;
  CustomDate: boolean = false;
  public xAxisLabel = '';
  public yAxisLabel = '';
  gradient3: boolean = true;
  showLegend3: boolean = true;
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  showDataLabel: boolean = true;

  // number card
  card_data: any[];
  data: any[];
  view: any[] = [550, 250];
  textColor: string = '#000000';
  colorScheme2 = {
    domain: ['#3737EC', '#F4902B', '#5AA454', '#7f675b', '#00f2f2', '#954D92', '#FF3333']
  };
  cardColor: string = 'rgb(236 233 238)';

  //tree map- Payables
  public gradient: boolean = false;
  public payable_chart: any[];
  public animations: boolean = true;
  colorScheme = {
    domain: ['#5AA454', '#f47b00', '#d22e2e']
  };
  //5AA454
  colorScheme6 = {
    domain: ['#5AA454', '#d22e2e']
  };

  //pie chart- Service level
  Service_chart: any[];
  showLegend: boolean = true;
  gradient1: boolean = true;
  showLabels: boolean = true;
  explodeSlices: boolean = true;
  legendPosition: string = 'right';
  legendTitle: string;

  public colorScheme1 = {
    domain: ['#ff3333', '#0000ff', '#cc33ff']
  };

  //vertical bar chart
  barchart_trips: any;
  barchart_performance: any;
  view1: any[] = [500, 300];

  // options
  colorScheme4 = {
    domain: ['#BD1616', '#DA8B3C', '#388B30']
  };
  schemeType: string = 'linear';
  animations2: boolean = true;
  showLegend1: boolean = false;
  legendTitle1: string = 'Trips';
  legendPosition1: string = 'right';
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showGridLines: boolean = true;
  gradient2: boolean = true;


  fromDate = null;
  toDate = null;
  isDateVisible: boolean;
  GlobalFilterOptions = [
    { DisplayName: 'Weekly' },
    { DisplayName: 'Fortnightly' },
    { DisplayName: 'MTD' },
    { DisplayName: 'QTD' },
    { DisplayName: 'YTD' },
    { DisplayName: 'Custom Range' },
  ]
  activeFilterOption: string = 'MTD';
  OrdersData: any[];
  ServiceLevelData = [];
  PerformanceData = [];
  PayableData = [];
  TripsData = [];
  OrderTrip = [];

  Cancelled = 0;
  Confirmed = 0;
  Picked = 0;
  Provisional = 0;
  TotalOrders = 0;
  InTransit = 0;
  Complete = 0;
  Sales = 0;
  arrived=0;
  ToBePlaced = 0;
  TotalFreight=0;

  constructor(private _appSettings: AppSettings,
    private _analyticsService: AnalyticsService,
    private _datePipe: DatePipe,
    private alertService: AlertService) {
    this.view = [500, 300];
  }

  ngOnInit() {
    
    this.applyFilter('MTD', false);

  }

  setDates() {
    this.Spinner=true;
    if (this.fromDate == null || this.toDate == null) {
      this.alertService.createAlert('From and To Dates are mandatory', 0);
    }
    let fromDate = this._datePipe.transform(this.fromDate, 'yyyy-MM-dd');
    let toDate = this._datePipe.transform(this.toDate, 'yyyy-MM-dd');
    this._appSettings.RecievablesGlobalFilter.next({ FromDate: fromDate, ToDate: toDate, keyword: null, Category: null });
    let filterData = { GFromDate: this._datePipe.transform(fromDate, 'yyyy-MM-dd'), GToDate: this._datePipe.transform(toDate, 'yyyy-MM-dd') }
    this._analyticsService.GetAnalyticsServiceLevel(filterData).subscribe(
      analyticsServiceData => {
        this.ServiceLevelData = analyticsServiceData['Data'];
        this.pieChart(analyticsServiceData['Data']);
      }
    )
    this._analyticsService.GetPayablesDashboard(filterData).subscribe(
      payablesData => {
        this.PayableData = payablesData['Data'];
        this.TreeMap(payablesData['Data']);
        console.log(payablesData['Data']);
      }
    )
    this._analyticsService.GetAnalyticsOrders(filterData).subscribe(
      ordersData => {

        this.TotalOrders = ordersData['Data']['TotalOrders'];
        this.TotalFreight = ordersData['Data']['Freight'];
        this.Provisional = ordersData['Data']['Provisional'];
        // this.Confirmed = ordersData['Data']['Confirmed'];
        this.InTransit = ordersData['Data']['InTransit']
        this.arrived=ordersData['Data']['arrived']
        this.Picked = ordersData['Data']['Picked'];
        this.Cancelled = ordersData['Data']['Cancelled'];
        this.OrdersData = ordersData['Data'];



        this.card_data = [
          // {
          
          //   name: "Sales",
          //   value: this.OrdersData['Freight']
          // },
          {

          name: "Total",
          value: this.OrdersData['TotalOrders']
        },
        {
          name: "Provisional",
          value: this.OrdersData['Provisional']
        },

        {
          name: "Intransit",
          value: this.OrdersData['InTransit']
        },
    
        // {
        //   name: "Confimed",
        //   value: this.OrdersData['Confirmed']
        // },
        // {
        //   name: "Picked",
        //   value: this.OrdersData['Picked']
        // },
        {
          name: "Completed",
          value: this.OrdersData['Complete']
        },
        {
          name: "To Be Placed",
          value: this.OrdersData['ToBePlaced']
        },
        {
          name: "Cancelled",
          value: this.OrdersData['Cancelled']
        }
        ];
      }
    )
    this._analyticsService.GetAnalyticsTrips(filterData).subscribe(
      tripsData => {
        this.TripsData = tripsData['Data'];
        this.BarChart(tripsData['Data']);

      }
    )
    this.Spinner=false;
  }
  
  reset() {
    this.fromDate = null;
    this.toDate = null;
    this._appSettings.GlobalFilter.next({ FromDate: null, ToDate: null, keyword: null, Category: null });
  }

  addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  applyFilter(displayName: string, isInitial = false) {
    this.Spinner = true;
    this.activeFilterOption = displayName;
    this.fromDate = null;
    this.toDate = null;
    if (displayName == 'Custom Range') {
      this.isDateVisible = true;
    }
    else {
      this.isDateVisible = false;
      if (displayName == 'Weekly') {
        this.fromDate = moment().startOf('week').toDate();
        this.toDate = moment().endOf('week').toDate();
      }
      else if (displayName == 'Fortnightly') {
        var today = new Date();
        let currentDay = parseInt(String(today.getDate()).padStart(2, '0'));
        if (currentDay > 15) {
          this.fromDate = moment().startOf('month').toDate();
          this.fromDate = this.addDays(this.fromDate, 15);
          this.toDate = moment().endOf('month').toDate();
        }
        else {
          this.fromDate = moment().startOf('month').toDate();
          //   this.toDate = this.fromDate.getDate() + 15;
          this.toDate = this.addDays(this.fromDate, 14);
        }
      }
      else if (displayName == 'MTD') {
        this.fromDate = moment().startOf('month').toDate();
        this.toDate = moment().endOf('month').toDate();
      }
      else if (displayName == 'QTD') {
        this.fromDate = moment().startOf('quarter').toDate();
        this.toDate = moment().endOf('quarter').toDate();
      }
      else if (displayName == 'YTD') {
        let temp = new Date().getMonth() + 1;
        if (temp > 3) {
          let year1 = new Date().getFullYear();
          let year2 = year1 + 1;
          let aprildate = "04" + "-" + "01" + "-" + year1;
          let marchdate = "03" + "-" + "31" + "-" + year2;
          this.fromDate = new Date(aprildate);
          this.toDate = new Date(marchdate);
        }
        else {
          let year1 = new Date().getFullYear();
          let year2 = year1 - 1;
          let aprildate = "04" + "-" + "01" + "-" + year2;
          let marchdate = "03" + "-" + "31" + "-" + year1;
          this.fromDate = new Date(aprildate);
          this.toDate = new Date(marchdate);

        }
      }
      this._appSettings.RecievablesGlobalFilter.next({ FromDate: this.fromDate, ToDate: this.toDate, keyword: null, Category: null });
      if (isInitial == false) {
        this._appSettings.RecievablesGlobalFilter.pipe(take(1)).subscribe(
          data => {
            let filterData = { GFromDate: this._datePipe.transform(data.FromDate, 'yyyy-MM-dd'), GToDate: this._datePipe.transform(data.ToDate, 'yyyy-MM-dd') }
            this._analyticsService.GetAnalyticsServiceLevel(filterData).subscribe(
              analyticsServiceData => {
                this.ServiceLevelData = analyticsServiceData['Data'];
                this.pieChart(analyticsServiceData['Data']);

              }
            )
            this._analyticsService.GetPayablesDashboard(filterData).subscribe(
              payablesData => {
                this.PayableData = payablesData['Data'];
                this.TreeMap(payablesData['Data']);



              }
            )

            this._analyticsService.GetAnalyticsPerformance(filterData).subscribe(
              PerformanceData => {
                this.PerformanceData = PerformanceData['Data'];
                this.BarChart1(PerformanceData['Data']);

                // console.log(this.PerformanceData);
              }

            )
            this._analyticsService.GetAnalyticsOrders(filterData).subscribe(
              ordersData => {

                this.TotalOrders = ordersData['Data']['TotalOrders'];
                //this.Confirmed = ordersData['Data']['Confirmed'];
                this.Provisional = ordersData['Data']['Provisional'];
                this.InTransit = ordersData['Data']['InTransit'];
                this.Complete = ordersData['Data']['Complete'];
                this.arrived=ordersData['Data']['arrived']
                // this.Picked = ordersData['Data']['Picked'];
                this.TotalFreight= ordersData['Data']['Freight']
                this.Cancelled = ordersData['Data']['Cancelled'];
                this.ToBePlaced = ordersData['Data']['ToBePlaced']
                this.OrdersData = ordersData['Data'];

                this.card_data = [
                  // {
                  
                  //   name: "Sales",
                  //   value: this.OrdersData['Freight']
                  // },
                  {
                  name: "Total",
                  value: this.OrdersData['TotalOrders']
                },
                {
                  name: "Provisional",
                  value: this.OrdersData['Provisional']
                },

                {
                  name: "Intransit",
                  value: this.OrdersData['InTransit']
                },
              
                // {
                //   name: "Confimed",
                //   value: this.OrdersData['Confirmed']
                // },
                // {
                //   name: "Picked",
                //   value: this.OrdersData['Picked']
                // },
                {
                  name: "Completed",
                  value: this.OrdersData['Complete']
                },
                {
                  name:"Unloading",
                  value:this.OrdersData['arrived']
                },

                {
                  name: "To Be Placed",
                  value: this.OrdersData['ToBePlaced']
                },
                {
                  name: "Cancelled",
                  value: this.OrdersData['Cancelled']
                }
                ];
              }
            )
            this._analyticsService.GetAnalyticsTrips(filterData).subscribe(
              tripsData => {
                this.TripsData = tripsData['Data'];
                this.BarChart(tripsData['Data']);
              }
            )
          }
        )
      }
    }
    this.Spinner=false;
  }

  pieChart(values) {
    if (values.length > 0) {
      this.Service_chart = [];
      values.forEach(element => {
        this.Service_chart.push(
          {
            "name": "POD",
            "value": element.POD
          },
          {
            "name": "OTA",
            "value": element.OTA
          },
          {
            "name": "OTP",
            "value": element.OTP
          });
      });
    }
  }
  // numberCard(values){
  //   if(values.length > 0){
  //     this.card_data =[];
  //      values.forEach(element => {
  //       this.card_data.push(

  //         {
  //           "name" : "Total",
  //           "value" : element.TotalOrders
  //         },
  //         {
  //           "name" : "Provisional",
  //           "value" : element.Provisional
  //         },
  //         {
  //           "name" : "Confimed",
  //           "value" : element.Confirmed
  //         },
  //         {
  //           "name" : "Picked",
  //           "value" : element.Picked
  //         },
  //         {
  //           "name" : "Cancelled",
  //           "value" : element.Cancelled
  //         }
  //       )
  //      })
  //   }
  // }


  TreeMap(values) {
    if (values.length > 0) {
      this.payable_chart = [];
      values.forEach(element => {
        this.payable_chart.push({ name: element.Category, value: element.TotalAmount ? element.TotalAmount : 0 });
      });
    }
  }


  BarChart(values) {
    if (values.length > 0) {
      this.barchart_trips = [];
      values.forEach(element => {
        this.barchart_trips.push(
          {
            "name": "Open Trips",
            "series": [
              {
                "name": "On time",
                "value": element.Open_ontime
              },
              {
                "name": "Delayed",
                "value": element.Open_delay
              }
            ]
          },
          {
            "name": "Closed Trips",
            "series": [
              {
                "name": "on time",
                "value": element.Completed_ontime
              },
              {
                "name": "delayed",
                "value": element.Completed_delay
              }
            ]
          }
        )
      });
    }
  }
  BarChart1(values) {
    if (values.length > 0) {
      this.barchart_performance = [];
      values.forEach(element => {
        this.barchart_performance.push(
          {
            "name": "Total Orders",
            "series": [
              {
                "name": "On time",
                //"value": element.Completed_ontime
                "value": ((element.Completed_ontime) / (element.Completed_ontime + element.Completed_delay)) * 100,
              },
              {
                "name": "Delayed",
                "value": ((element.Completed_delay) / (element.Completed_ontime + element.Completed_delay)) * 100,
              }
            ]
          },
          {
            "name": "Placement",
            "series": [
              {
                "name": "On time",
                // "value": element.Placement_ontime
                "value": ((element.Placement_ontime) / (element.Placement_ontime + element.Placement_delay)) * 100,
              },
              {
                "name": "Delayed",
                "value": ((element.Placement_delay) / (element.Placement_ontime + element.Placement_delay)) * 100,
              }
            ]
          }
        )
      });
    }
  }
  TreeMap2(values) {
    if (values.length > 0) {
      this.payable_chart = [];
      values.forEach(element => {
        this.payable_chart.push({ name: element.Placement_ontime, value: element.TotalAmount ? element.TotalAmount : 0 });
      });
    }
  }
}
