<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
    <h3 mat-dialog-title>Manage Table Grid </h3>
</div>

<div class="row" mat-dialog-content>
    <div class="col-sm-12">
        <div class="row">
            <div *ngFor="let item of headers; let i = index" class="col-sm-4" style="margin-bottom: 10px; padding: 0px;">
                <mat-checkbox style="font-weight: bold;"  [checked]="item.IsSelected" [(ngModel)]="item.IsSelected">{{item.Name}}</mat-checkbox>
            </div>
        </div>
        
       
    </div>
</div>

<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
    <button class="btn btn-primary fa fa-refresh" (click)="save()"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Update</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
</mat-dialog-actions>