import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapDialogComponent } from './map-dialog/map-dialog.component';
import { AgmCoreModule } from '@agm/core';
import { ShareCsvDialogComponent } from './share-csv-dialog/share-csv-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageGridDialogComponent } from './manage-grid-dialog/manage-grid-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { OrdersSummaryComponent } from './orders-summary/orders-summary.component';
import { XpcnXplsListDialogComponent } from './xpcn-xpls-list-dialog/xpcn-xpls-list-dialog.component';
import { XpcnPreviewDialogComponent } from './xpcn-preview-dialog/xpcn-preview-dialog.component';
import { XplsPreviewDialogComponent } from './xpls-preview-dialog/xpls-preview-dialog.component';
import { UploadcsvdialogComponent } from './uploadcsvdialog/uploadcsvdialog.component';
import {MatIconModule} from '@angular/material/icon';
import { SharedXpcnXplsListPerformanceComponent } from './shared-xpcn-xpls-list-performance/shared-xpcn-xpls-list-performance.component';
import { OrderXpcnDialogComponent } from './order-xpcn-dialog/order-xpcn-dialog.component';
import { OrderXplsDialogComponent } from './order-xpcn-dialog/order-xpls-dialog/order-xpls-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { AddEwaybillDialogComponent } from './add-ewaybill-dialog/add-ewaybill-dialog.component';
import { AddInvoiceDialogComponent } from './add-invoice-dialog/add-invoice-dialog.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AddNewXPCNComponent } from './add-new-xpcn/add-new-xpcn.component';



@NgModule({
  declarations: [
    MapDialogComponent,
    ShareCsvDialogComponent,
    ManageGridDialogComponent,
    OrdersSummaryComponent,
    XpcnXplsListDialogComponent,
    XpcnPreviewDialogComponent,
    XplsPreviewDialogComponent,
    UploadcsvdialogComponent,
    SharedXpcnXplsListPerformanceComponent,
    OrderXpcnDialogComponent,
    OrderXplsDialogComponent,
    AddEwaybillDialogComponent,
    AddInvoiceDialogComponent,
    AddNewXPCNComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,MatIconModule,
    AgmCoreModule,MatDialogModule,MatFormFieldModule,FormsModule,ReactiveFormsModule,MatCheckboxModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
  ]
})
export class SharedModule { }
