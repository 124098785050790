<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
    <h3 mat-dialog-title>Share CSV</h3>
</div>
<div>
    <form novalidate [formGroup]="addEditForm">
        <div class="row form-group m-0 p-0">
            <div class="col-sm-3 form-group required dj">
                 Email ID:</div>
            <div class="col-sm-9 form-group">
                <input type="text" class="form-control" formControlName="EmailIds" (keypress)="emailsWithCome($event)" placeholder="Enter Email ID...">
                <div *ngIf="!addEditForm.controls.EmailIds.valid && (addEditForm.controls.EmailIds.dirty || addEditForm.controls.EmailIds.touched)" class="error-text">
                    <div *ngIf="addEditForm.controls.EmailIds.errors.required" style="color: red ;">
                      Email Id is required
                    </div>
                </div>
            </div>
        </div>
       
        <div class="row form-group m-0 p-0">
            <div class="col-sm-3 form-group required dj">
                 Subject:</div>
            <div class="col-sm-9 form-group">
                <input type="text" class="form-control" formControlName="Subject" (keypress)="alphaNumericWithSpace($event)" placeholder="Enter Subject...">
                <div *ngIf="!addEditForm.controls.Subject.valid && (addEditForm.controls.Subject.dirty || addEditForm.controls.Subject.touched)" class="error-text">
                    <div *ngIf="addEditForm.controls.Subject.errors.required" style="color: red ;">
                      Subject is required
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0 p-0 form-group">
            <div class="col-sm-3 form-group dj">
                 Message:</div>
                 <div class="col-sm-9 form-group">
                    <textarea class="form-control" formControlName="Body" placeholder="Message" rows="3"></textarea>
                    <div *ngIf="!addEditForm.controls.Body.valid && (addEditForm.controls.Body.dirty || addEditForm.controls.Body.touched)" class="error-text">
                        <div *ngIf="addEditForm.controls.Body.errors.required" style="color: red ;">
                          Message is required
                        </div>
                    </div>
                </div>
        </div>
    </form>
</div>

<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
    <button class="btn btn-primary fa fa-paper-plane" (click)="send()"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Send</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
</mat-dialog-actions>
