<div class="col-xl-4 mb-3">
    <a routerLinkActive="active" type="button" class="btn filterbtn w-40 fa fa-filter"
        (click)="filterToggle = !filterToggle">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Filter</span></a>
</div>

<div class="card border-0 box-shadow" style="margin-top:10px; margin-bottom: 15px;" *ngIf="filterToggle">
    <div class="col-xs-12 mt-3 ml-4 mb-3">
        <div>
            <button type="button" class="btn btn-dark filterbuttons2 mgnfilterbesidebuttons ml-3"
                *ngFor="let item of GlobalFilterOptions" (click)="applyFilter(item.DisplayName)"
                [ngClass]="{'btn-active': item.DisplayName == activeFilterOption}">
                {{item.DisplayName}}
            </button>
        </div>

        <div class="col-xs-12 mb-0 d-flex justify-content-right ml-3 mt-3 p-0 width" *ngIf="isDateVisible">
            <div class="d-flex justify-content-right ml-0 p-0" style="margin-bottom: -6px;
            margin-top: 5px;">
                <div>
                    <label style="margin-top: 5px; color: black; font-size: 15px;">From:</label>
                </div>
                <div class="ml-4">
                    <input type="date" class="form-control" [(ngModel)]="fromDate">
                </div>
                <div class="ml-5">
                    <label style="margin-top: 5px; color: black; font-size: 15px;">To:</label>
                </div>
                <div class="ml-4">
                    <input type="date" class="form-control" [(ngModel)]="toDate">
                </div>
                <div class="col-xl-1 col-lg-3 col-sm-6 col-12 mb-f2">
                    <button class="btn btn-primary w-70" style="height:32px;" (click)="setDates()"> Search</button>
                </div>
                <div class="col-xl-1 col-sm-6 col-12">
                    <button class="btn btn-danger w-70 ml-4" style="height: 32px ;" (click)="reset()"> Clear</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row ">
    <div class="col-md-6  mb-0" #containerRef style="padding-right: 6px;">
        <div widget class="card border-0 box-shadow" style="height: 100%;">
            <div class="card-header transparent border-0 text-muted">
                <div class="row col-sm-6">
                    <!-- <h3 style="width:100px" class="mt-3">Orders</h3> -->
                    <div class="col-sm-4 mt-3" style="font-size: 22px;">Orders</div>
                    <div class="col-sm-8">
                        <button disabled class="btn btn-primary mt-3">
                            Sales({{TotalFreight|currency:'INR'}})
                        </button>
                    </div>
                </div>

            </div>
            <!-- <div class="row col-sm-12 card-header transparent border-0 text-muted">
                <div class="col-sm-2">
                    <h3 class="mt-3">Orders</h3>
                </div>
                <div class="'row col-sm-2 w-0">
                    <button disabled class="btn btn-primary mt-3"> Sales({{TotalFreight|currency:'INR'}})</button>
                </div>
            </div> -->
            <ngx-charts-number-card [scheme]="colorScheme2" [results]="card_data" [cardColor]="cardColor"
                [textColor]="textColor" [view]="[650, 300]">
            </ngx-charts-number-card>
        </div>
    </div>

    <div class="col-md-6" style="margin-top:60px ;">
        <div class=" transparent border-0 text-muted" style="text-align: center; margin-bottom: 1%;">
            <h3 class="ml-4">Sales</h3>
        </div>

        <table class="table">
            <thead>
                <tr class="text-center" style="background-color: #4b519f; color: white;">
                    <th>Sales</th>
                    <th>Total Orders</th>
                    <th>Provisional</th>
                    <th>To Be Placed</th>
                    <th>Intransit</th>
                    <!-- <th>Confirmed</th> -->
                    <th>Completed</th>
                    <th>Waiting For Unloading</th>
                    <!-- <th>Picked</th> -->
                    <th>Cancelled</th>
                </tr>
            </thead>
            <tbody>
                <tr class="text-center">

                    <td>{{TotalFreight | currency:'INR'}}</td>
                    <td>{{TotalOrders}}</td>
                    <td>{{Provisional}}</td>
                    <td>{{ToBePlaced}}</td>
                    <td>{{InTransit}}</td>
                    <td>{{Complete}}</td>
                    <td>{{arrived}}</td>
                    <!-- <td>{{Confirmed}}</td> -->
                    <!-- <td>{{Picked}}</td> -->
                    <td>{{Cancelled}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<div class="row mt-3 p-0">
    <div class="col-6 mb-0" style="padding-right: 6px;">
        <div widget class="card border-0 box-shadow">
            <div class="card-header transparent border-0 text-muted" style="margin-left: 41%;">
                <h3 class="mb-0">Payables</h3>
            </div>

            <div class="card-body pt-0 pl-2 pr-2 pb-2 widget-body">
                <div class="w-100 h-300p payables">
                    <ngx-charts-tree-map [scheme]="colorScheme" [gradient]="gradient" [animations]="animations"
                        [results]="payable_chart">

                    </ngx-charts-tree-map>
                </div>
            </div>
        </div>
    </div>

    <div class="col-6 paid-table" style="margin-top:80px ;">
        <div class=" transparent border-0 text-muted" style="text-align: center;">
            <h3 class="mb-2">Payables</h3>
        </div>

        <table class="table">
            <thead>
                <tr class="text-center" style="background-color: #4b519f; color: white;">
                    <th *ngFor="let item of PayableData">{{item.Category}}</th>
            </thead>
            <tbody>
                <tr class="text-center">
                    <td *ngFor="let item of PayableData">
                        {{item.TotalAmount ? (item.TotalAmount | currency:'INR') : ('0.00' | currency:'INR')}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="row">
    <div class="col-6 mt-4" style="padding-right: 6px;">
        <div widget class="card border-0 box-shadow" style="height: 100%;">
            <div class="card-header transparent border-0 text-muted text-center" style="margin-left:65px">
                <h3 class="mb-0">Performance </h3>
            </div>
            <div class="card-body pt-0 pl-2 pr-2 pb-2 widget-body">
                <div class="w-100 h-300p payables">
                    <ngx-charts-bar-horizontal-normalized [scheme]="colorScheme" [results]="barchart_performance"
                        [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="true" legendTitle=" "
                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel">
                    </ngx-charts-bar-horizontal-normalized>
                </div>

            </div>
        </div>
    </div>

    <div class="col-6 paid-table" style="margin-top:80px ;">
        <div class=" transparent border-0 text-muted" style="text-align: center;">
            <h3 class="mb-2 text">Performance</h3>
        </div>

        <table class="table">
            <thead>
                <tr class=" text-center" style="background-color: #4b519f; color: white;">
                    <th colspan="3">Total Orders</th>
                    <th colspan="3">Placement</th>
                </tr>
            </thead>
            <thead>
                <tr class=" text-center" style="background-color: #5c61a6; color: white;">
                    <th>Total</th>
                    <th>On Time</th>
                    <th>Delayed</th>
                    <th>Total</th>
                    <th>On Time</th>
                    <th>Delayed</th>
                </tr>
            </thead>
            <tbody>
                <tr class=" text-center" *ngFor="let item of PerformanceData">
                    <td>{{item.total_order}}</td>
                    <td>{{item.Completed_ontime}}</td>
                    <td>{{item.Completed_delay}}</td>
                    <td>{{item.total_order}}</td>
                    <td>{{item.Placement_ontime}}</td>
                    <td>{{item.Placement_delay}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="row">
    <div class="col-6 mt-4" style="padding-right: 6px;">
        <div widget class="card border-0 box-shadow" style="height: 94%;">
            <div class="card-header transparent border-0 text-muted " style="margin-left: 33%;">
                <h3 class="mb-0">Service Level (%)</h3>
            </div>
            <div class="custom-control custom-checkbox custom-control-inline" style="margin-left:81% ;">
                <input class="custom-control-input checkbox-dark-gray">

            </div>
            <div class="card-body widget-body">
                <div class="w-70 h-300p">
                    <ngx-charts-bar-vertical [scheme]="colorScheme" [gradient]="gradient" [xAxis]="showXAxis"
                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                        [showDataLabel]="true" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                        [results]="Service_chart">
                    </ngx-charts-bar-vertical>
                </div>
            </div>

            <!-- <div class="w-100 h-300p">
                    <ngx-charts-pie-chart [legend]="showLegend" [legendTitle]=legendTitle [scheme]="colorScheme1"
                        [results]="Service_chart" [gradient]="gradient1" [legendPosition]="legendPosition"
                        [labels]="showLabels" [animations]="animations" [explodeSlices]="explodeSlices">
                    </ngx-charts-pie-chart>
                </div> -->

        </div>
    </div>

    <div class="col-6 paid-table" style="margin-top:80px ;">
        <div class=" transparent border-0 text-muted" style="text-align: center;">
            <h3 class="mb-2">Service Level</h3>
        </div>

        <table class="table">
            <thead>
                <tr class="text-center" style="background-color:#4b519f; color: white;">
                    <th>OTP</th>
                    <th>OTA</th>
                    <th>POD</th>
                    <th>Service Level</th>
                </tr>
            </thead>
            <tbody>
                <tr class="text-center" *ngFor="let item of ServiceLevelData">
                    <td>{{item.OTP}}</td>
                    <td>{{item.OTA}}</td>
                    <td>{{item.POD}}</td>
                    <td>{{item.service_level}}%</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="row">
    <div class="col-6 mt-4" style="padding-right: 6px;">
        <div widget class="card border-0 box-shadow" style="height: 100%;">
            <div class="card-header transparent border-0 text-muted " style="margin-left: 42%;">
                <h3 class="mb-0">Trips</h3>
            </div>
            <div class="card-body ml-4 widget-body">
                <ngx-charts-bar-vertical-2d [results]="barchart_trips" [view]="view1" [scheme]="colorScheme4"
                    [schemeType]="schemeType" [gradient]="gradient2" [animations]="animations2" [legend]="showLegend1"
                    [legendTitle]="legendTitle1" [legendPosition]="legendPosition1" [xAxis]="showXAxis"
                    [yAxis]="showYAxis" [showGridLines]="showGridLines" [showDataLabel]="true">
                </ngx-charts-bar-vertical-2d>
            </div>
        </div>
    </div>

    <div class="col-6 paid-table" style="margin-top:80px ;">
        <div class=" transparent border-0 text-muted" style="text-align: center;">
            <h3 class="mb-2 text">Trips</h3>
        </div>

        <table class="table">
            <thead>
                <tr class=" text-center" style="background-color: #4b519f; color: white;">
                    <th colspan="3">Open Trips</th>
                    <th colspan="3">Completed Trips</th>
                </tr>
            </thead>
            <thead>
                <tr class=" text-center" style="background-color: #5c61a6; color: white;">
                    <th>Total</th>
                    <th>On Time</th>
                    <th>Delayed</th>
                    <th>Total</th>
                    <th>On Time</th>
                    <th>Delayed</th>
                </tr>
            </thead>
            <tbody>
                <tr class=" text-center" *ngFor="let item of TripsData">
                    <td>{{item.Open}}</td>
                    <td>{{item.Open_ontime}}</td>
                    <td>{{item.Open_delay}}</td>
                    <td>{{item.Completed}}</td>
                    <td>{{item.Completed_ontime}}</td>
                    <td>{{item.Completed_delay}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="d-flex justify-content-center" *ngIf="Spinner">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>