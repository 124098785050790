

export class TextValidator {
    static onlyAlphabets(event) {
        let k;
        k = event.charCode;
        return ((k > 64 && k < 91) || (k > 96 && k < 123));
      }
      static onlyAlphabetsWithSpace(event) {
          let k;
          k = event.charCode;
          return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 32);
        }
      static onlyNumbers(event) {
          let k;
          k = event.charCode;
          return (k > 47 && k < 58);
        }

        static phoneNumbers(event) {
            let k;
            k = event.charCode;
            if ((k > 47 && k < 58) || k == 32 || k == 43 || k == 45) {
              return true
            } else {
              return false;
            }
          }
      static alphaNumeric(event) {
        let k;
        k = event.charCode;
        return ((k > 47 && k < 58) || (k > 64 && k < 91) || (k > 96 && k < 123));
      }
      static alphaNumericWithSpace(event) {
        let k;
        k = event.charCode;
        return ((k > 47 && k < 58) || (k > 64 && k < 91) || (k > 96 && k < 123) || k == 32);
      }
      static emailsWithCome(event) {
        let k;
        k = event.charCode;
        return ((k > 47 && k < 58) || (k > 64 && k < 91) || (k > 96 && k < 123) || k == 64 || k == 44 || k == 46 || k == 95);
      }
      static onlyNumbersWithDecimal(event) {
        const txt = event.target.value;
        const dotcontainer = txt.split('.');
        const decimals = txt.split('.')[1];
        const charCode = event.charCode;
        if ((charCode > 47 && charCode < 58) || charCode == 46) {
          if (decimals !== null && decimals !== undefined) {
            if (!(dotcontainer.length === 1 && charCode === 46) && (charCode < 48 || charCode > 57)
              || decimals.length >= 2) {
              return false;
            }
          } else {
            if (txt.length >= 2 && charCode !== 46) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      }
      static percentageWithDecimal(event) {
        const txt = event.target.value;
        const dotcontainer = txt.split('.');
        const decimals = txt.split('.')[1];
        const charCode = event.charCode;
        if ((charCode > 47 && charCode < 58) || charCode == 46) {
          if (decimals !== null && decimals !== undefined) {
            if (!(dotcontainer.length === 1 && charCode === 46) && (charCode < 48 || charCode > 57)
              || decimals.length >= 2) {
              return false;
            }
          } else {
            if (txt.length >= 3 && charCode !== 46) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      }
      static priceValidator(event) {
        const txt = event.target.value;
        const dotcontainer = txt.split('.');
        const decimals = txt.split('.')[1];
        const charCode = event.charCode;
        if ((charCode > 47 && charCode < 58) || charCode == 46) {
          if (decimals !== null && decimals !== undefined) {
            if (!(dotcontainer.length === 1 && charCode === 46) && (charCode < 48 || charCode > 57)
              || decimals.length >= 2) {
              return false;
            }
          } else {
            if (txt.length >= 9 && charCode !== 46) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      }

      static latLong(event) {
        const txt = event.target.value;
        const dotcontainer = txt.split('.');
        const decimals = txt.split('.')[1];
        const charCode = event.charCode;
        if ((charCode > 47 && charCode < 58) || charCode == 46) {
          if (decimals !== null && decimals !== undefined) {
            if (!(dotcontainer.length === 1 && charCode === 46) && (charCode < 48 || charCode > 57)
              || decimals.length >= 15) {
              return false;
            }
          } else {
            if (txt.length >= 2 && charCode !== 46) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      }

      static validateSetting(unit, event) {
        let k;
        let value = event.target.value;
        let length = event.target.value.length;
        k = event.charCode;
        if(unit == 'Days' || unit == 'Hours') {
          return ((k > 47 && k < 58) && length < 3);
        } else if(unit == 'Kms') {
          return ((k > 47 && k < 58) && length < 3);
        } else if(unit == 'Date') {
          return ((k > 47 && k < 58) && length < 2 && Number(value) < 31);
        } else if(unit == 'Amount') {
            return this.priceValidator(event);
          // return ((k > 47 && k < 58) && length < 6);
        } else if(unit == 'Percentage') {
          return this.percentageWithDecimal(event);
          // return ((k > 47 && k < 58) || k == 44);
        } else if(unit == 'Time') {
          return ((k > 47 && k < 58) || k == 44);
        } else if(unit == '-') {
          return (((k > 47 && k < 58) || (k > 64 && k < 91) || (k > 96 && k < 123)) && length < 6);
        } else if(unit == 'Email') {
          return ((k > 47 && k < 58) || (k > 64 && k < 91) || (k > 96 && k < 123) || k == 64 || k == 44 || k == 46 || k == 95);
        } else if(unit == 'Mobile Number') {
          return ((k > 47 && k < 58) 
          // || k == 44
          && length < 10
          );
        } else if(unit == 'Timestamps') {
          return ((k > 47 && k < 58) || k == 44 ||k==58);
        }
      }
}

