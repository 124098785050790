import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  headers: { headers: any; };
  constructor(private _http: HttpClient) { }
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  getOrderSummary(orderId){
    return this._http.get(environment.apiUrl+"api/get-order-summary?orderId=" + orderId);
  }

  // to get xpcn and xpls list
  getXPCNXPLSList(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-xpcn-xpls-list?orderId=' + orderId);
  }

  // to get finalize xpcn
  getFinalizeXPCN(xpcn) {
    return this._http.post(environment.apiUrl + 'api/get-finalize-xpcn', xpcn);
  }

   // to get finalize xpls 
   getFinalizeXPLS(xpls) {
    return this._http.post(environment.apiUrl + 'api/get-finalize-xpls', xpls);
  }
    // to upload price master csv 
    uploadPriceMasterCSV(data) {
      return this._http.post(environment.apiUrl + 'api/upload-price-master-csv', data);
    }
    
  // to upload diesel master csv 
  uploadDieselMasterCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-diesel-master-csv', data);
  }
    // to upload ffv driver csv
    uploadFFvDriverCSV(data) {
      return this._http.post(environment.apiUrl + 'api/upload-ffv-driver-as-csv', data);
    }
  // to upload dynamic diesel master csv 
  uploadDynamicDieselMasterCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-dynamic-diesel-master-csv', data);
  }
  // to upload post code csv 
  uploadPostCodeCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-post-code-csv', data);
  }
  uploadDynamicCapacity(data) {
    return this._http.post(environment.apiUrl + 'api/upload-capacity-as-csv', data);
  }
  // to upload ffv vehicle csv 
  uploadFFVVehicleCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-ffv-Vehicle-as-csv', data);
    // return this._http.post(environment.apiUrl + 'api/upload-ffv-vehicle-csv', data);
  }
  uploadMarkAttendance(data) {
    return this._http.post(environment.apiUrl + 'api/upload-markattendance-as-csv', data);
  }
  uploadPayableOthers(data) {
    return this._http.post(environment.apiUrl + 'api/upload-payable-others-as-csv', data);
  }

 // to upload input others gst working
 uploadInputOthers(data) {
  return this._http.post(environment.apiUrl + 'api/upload-input-others-as-csv', data);
}
  // to get list of Prices from price master
  updateCustomerAdhocPricing(data) {
    return this._http.post(environment.apiUrl + 'api/update-customer-adhoc-pricing', data);
  } 

  // to upload quotation csv 
  uploadQuotationCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-quotation-csv', data);
  }
  uploadMISPOCsCsv(data) {
    return this._http.post(environment.apiUrl + 'api/custmr/upload-mis-poc-csv', data);
    // return this._http.post(environment.apiUrl + 'api/upload-ffv-vehicle-csv', data);
  }
  uploadFclRatesCsv(data) {
    return this._http.post(environment.apiUrl + 'api/upload-fcl-rates-as-csv', data);
    // return this._http.post(environment.apiUrl + 'api/upload-ffv-vehicle-csv', data);
  }
// to upload adhoc pricing customer csv 
uploadCustomerAdhocPriceingCSV(data) {
  return this._http.post(environment.apiUrl + 'api/upload-customer-adhoc-pricing-csv', data);
}

// to upload invoice details xpcn csv 
uploadCustomerInvoiceDetailsCSV(data) {
  return this._http.post(environment.apiUrl + 'api/upload-xpcn-invoice-csv', data);
}
   // to upload eway bills xpcn csv 
   uploadCustomerEwayBillDetailsCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-xpcn-Eway-bill-csv', data);
   }
  // to upload book new order csv 
  uploadBookNewOrderCSV(data) {
    return this._http.post(environment.apiUrl + 'api/upload-book-new-order-csv', data);
  }
}
