import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadExcelService } from 'src/app/services/download-excel.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TextValidator } from '../../customers/text_validator';
import { OrderService } from '../../orders/order-dashboard/order.service';

@Component({
  selector: 'app-share-csv-dialog',
  templateUrl: './share-csv-dialog.component.html',
  styleUrls: ['./share-csv-dialog.component.scss']
})
export class ShareCsvDialogComponent implements OnInit {
  excelData = [];
  formData: FormData = new FormData();
  isSubmitting = false;
  addEditForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<ShareCsvDialogComponent>,
    private _fb: FormBuilder,
    private alertService: AlertService,
    private _datePipe: DatePipe, private _OrderService: OrderService,
    public _downloadExcelService: DownloadExcelService,
    @Inject(MAT_DIALOG_DATA) private _data: any) {
      this.addEditForm = this._fb.group({
        FileName: new FormControl(''),
        EmailIds: new FormControl('', Validators.compose([Validators.required])),
        Subject: new FormControl('', Validators.compose([Validators.required])),
        Body: new FormControl('')
      });
     }

  ngOnInit(): void {
    this.addEditForm.controls['FileName'].setValue(this._data['fileName']);
  }
  close(): void {
    this.dialogRef.close();
  }

  send() {
    if (this.addEditForm.valid) {
      let emailsValid = true;
      let emails = this.addEditForm.controls['EmailIds'].value.split(',');
      // let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"; 
      let emailPattern = "[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"; 
      emails.forEach(x => {
        if(!x.match(emailPattern)) {
          emailsValid = false;
        }
      });
      if(emailsValid) {
    this.formData.append('123', this._data['blob']);
    this.formData.delete('FormData');
    this.formData.append('FormData', JSON.stringify(this.addEditForm.value));
      this._OrderService.sendEmailsWithCSV(this.formData).subscribe(data => {
        if(data != null && data['Success']){
          this.dialogRef.close();
        }
      });
      } else {
        this.alertService.createAlert('Invalid Email Id(s)', 0);
      }
    }else {
      this.addEditForm.controls['EmailIds'].markAsTouched();
      this.addEditForm.controls['Subject'].markAsTouched();
      this.addEditForm.controls['Body'].markAsTouched();
    }
  }
  
  alphaNumericWithSpace(event) {
    return TextValidator.alphaNumericWithSpace(event);
  }
  emailsWithCome(event) {
    return TextValidator.emailsWithCome(event);
  }
}
