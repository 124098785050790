<div>
    <!-- <h1 class="custom-header" style="font-weight: 400; margin-bottom: 10px !important; margin-left: 32%;"
        mat-dialog-title>
        <i class="fa fa-times" aria-hidden="true"
            style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;"
            (click)="close()"></i> Capture Location
    </h1> -->

    <div class="d-flex justify-content-end NewHeader" style="align-items: center;" >
        <h3 routerLinkActive="active" class="card-title" style="padding-right: 28%;text-decoration: underline;"> Capture Location</h3>
        <i class="fa fa-times" aria-hidden="true" style="cursor: pointer;padding-right: 27px;"
            (click)="close()"></i>
    </div>


    <hr>

    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-10" style="padding-right: 5px;">
                <mat-select placeholder="Enter Location" class="form-control" name="viaPoint"
                    [(ngModel)]="googlePlaceId" [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onGoogleLocationChange('origin', $event)" style="margin-bottom: 15px;">
                    <mat-form-field style="width: 100%;">
                        <input matInput placeholder="&nbsp;&nbsp;Enter Location" (input)="googleLocation($event)"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="googleSearch"
                            matTooltip="{{googleSearch == null || (googleSearch != null && googleSearch.length < 3) ? 'Enter at least 3 letters' : ''}}" />
                    </mat-form-field>
                    <mat-option *ngIf="googleLocations.length == 0" disabled>No Record Found</mat-option>
                    <mat-option *ngFor="let item of googleLocations" [value]="item.PlaceId">{{item.Name}}
                        <small>{{item.Address}}</small></mat-option>
                </mat-select>

            </div>
            <div class="col-sm-2" style=" padding-left: 0px;margin-left: 0px;">

                <button (click)="confirmLocation()" [disabled]="!isConfirmEnabled"
                    class="btn btn-primary">Confirm</button>
            </div>
        </div>


        <div id="map" style="border: 1.1px solid black;">
            <agm-map id="agmMap" [zoom]="16" [latitude]="latitude" [longitude]="longitude"
                (mapClick)="updateMarker('origin', $event.coords.lat, $event.coords.lng)">
                <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
            </agm-map>
        </div>


    </div>
</div>