<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true" (click)="close()"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;cursor: pointer;"></i>
    <h3 mat-dialog-title>Add XPCNs
    </h3>
</div>

<div mat-dialog-content class="p-0 m-0">
    <table id="example3 " class="table table-bordered table-hover p-0 m-0">
        <thead class="tbl-head m-0 p-0">
        </thead>
        <tbody>
            <tr *ngFor="let xpcnDetail of xpcnDetails">
                <td class="text-center">{{xpcnDetail.XPCNCode}}</td>
            </tr>
            <tr *ngIf="xpcnDetails.length == 0">
                <td class="text-center" colspan="100">No Records Found</td>
            </tr>
        </tbody>
    </table>
</div>
<div>
    <a class="custom-button add_btn " (click)="addLCLOrderAndXPCN()">
        Add XPCN
    </a>
</div>