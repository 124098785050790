import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})

export class FinanceComponent implements OnInit {
  
  constructor() { }

  userData: any = [];
  access: boolean = false;

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userSession'));
    if (this.userData['Role'] === "Financial" || this.userData['Administrator'] === true || this.userData['Role'] === "Operations & Financial") {
      this.access = true;
    }
    else {
      this.access = false;
    }

  }

}
