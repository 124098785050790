import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-xpls-preview-dialog',
  templateUrl: './xpls-preview-dialog.component.html',
  styleUrls: ['./xpls-preview-dialog.component.scss']
})
export class XplsPreviewDialogComponent implements OnInit {
  xpls: any;
  invoices = [];
  waybills = [];
  showFreight = false;

  freightMode;
  viaPoints;
  goodsDesc;
  packageType;
  riskType;
  cgst = 0;
  sgst = 0;
  igst = 0;
  invoicesTotal = 0;
  total = 0;
  lspName;
  lspGSTIN;
  ewaybillNo;
  constructor(
    public dialogRef:MatDialogRef<XplsPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if(this.data != null) {
      //console.log(this.data);
     if(this.data['xpls'] != null) {
       this.xpls = this.data['xpls'];
       if(this.xpls.ModeOfFreightId != null && this.xpls.FreightModes != null 
         && this.xpls.FreightModes.length > 0 && this.xpls.FreightModes.filter(x => x.LookupId == this.xpls.ModeOfFreightId).length > 0) {
         this.freightMode = this.xpls.FreightModes.filter(x => x.LookupId == this.xpls.ModeOfFreightId)[0].LookupName;
       }
       this.showFreight =  this.freightMode == 'Paid' || this.freightMode == 'To-Pay';
       // if(this.xpls.GoodsDescId != null && this.xpls.GoodsDescs != null 
       //   && this.xpls.GoodsDescs.length > 0 && this.xpls.GoodsDescs.filter(x => x.LookupId == this.xpls.GoodsDescId).length > 0) {
       //   this.goodsDesc = this.xpls.GoodsDescs.filter(x => x.LookupId == this.xpls.GoodsDescId)[0].LookupName;
       // }
       if(this.xpls.PackageTypeId != null && this.xpls.PackageTypes != null 
         && this.xpls.PackageTypes.length > 0 && this.xpls.PackageTypes.filter(x => x.LookupId == this.xpls.PackageTypeId).length > 0) {
         this.packageType = this.xpls.PackageTypes.filter(x => x.LookupId == this.xpls.PackageTypeId)[0].LookupName;
       }
       if(this.xpls.RiskTypeId != null && this.xpls.RiskTypes != null 
         && this.xpls.RiskTypes.length > 0 && this.xpls.RiskTypes.filter(x => x.LookupId == this.xpls.RiskTypeId).length > 0) {
         this.riskType = this.xpls.RiskTypes.filter(x => x.LookupId == this.xpls.RiskTypeId)[0].LookupName;
       }
       if(this.xpls.Via1 != null && this.xpls.Via2 == null) {
         this.viaPoints = this.xpls.Via1;
       } 
       else if(this.xpls.Via1 != null && this.xpls.Via2 != null) {
         this.viaPoints = this.xpls.Via1 + ', ' + this.xpls.Via2;
       }
 
       if(this.xpls.Rate != null) {
         this.total = this.total + this.xpls.Rate;
       }
       if(this.xpls.HandlingCharges != null) {
         this.total = this.total + this.xpls.HandlingCharges;
       }
       if(this.xpls.HaltingCharges != null) {
         this.total = this.total + this.xpls.HaltingCharges;
       }
       if(this.xpls.OtherCharges != null) {
         this.total = this.total + this.xpls.OtherCharges;
       }
 
       if(this.xpls.GST != null) {
         this.total = this.total + this.xpls.GST;
       if(this.xpls.BillingOfficeType == 'Central') {
         this.igst = this.xpls.GST;
       } else {
         this.cgst = this.xpls.GST / 2;
         this.sgst = this.xpls.GST / 2;
       }
     }
     }
     if(this.data['waybills'] != null && this.data['waybills'].length > 0) {
       this.waybills = this.data['waybills'];
       this.ewaybillNo = this.waybills[0].InvoiceNo;
     }
     if(this.data['invoices'] != null && this.data['invoices'].length > 0) {
       this.invoices = this.data['invoices'];
       this.lspName = this.invoices[0].InvoiceNo;
       this.lspGSTIN = this.invoices[0].GSTIN;
       this.invoices.forEach(x => {
         this.invoicesTotal = this.invoicesTotal + x.InvoiceValue;
         let match = this.waybills.filter(y => y.InvoiceValue == x.InvoiceValue);
         let ewb = match.length > 0 ? match[0].InvoiceNo : '';
         x.ewb = ewb;
       });
     }
     }
   }
 
   close(): void {
     this.dialogRef.close();
   }
 
   downloadPDF() { 
     window.open(environment.apiUrl + 'InvoiceView/DownloadXPLSPDF?oId=' + this.xpls.OrderId + "&xId=" + this.xpls.XPLSId + "&f=" + this.showFreight, "_blank");
     // var data = document.getElementById('pdf');  
     // html2canvas(data).then(canvas => {  
     //   // Few necessary setting options  
     //   var imgWidth = 210;
     //   var pageHeight = 297;    
     //   var imgHeight = canvas.height * imgWidth / canvas.width;  
     //   var heightLeft = imgHeight;  
   
     //   const contentDataURL = canvas.toDataURL('image/png')  
     //   let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A6 size page of PDF   
     //   var position = 0;  
     //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
     //   pdf.save(this.xpls.XPLSCode + '.pdf'); // Generated PDF   
     // }); 
   }

}
