<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true"
        style="float: right !important;margin: 0 10px 0px 0;font-size: 17px;    cursor: pointer;" (click)="close()"></i>
    <h3 mat-dialog-title>Manage Table Grid </h3>
</div>

<div mat-dialog-content style="margin: 0px !important; padding: 0px !important">
    <div class="col-sm-12" id="pdf" style="padding-top: 15px;">
        <table id="tblPrint" style="width: 100%; border: 2px solid black; margin-bottom: 10px;">
            <tr>
                <!-- <td colspan="2" style="text-align: center !important; vertical-align: middle !important;">
            <img style="height:115px;margin-top:-17px;margin-left:5px; position: relative; top: 10px;" src="../../../../../../../assets/img/vendor/leaflet/logo_xpp.jpg">
          </td> -->
                <td colspan="2" style="border: none;"> <span class="text-center">Created By: {{data?.CreatedBy}}</span>
                </td>
                <td class="text-center" style="border: none;" colspan="3">
                    <h4><b>Order Summary </b></h4>
                </td>
                <td colspan="2" style="border: none;text-align: center !important;">
                    <span *ngIf="(data.vc_firstName != null) || (data.vc_lastName != null)" class="pull-right">Approved
                        By:
                        {{data?.vc_firstName}} {{data?.vc_lastName}}</span>
                    <span *ngIf="(data.vc_firstName == null) && (data.vc_lastName == null)" class="pull-right">Approved
                        By: -
                    </span>
                </td>
            </tr>
            <tr>
                <td>
                    <b>Load Booked By:</b>
                    <p>{{data?.BookedBy}}</p>
                </td>
                <td>
                    <b>Load Booked Date:</b>
                    <p>{{data?.BookedDate | date : 'dd-MM-y HH:mm'}}</p>
                </td>
                <td>
                    <b>Vehicle Assigned by:</b>
                    <p>{{data?.VehicleAssignedBy}}</p> <br>
                    <p>{{data?.VehicleAssignedDate | date : 'dd-MM-y HH:mm'}}</p>
                </td>
                <td>
                    <b>Vehicle Changed by:</b>
                    <p>{{data?.VehicleChangedBy}}</p> <br>
                    <p>{{data?.VehicleChangedDate | date : 'dd-MM-y HH:mm'}}</p>
                </td>
                <td>
                    <b>Cancelled by:</b>
                    <p *ngIf="data.CancelledDate != null ">{{data?.CancelledBy}}</p>
                    <p *ngIf="data.CancelledDate != null && data.CancelledBy == null "> Auto Cancelled </p>
                </td>
                <td>
                    <b>Cancelled Date:</b>
                    <p>{{data?.CancelledDate | date : 'dd-MM-y HH:mm'}}</p>
                </td>
                <td>
                    <!-- <b>FFV assigned by:</b>
                    <p>{{data?.FFVAssignedBy}}</p> <br>
                    <p>{{data?.FFVAssignedDate | date : 'dd-MM-y HH:mm'}}</p> -->
                </td>
            </tr>
            <tr>
                <td>
                    <b>Order ID:</b>
                    <p>{{data?.OrderIdCode}}</p>
                </td>
                <td>
                    <b>Booking Date:</b>
                    <p>{{data?.BookingIdDateTime | date : 'dd-MM-y HH:mm'}}</p>
                </td>
                <td>
                    <b>Booking ID:</b>
                    <p>{{data?.BookingIdCode}}</p>
                </td>
                <td>
                    <b>Service Class:</b>
                    <p>{{data?.ServiceClass}}</p>
                </td>
                <td>
                    <b>Service Type:</b>
                    <p>{{data?.ServiceType}}</p>
                </td>
                <td>
                    <b>Branch:</b>
                    <p>{{data?.BranchName}}</p>
                </td>
                <td>
                    <!-- <b>Zone:</b>
                    <p>{{data?.ZoneName}}</p> -->
                </td>
            </tr>
            <tr>
                <td *ngIf="data != null && data.XPCNs != null && data.XPCNs.length > 0">
                    <b>XPCN/XPLS:</b>
                    <p *ngFor="let item of data.XPCNs">
                        <a *ngIf="item.XPCNUrl != null" target="_blank" href="{{item.XPCNUrl}}">{{item.XPCNNumber}}</a>
                        <span *ngIf="item.XPCNUrl == null">{{item.XPCNNumber}}</span>
                    </p>
                </td>
                <td *ngIf="data != null && data.XPLSId != null">
                    <b>XPCN/XPLS:</b>
                    <p>
                        <a *ngIf="data.XPLSUrl != null" target="_blank" href="{{data.XPLSUrl}}">{{data.XPLSNumber}}</a>
                        <span *ngIf="data.XPLSUrl == null">{{data.XPLSNumber}}</span>
                    </p>
                </td>
                <td *ngIf="(data == null || (data.XPCNs == null || data.XPCNs.length == 0) && (data.XPLSId == null))">
                    <b>XPCN/XPLS:</b>
                    <p></p>
                </td>
                <td>
                    <!-- <b>XPTS:</b>
                    <p>
                        <a *ngIf="data != null && data.xpts_url != null" target="_blank"
                            href="{{data.xpts_url}}">{{data.xpts_no}}</a>
                        <span *ngIf="data != null && data.xpts_url == null">{{data.xpts_no}}</span>
                    </p> -->
                </td>
                <td>
                    <b>Customer:</b>
                    <p>{{data?.CustomerName}}</p>
                </td>
                <td>
                    <b>Consignee:</b>
                    <p>{{data?.ConsigneeName}}</p>
                </td>
                <td>
                    <b>Weight (Kgs):</b>
                    <p>{{data?.Weight}}</p>
                </td>
                <td>

                </td>
                <td>

                </td>
            </tr>
            <tr>
                <td>
                    <b>Origin:</b>
                    <p>{{data?.Origin}}</p>
                </td>
                <td>
                    <b>Via Points:</b>
                    <p>{{data.Via1 != '-' && data.Via2 != '-' ? '2' : (data.Via1 == '-' && data.Via2 == '-' ? '0':'1')}}
                    </p>
                </td>
                <td>
                    <b>Via 1:</b>
                    <p>{{data?.Via1}}</p>
                </td>
                <td>
                    <b>Via 2:</b>
                    <p>{{data?.Via2}}</p>
                </td>
                <td>
                    <b>Destination:</b>
                    <p>{{data?.Destination}}</p>
                </td>
                <td>

                </td>
                <td>

                </td>
            </tr>
            <tr>
                <td>
                    <b>Vehicle Type:</b>
                    <p>{{data?.VehicleType}}</p>
                </td>
                <td>
                    <b>Vehicel No:</b>
                    <p>{{data?.VehicleNo}}</p>
                </td>
                <td>
                    <!-- <b>FFV:</b>
                    <p>{{data?.FFVName}}</p> -->
                </td>
                <td>
                    <b>Driver Name:</b>
                    <p>{{data?.DriverName}}</p>
                </td>
                <td>
                    <b>Driver ID:</b>
                    <p>{{data?.DriverCode}}</p>
                </td>
                <td>
                    <b>Driver No:</b>
                    <p>{{data?.DriverContact}}</p>
                </td>

                <td>

                </td>
            </tr>
            <tr>
                <td>
                    <b>Placement Date & Time:</b>
                    <!-- <p>{{data?.OriginArrivedDate | date : 'dd-MM-y, hh:mm a'}}</p> -->
                    <p>{{data.PlacementDateTime | date : 'dd-MM-y'}} <br>({{ data.FromPlacementTime | date:'shortTime'}}
                        - {{
                        data.ToPlacementTime | date:'shortTime'}})</p>
                </td>
                <td>
                    <b>Origin Departure Date & Time:</b>
                    <p>{{data?.DepartedOrigin | date : 'dd-MM-y, hh:mm a'}}</p> <br>
                    <p *ngIf="data?.DepartOriginCreatedBy != null && data?.DepartOriginCreatedBy != '-'">
                        ({{data?.DepartOriginCreatedBy}})</p>
                </td>
                <td>
                    <b>Via Point 1 Arrival Date & Time:</b>
                    <p>{{data?.Via1ArrivalDate | date : 'dd-MM-y, hh:mm a'}}</p><br>
                    <p *ngIf="data?.Via1ArrivedCreatedBy != null && data?.Via1ArrivedCreatedBy != '-'">
                        ({{data?.Via1ArrivedCreatedBy}})</p>
                </td>
                <td>
                    <b>Via Point 1 Departure Date & Time:</b>
                    <p>{{data?.Via1DepartedDate | date : 'dd-MM-y, hh:mm a'}}</p><br>
                    <p *ngIf="data?.Via1DepartedCreatedBy != null && data?.Via1DepartedCreatedBy != '-'">
                        ({{data?.Via1DepartedCreatedBy}})</p>
                </td>
                <td>
                    <b>Via Point 2 Arrival Date & Time:</b>
                    <p>{{data?.Via2ArrivalDate | date : 'dd-MM-y, hh:mm a'}}</p><br>
                    <p *ngIf="data?.Via2ArrivedCreatedBy != null && data?.Via2ArrivedCreatedBy != '-'">
                        ({{data?.Via2ArrivedCreatedBy}})</p>
                </td>
                <td>
                    <b>Via Point 2 Departure Date & Time:</b>
                    <p>{{data?.Via2DepartedDate | date : 'dd-MM-y, hh:mm a'}}</p><br>
                    <p *ngIf="data?.via2DepartedCreatedBy != null && data?.via2DepartedCreatedBy != '-'">
                        ({{data?.via2DepartedCreatedBy}})</p>
                </td>
                <td>
                    <b>Destination Arrival Date & Time:</b>
                    <p>{{data?.ArrivalDestinationDate | date : 'dd-MM-y, hh:mm a'}}</p><br>
                    <p *ngIf="data?.DestinationArrCreatedBy != null && data?.DestinationArrCreatedBy != '-'">
                        ({{data?.DestinationArrCreatedBy}})</p>
                </td>
            </tr>
            <tr>
                <td>
                    <b>ATP:</b>
                    <!-- <p>{{data?.OriginArrivedDate | date : 'dd-MM-y, hh:mm a'}}</p> -->
                    <p>{{data.ATP | date : 'dd-MM-y HH:mm'}}</p><br>
                    <p *ngIf="data?.ATP != null && data?.ATP != '-'">
                        ({{data?.ArrivedOriginCreatedBy}})</p>
                </td>
                <td>
                    <b>Unloading Date & Time:</b>
                    <p>{{data?.UnloadedDate | date : 'dd-MM-y, hh:mm a'}}</p><br>
                    <p *ngIf="data?.DestinationUnloadedCreatedBy != null && data?.DestinationUnloadedCreatedBy != '-'">
                        ({{data?.DestinationUnloadedCreatedBy}})</p>
                </td>
                <td>
                    <b>Halting Hours At Destination:</b>
                    <p>{{data?.DestinationHaltedHours}}</p>
                </td>
                <td>
                    <b>Total Transit Hours:</b>
                    <p>{{data?.TotalTransitHours}}</p>
                </td>
                <td>
                    <b>Halting Hours:</b>
                    <p>{{(data.Pickup1_HaltedHours)+ (data.Pickup2_HaltedHours)+ (data.Via1_HaltedHours)+
                        (data.Via2_HaltedHours)}}</p>
                </td>
                <td>
                    <b>Net Transit Hours:</b>
                    <p>{{(data.TotalTransitHours) - ( (data.Pickup1_HaltedHours)+ (data.Pickup2_HaltedHours)+
                        (data.Via1_HaltedHours)+ (data.Via2_HaltedHours))}}</p>
                </td>
                <td>
                    <b>Transit Time:</b>
                    <p>{{data.TransitTime}}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <b>Trip Status:</b>
                    <p>{{data?.TripStatus}}</p>
                </td>
                <td>
                    <b>Delay Hours:</b>
                    <p>{{data?.DelayHours}}</p>
                </td>
                <td>
                    <b>Delay Reason:</b>
                    <p>{{data?.DelayReason}}</p>
                </td>
                <td>
                    <b>Delay Type:</b>
                    <p>{{data?.DelayType}}</p>
                </td>
                <td>
                    <b>Case ID:</b>
                    <p>{{data?.CaseID}}</p><br>
                    <p *ngIf="data?.CaseIDCreatedBy != null && data?.CaseIDCreatedBy != '-'">
                        ({{data?.CaseIDCreatedBy}})</p>
                </td>
                <td>

                </td>
                <td>

                </td>
            </tr>
            <tr>
                <td>
                    <b>Freight:</b>
                    <p>{{data?.Freight}}</p><br>
                    <p *ngIf="data?.FreightChangedBy != null && data?.FreightChangedBy != '-'">
                        ({{data?.FreightChangedBy}})</p>
                </td>
                <td>
                    <b>Halting Charges:</b>
                    <p>{{data?.HaltingCharges != null && data?.HaltingCharges >=0 ?
                        (data?.HaltingCharges) : '0'}}</p><br>
                    <p *ngIf="data?.HaltingCharges > 0">
                        ({{data?.XPCNCreatedBy}})</p>
                </td>
                <td>
                    <b>Handling Charges:</b>
                    <p>{{data?.HandlingCharges}}</p><br>
                    <p *ngIf="data?.HandlingCharges > 0">
                        ({{data?.XPCNCreatedBy}})</p>
                </td>
                <td>
                    <b>Other Charges:</b>
                    <p>{{data?.OtherCharges}}</p><br>
                    <p *ngIf="data?.OtherCharges > 0">
                        ({{data?.XPCNCreatedBy}})</p>
                </td>
                <td>
                    <b>Offer/Discount:</b>
                    <p>{{data?.Discount}}</p>
                </td>
                <td>
                    <b>Bill Discount:</b>
                    <p>{{data?.Freight != null && data?.DiscountPercentage != null ? ((data?.Freight *
                        data?.DiscountPercentage)/100) : '0'}}</p>
                </td>
                <td>
                    <b>Invoice No:</b>
                    <p>{{data?.InvoiceBillNo}}</p>
                </td>
            </tr>
            <tr>
                <td>
                    <b>Invoice Net Amount:</b>
                    <p>{{data?.TotalNetAmount}}</p>
                </td>
                <td>
                    <b>Invoice Total Amount:</b>
                    <p>{{data?.TotalInvoiceAmount}}</p>
                </td>
                <td>
                    <b>Due Date:</b>
                    <p>{{data?.DueDate | date: 'dd-MM-yyyy'}}</p>
                </td>
                <td>
                    <b>Payment Release Date:</b>
                    <p>{{data?.PaymentRealisedDate | date: 'dd-MM-yyyy'}}</p>
                </td>
                <td>
                    <b>Collected Amount:</b>
                    <p>{{data?.CollectedAmount}}</p>
                </td>
                <td>
                    <b>Due Days/Paid Days:</b>
                    <p>{{data?.DueDays}}</p>
                </td>
                <td>
                    <b>Payment Status:</b>
                    <p>{{data?.OrderPaymentStatus}}</p>
                </td>
            </tr>
        </table>
    </div>
</div>

<mat-dialog-actions align="end" style="padding-bottom: 10px ;margin-right: 12px;">
    <button class="btn btn-primary fa fa-download" (click)=" downloadPDF()"
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
        <span style="font-family: Arial, Helvetica, sans-serif;"> Download</span>
    </button>
    <button class="btn btn-danger fa fa-times-circle" (click)="close()"
        style="margin-left: 10px ; background-color: #f44336 !important;">
        <span style="font-family: Arial, Helvetica, sans-serif;">
            Cancel</span></button>
</mat-dialog-actions>