<div class="newMarginClass">
    <i class="fa fa-times" aria-hidden="true" style="
      float: right !important;
      margin: 0 10px 0px 0;
      font-size: 17px;
      cursor: pointer;
    " (click)="close()"></i>
    <h1 mat-dialog-title>Enter Cancellation Fields</h1>
</div>


<div mat-dialog-content>
    <form class="form-horizontal" novalidate [formGroup]="EditOrder">
        <div class="form-group">
            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 add-member-label" for="pwd">Booking Id
            </label>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <input type="text" style="cursor:not-allowed" disabled [value]="vc_BookingId" class="form-control">
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-lg-4 col-md-4 col-sm-4 col-xs-4 add-member-label" for="pwd">Select Reason:
            </label>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <mat-select placeholder="--Select Reason--" formControlName="Reason" class="form-control" required>
                    <mat-option *ngFor="let element of ReasonArray" [value]="element.Reason">
                        {{element.Reason}}
                    </mat-option>
                </mat-select>
                <div *ngIf="!EditOrder.controls.Reason.valid &&
                (EditOrder.controls.Reason.dirty ||
                  EditOrder.controls.Reason.touched)" class="error-text">
                    <div *ngIf="EditOrder.controls.Reason.errors.required">
                        Reason is required
                    </div>
                </div>

            </div>
        </div>

    </form>
</div>

<mat-dialog-actions align="end" style="padding-bottom: 10px">
    <button (click)="Update()" mat-raised-button
        style="cursor: pointer; left: 6px; background-color: #286ba5; color: white">
        <span>Update</span>
    </button>
    <button (click)="close()" mat-raised-button
    style="cursor: pointer; left: 6px; background-color: #f44336; color: white">
    <span>Cancel</span>
</button>
    <!-- <button mat-raised-button style="
      background-color: #f44336 !important;
      color: white !important;
      left: 6px;" (click)="close()">
    </button> -->
</mat-dialog-actions>