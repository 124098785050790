import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  setScreenFilter(screenName: string, filters: any,status:boolean) {
    var ffvfilter= localStorage.getItem("filterAction")
    if(ffvfilter==null)
    {
      let filter=[{
        screenName:screenName,
        action:status,
        filters:filters
      }]
      localStorage.setItem("filterAction",JSON.stringify(filter))
    }
    else{
      let obj=JSON.parse(ffvfilter)
      let filter={
        screenName:screenName,
        action:status,
        filters:filters
      }
      obj=obj.filter(function(ele){ 
        return ele.screenName != screenName; 
       });
       obj.push(filter)
       localStorage.setItem("filterAction",JSON.stringify(obj))

    }
  }
  getScreenFilter(screenName: string):any {
    var ffvfilter= localStorage.getItem("filterAction")
    if(ffvfilter==null)
    {
      return null
    }
    else{
      let obj=JSON.parse(ffvfilter)
      obj=obj.filter(function(ele){ 
        return ele.screenName == screenName; 
       });
      return obj[0].filters

    }
  }
  getFilterAction(screenName:string)
  {
    var session= localStorage.getItem("filterAction")
    var action=null
    if(session!=null)
    {
      let data=JSON.parse(session).filter(screen=>screen.screenName==screenName)
      if(data!=null)
      {
        if(data.length!=0)
        action=data[0].action
      }
    }
    return action
  }

  constructor() { }
  getItem(key:string)
  {
    return localStorage.getItem(key)
  }
  setItem(key:string,value:string)
  {
    localStorage.setItem(key,value)
  }
  removeItem(key:string){
    localStorage.removeItem(key)
  }
}
