import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { BookOrderService } from '../../book-order/book-order.service';
import { Pipe, PipeTransform, ViewChild } from '@angular/core';
import { MapDialogComponent } from 'src/app/pages/shared/map-dialog/map-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MapsdialogsComponent } from 'src/app/pages/customers/mapsdialogs/mapsdialogs.component';
import { CustomerserviceService } from 'src/app/pages/customers/customerservice.service';
import { MaplclOrderComponent } from '../../maplcl-order/maplcl-order.component';
import { OrderService } from '../../order-dashboard/order.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateTimeAdapter } from 'ng-pick-datetime/date-time';
import { NewOrderDialogComponent } from './new-order-dialog/new-order-dialog.component';
import { Router, RouterLink } from '@angular/router';
import { ViewMapLocationComponent } from 'src/app/pages/customers/locations/view-map-location/view-map-location.component';
import { CaptureLocationMapDialogComponent } from '../../book-order/capture-location-map-dialog/capture-location-map-dialog.component';
import { element } from 'protractor';
import { CommonModule } from '@angular/common';



//import { NewOrderDialogComponent } from './lcl/book-lclorder/new-order-dialog/new-order-dialog.component';

@Component({
  selector: 'app-book-lclorder',
  templateUrl: './book-lclorder.component.html',
  styleUrls: ['./book-lclorder.component.scss']
})
export class BookLCLOrderComponent implements OnInit {
  LCLOrderTypes = null;
  Spinner: boolean = false;
  isDeliveryRegionEnabled: boolean = false;

  public ORDER_TYPE_FCL = "FCL"
  public ORDER_TYPE_LCL = "LCL"
  customerLocationForDropDown: any[] = [];
  GatewayList: any[] = [];
  submitted: boolean = false;
  isLCLDirect: boolean = false;
  // @Input() max:any;
  // @Input() min:any;
  today = new Date();
  today1 = this.datepipe.transform(new Date(), "yyyy-MM-dd").toString();
  dt = new Date()
  //today1 = '2023-12-05'; 


  // @ViewChild('picker') _picker: MatDatepicker<Date>;
  public CLOSE_ON_SELECTED = false;
  public resetModel = new Date(0);
  public model = [];
  datePlaceholder = 'Select Date(s)';
  dateChange = false;
  customerId = null;
  serviceClassId = null;
  customerType;
  selectedCountries2: string[] = [];
  originLocationList: any[] = [];
  DestLocationList: any[] = []
  selectedCities1: string[] = [];
  public init = new Date();
  DeliveryStateSearch: any[] = []
  OriginGatewayDetails: any[] = []
  isPickUpDateSelected: boolean = false
  customStateListForDropDown: any[] = []
  selectedStates: any[] = []

  DestLocationSearch: any[] = [] //Destination Direct Delivery
  OriginLocationSearch: any[] = []
  isStateSelected: boolean = false
  VehicleTypeSearch: any[] = [];
  DeliveryRegionSearch: any[] = []
  googlePlaceId: any;
  isBreakbulk: boolean = false;
  isD2c: boolean = false;
  Filtertoggle: boolean = false;
  LCL_BREAKBULK = 'LCL Breakbulk';
  LCL_DIRECT_DELIEVERY = 'LCL Direct - Delivery'
  googleLocations = [];
  originList: any[] = [];
  deliveryRegionList: any[] = []
  OriginSearch: any[] = [];
  deliveryStateList: any[] = []
  destList: any[] = []
  googleSearch: any[] = [];
  isToTimeDisabled: boolean = true
  TOTAL_HOURS_IN_A_DAY = 24
  TENTATIVE_TO_TIME_HOURS = 4
  RESTRICTED_HOURS = this.TOTAL_HOURS_IN_A_DAY - this.TENTATIVE_TO_TIME_HOURS


  destinationList: any[] = []

  vehicleTypeList: any[] = []
  serviceId: number = -1;
  serviceTypes: any[] = [];
  bookOrderForm: FormGroup;
  order = {
    ServiceTypeId: null,
    ServiceClassId: null,
    ServiceClass: null,
    CustomerId: null,
    OrderDates: null,
    VehicleTypeId: null,
    VehicleType: null,
    PaymentModeId: null,
    OrderBookedFrom: null,
    OrderDetails: [
      {
        OriginLatLng: null,
        Pickup1LatLng: null,
        Pickup2LatLng: null,
        DestLatLng: null,
        OriginId: null,
        Origin: null,
        OriginType: null,
        Pickup1Id: null,
        Pickup1: null,
        Pickup1Type: null,
        Pickup2Id: null,
        Pickup2: null,
        Pickup2Type: null,
        Via1Id: null,
        Via1: null,
        Via1Type: null,
        Via2Id: null,
        Via2: null,
        Via2Type: null,
        DestinationId: null,
        Destination: null,
        DestinationType: null,
        NoOfVehicles: null,
        Distance: null,
        TransitTime: null,
        Rate: null,
        HaltingChargesPerHour: null,
        FCLRateId: null,
        PriceMasterId: null,
        IsRateChanged: null,
        PickupLocations: [
          {
            PlaceId: null,
            GoogleSearch: null,
            GoogleList: [],
            ShowMap: false,
            SavedPickupLocationId: null,
            SavedPickupLocation: null,
            SavedPickupAddress: null,
            FromPickupTime: null,
            ToPickupTime: null,
            PickupWindow: null,
            CompanyName: null,
            PlotNo: null,
            Area: null,
            City: null,
            Pincode: null,
            Latitide: null,
            Longitude: null,
            POC: null,
            ContactNo: null
          }
        ]
      }
    ]
  }


  isMapLocationNotEditable: boolean = true
  isOriginSelectedFromMap: boolean = false
  isDestSelectedFromMap: boolean = false
  isBookNewOrderClicked: boolean = false

  lat: number;
  lng: number;
  orderType: any;
  originLocationId: number;
  arrayofservice: any[];
  sample: Date;
  sample3: Date;
  sample2: Date;
  //minDate: any;
  constructor(private fb: FormBuilder, private customerservice: CustomerserviceService, private dialog: MatDialog, public alertService: AlertService, private _orderService: BookOrderService,
    public orderservice: OrderService, public datepipe: DatePipe
    , public route: Router) {
    this.bookOrderForm = this.fb.group({
      ServiceTypeId: [null,],
      ServiceTypeName: [null,],
      OriginAddress: [null],
      CustomerId: [null,],
      PickUpRequestDate: ['', Validators.required],
      FromTime: [null, Validators.required], //FrontEnd purpose only
      ToTime: [null, Validators.required], //FrontEnd purpose only
      PickUpRequestFromDate: ['', Validators.required],
      PickUpRequestToDate: ['', Validators.required],
      OriginPostCodeId: ['', Validators.required],
      OriginPostCodeNumber: ['', Validators.required],
      OriginId: [null, Validators.required],
      OriginName: ['', Validators.required],
      OriginLatitude: [''],
      OriginLongitude: ['',],
      TentativeWeight: [null],
      POCName: ['', Validators.required],
      POCNumber: [null, Validators.required],
      VehicleTypeId: [null],
      VehicleTypeName: [null],
      OriginMap: [''], //FrontEnd purpose only
      DeliveryZoneId: [null],
      DeliveryZoneName: [null],
      DeliveryStateIdFE: [null], //FrontEnd purpose only
      DeliveryStateId: [null],
      DeliveryStateName: [null],
      OriginLocationId: [null, Validators.required],
      Remarks: [null],
      OriginGatewayId: [null],
      OriginGateway: [null],
      GatewayBranch: [null],
      GatewayLocation: [null],
      DestMap: [''], //FrontEnd purpose only
      //No of booking 
      total_bookings: [null],
      FreightAmount: [null],
      //Destination
      DestLocationId: [null, Validators.required],
      DestId: [null, Validators.required],
      DestPostCodeId: [null],
      DestPostCodeNumber: [null],
      DestName: [null],
      DestLatitude: [null],
      DestLongitude: [null],
      DestAddress: [null],
    })

  }

  ngOnInit(): void {
    //console.log(this.today,this.today1)
    this._orderService.getOrderOptions().subscribe(data => {
      if (data != null) {
        this.serviceTypes = data['Data']['LCLOrderTypes']
        this.arrayofservice = this.serviceTypes.filter(element => element.LookupName !== 'LCL Aggregation' && element.LookupName !== 'LCL Express D2C'&& element.LookupName !== 'Gateway / CC Pickup')
        this.getRegionsForDelivery()
        this.getLCLOptionsList()
        //this.getDeliveryStates();
        this.getCustomerId();
        this.originGateway();



      }
    });
  }

  getCustomerId() {
    this._orderService.getCustomerDetails().subscribe(data => {
      console.log(data);
      this.customerId = data['Data']['Customer']["CustomerId"];

    });
  }

  getDeliveryStates() {
   
    this.customStateListForDropDown = []
    let allStatesList = []
    this.deliveryStateList = [] 
    this.orderservice.GetStatesDropdownlist().subscribe(response => {
      allStatesList = response["Data"]
      allStatesList.forEach(x => {
        this.customStateListForDropDown.push({ label: x.StateName, value: x.StateId })
        this.deliveryStateList.push({ LookupId: x.StateId, LookupName: x.StateName })
        console.log(this.customStateListForDropDown,"deliverystates")

      })

    })
  }
  onGatewaySelection(event) {
    this.bookOrderForm.controls['OriginGatewayId'].setValue(event.value);
    this.OriginGatewayDetails = this.GatewayList.filter(x => x.ZoneId == event.value);
    let formControls = [{ name: "OriginGateway", value: this.OriginGatewayDetails['0']['ZoneName'] },
    { name: "GatewayBranch", value: this.OriginGatewayDetails['0']['BranchName'] },
    { name: 'GatewayLocation', value: this.OriginGatewayDetails['0']['Location'] },
    { name: 'POCName', value: this.OriginGatewayDetails['0']['ContactPerson'] },
    { name: 'POCNumber', value: this.OriginGatewayDetails['0']['Mobile'] },
    { name: 'OriginLatitude', value: this.OriginGatewayDetails['0']['Latitude'] },
    { name: 'OriginLatitude', value: this.OriginGatewayDetails['0']['Longitude'] },
    { name: 'OriginAddress', value: this.OriginGatewayDetails['0']['Address'] },
    { name: 'OriginPostCodeId', value: this.OriginGatewayDetails['0']['LocationID'] },
    { name: "OriginPostCodeNumber", value: this.OriginGatewayDetails['0']['Postcode'] },
    { name: 'OriginName', value: this.OriginGatewayDetails['0']['ContactPerson'] }]
    this.setValuesForFormControl(formControls);
  }


  // onGoogleLocationChange(type, event) {
  //    
  //   if (event != null) {
  //     let loc = this.googleLocations.filter(x => x.PlaceId == event)[0];
  //     if (loc != null) {
  //       this.bookOrderForm.controls['Address'].setValue(loc['Address']);
  //       this.bookOrderForm.controls['Latitude'].setValue(loc['Lat']);
  //       this.bookOrderForm.controls['Longitude'].setValue(loc['Lng']);
  //       //for google maps
  //       this.lat = Number(loc['Lat']);
  //       this.lng = Number(loc['Lng']);
  //       //for google maps
  //       //this.changeMarker(this.lat, this.lng);
  //     }
  //   }
  // }

  onSelectCustomer(event) {

    let customerId = event.value
    this.reset(false)
    this.setValuesForFormControl([{ name: 'CustomerId', value: customerId }])
    if (this.getValueOfFormControl("CustomerId") != null && this.getValueOfFormControl("OriginLocationId") != null)
      this.getCustomerSavedLocations(customerId, this.getValueOfFormControl("OriginLocationId"), "Origin")

  }

  getValueOfFormControl(keyName: string): any {
    return this.bookOrderForm.controls[keyName].value
  }

  onDeliveryStateSelection(event) {
    let selectedDeliveryState = this.deliveryStateList.filter(x => x.LookupId == event.value)[0];
    this.setValuesForFormControl([{ name: 'DeliveryStateName', value: selectedDeliveryState['LookupName'] }])
  }
  originLocationSearch(originLocation, Type) {

    if (originLocation != null && originLocation.length >= 3) {

      this._orderService.getOriginDestinations(originLocation, true).subscribe(data => {
        let list: any[] = []
        if (data != null) {
          list = data['Data'];

        }
        if (Type === 'Origin') this.originLocationList = list;
        else if (Type === 'Dest') this.DestLocationList = list;

      });
    }
  }
  openMapForLocationCapture(Type) {
    if (this.getValueOfFormControl("OriginLocationId") != null || this.getValueOfFormControl("DestLocationId") != null) {
      //console.log(this.getValueOfFormControl("OriginLocationId"));

      const dialogRef = this.dialog.open(CaptureLocationMapDialogComponent, {
        height: 'auto',
        width: '600px',
        autoFocus: false,
        data: ''
      });
      dialogRef.afterClosed().subscribe(modalData => {
        if (modalData.event === 'Confirm') {
          let data = modalData['data']
          this.validateCapturedLocation(data, Type);


        }

      });
    }
    else {
      this.alertService.createAlert("Please Select Origin Location", 2)
    }

  }
  onlyNumberswithlimts(event) {
    (Number(event.target.value) > 10) ? this.setValuesForFormControl([{ name: 'total_bookings', value: null }]) : this.setValuesForFormControl([{ name: 'total_bookings', value: Number(event.target.value) }])
  }
  onOriginSelection(event) {

    let selectedOrigin = this.originList.filter(x => x.CustomerLocationId == event.value)[0];
    let formControls = [{ name: 'OriginPostCodeId', value: selectedOrigin['PostcodeId'] },
    { name: 'OriginPostCodeNumber', value: selectedOrigin['Postcode'] },
    { name: 'OriginName', value: selectedOrigin['LocationName'] },
    { name: 'OriginLatitude', value: selectedOrigin['Latitude'] },
    { name: 'OriginLongitude', value: selectedOrigin['Longitude'] },
    { name: 'POCName', value: selectedOrigin['POC'] == null || selectedOrigin['POC'] == "-" ? "" : selectedOrigin['POC'] },
    { name: 'POCNumber', value: selectedOrigin['Mobile'] == null || selectedOrigin['Mobile'] == "-" ? "" : selectedOrigin['Mobile'] },
    { name: "OriginAddress", value: selectedOrigin["Address"] }]
    this.setValuesForFormControl(formControls)

  }
  onDestSelection(event) {
    let selectedOrigin = this.destList.filter(x => x.CustomerLocationId == event.value)[0];
    let formControls = [{ name: 'DestPostCodeId', value: selectedOrigin['PostcodeId'] },
    { name: 'DestPostCodeNumber', value: selectedOrigin['Postcode'] },
    { name: 'DestName', value: selectedOrigin['LocationName'] },
    { name: 'DestLatitude', value: selectedOrigin['Latitude'] },
    { name: 'DestLongitude', value: selectedOrigin['Longitude'] },
    { name: "DestAddress", value: selectedOrigin["Address"] }]
    this.setValuesForFormControl(formControls)
  }
  cancelMapLocation() {
    let resetKeyNames = ['OriginPostCodeNumber', 'OriginName', 'OriginMap', 'OriginLatitude', 'OriginLongitude']
    resetKeyNames.forEach(key => {
      this.setValuesForFormControl([{ name: key, value: null }])
    })

    let setValidators = ['OriginId', 'OriginPostCodeId']
    setValidators.forEach(key => {
      this.setValidator(key)

    })
  }

  cancelMapDestLocation() {
    let resetKeyNames = ['DestPostCodeNumber', 'DestName', 'DestMap', 'DestLatitude', 'DestLongitude']
    resetKeyNames.forEach(key => {
      this.setValuesForFormControl([{ name: key, value: null }])
    })

    let setValidators = ['DestId', 'DestPostCodeId']
    setValidators.forEach(key => {
      this.setValidator(key)

    })
  }


  onSelectFromTime(event) {
    // console.log(this.getValueOfFormControl('FromTime'),"akshjfaksh")
    // debugger

    // this.setValuesForFormControl([{ name: 'FromTime', value: this.datepipe.transform(this.getValueOfFormControl('FromTime'),'HH:mm') }])

    let dateTime = new Date(this.getValueOfFormControl('PickUpRequestDate') + " " + this.datepipe.transform(this.getValueOfFormControl('FromTime'),'HH:mm'))

    if (dateTime < this.today) {
      this.bookOrderForm.controls.FromTime.setErrors({ lessDateTime: true })
      this.setValuesForFormControl([{ name: 'PickUpRequestFromDate', value: null },
      { name: 'ToTime', value: null }])
    }
    else {
      this.bookOrderForm.controls.FromTime.setErrors(null)
      this.setValuesForFormControl([{ name: 'PickUpRequestFromDate', value: new Date(dateTime) }])

      this.setToTime(dateTime)

    }

  }

  onSelectToTime(event) {
    let dateTime = new Date(this.today.toDateString() + " " + this.getValueOfFormControl('ToTime'))
    let fromTime = this.getValueOfFormControl('FromTime')
    if (fromTime !== '' && fromTime !== null) {
      if (dateTime <= fromTime) {
        this.bookOrderForm.controls.ToTime.setErrors({ lessDateTime: true })
        this.setValuesForFormControl([{ name: 'PickUpRequestToDate', value: null }])
      }
      else {
        this.bookOrderForm.controls.ToTime.setErrors(null)
        this.setValuesForFormControl([{ name: 'PickUpRequestToDate', value: new Date(dateTime) }])
      }
    }
    else {
      //this.alertService.createAlert('Please Select From Time', 0)
      let formControls = [{ name: 'PickUpRequestToDate', value: null }, { name: 'ToTime', value: null }]
      this.setValuesForFormControl(formControls)

    }
  }

  originGateway() {
    this._orderService.GetGateway().subscribe(x => {
      if (x['Success']) {
        this.GatewayList = x['Data']

      }
    })
  }
  onlyNumbers(event) {
    let k;
    k = event.charCode;
    return (k > 47 && k < 58);
  }

  onDeliveryRegionSelection($event) {
    let selectedRegion = this.deliveryRegionList.filter(x => x.ZoneId == $event)[0];
    this.setValuesForFormControl([{ name: 'DeliveryZoneName', value: selectedRegion['ZoneName'] }])
    this.getRegionStates(selectedRegion.ZoneId)
    this.isBreakbulk = true
    this.isLCLDirect = true
  }

  onStateSelection(event) {
   
    let selectedStateIds = event
    this.isStateSelected = selectedStateIds.length > 0 ? true : false
    this.isBreakbulk = true;
    this.isLCLDirect = true
   
  }

  getRegionStates(regionId: any) {
    //this.customStateListForDropDown = []
    if (regionId != null) {
      this.orderservice.getRegionStates(regionId).subscribe(res => {
        //debugger
        this.customStateListForDropDown = []
        this.deliveryStateList = res["Data"]
        this.deliveryStateList.forEach(x => {
          this.customStateListForDropDown.push({ label: x.LookupName, value: x.LookupId })
        })

        console.log(this.customStateListForDropDown,"region")

      })
    }
  }

  getRegionsForDelivery() {
    this._orderService.GetRegions().subscribe(response => {
      this.deliveryRegionList = response['Data']

    })
  }

  selectServiceType(event) {
    this.reset(false)

    this.bookOrderForm.controls['ServiceTypeId'].setValue(event)
    let selectedServiceType = this.serviceTypes.filter(x => x.LookupId == event)[0];
    let ServiceName = this.serviceTypes.filter(x => x.LookupId == event)[0].LookupName;
    this.bookOrderForm.controls['ServiceTypeName'].setValue(ServiceName)
    //console.log(event, selectedServiceType, ServiceName)
    this.setValuesForFormControl([{ name: "ServiceTypeName", value: selectedServiceType.LookupName }])
    this.validationForServiceTypeSelection(selectedServiceType.LookupName);

  }

  reset(resetAll) {
    let keyWords = [
      "PickUpRequestDate", "PickUpRequestFromDate", "PickUpRequestToDate", "OriginPostCodeId", "OriginPostCodeNumber", "OriginId","OriginLocationId",
      "OriginName", "OriginLatitude", "OriginLongitude", "TentativeWeight", "POCName", "POCNumber", "VehicleTypeId", "VehicleTypeName", "OriginMap", "DestPostCodeId", "DestPostCodeNumber", "DestId", "DestMap",
      "DeliveryZoneId", "DeliveryZoneName", "DestName", "DestLatitude", "DestLongitude", "ToTime", "FromTime", "Remarks", "DeliveryStateId", "DeliveryStateName", "DeliveryStateIdFE", "total_bookings", "FreightAmount", "DestLocationId"
    ]

    if (resetAll) {
      this.serviceId = -1
      keyWords.push("ServiceTypeId")

    }
    for (let i = 0; i < keyWords.length; i++) {
      this.setValuesForFormControl([{ name: keyWords[i], value: null }])
    }

    this.bookOrderForm.markAsUntouched()
    this.bookOrderForm.setErrors(null)
    this.isBookNewOrderClicked = false
  }

  getLCLOptionsList() {
    this._orderService.getOrderOptions().subscribe(data => {
      if (data != null) {
        // this.customers = data['Data']['CustomerList'];

        /*Not all vehicletypes are available in LCL, only few Vehicle Types
          are available -Bharath Uppalanchi
        */
        this.vehicleTypeList = data['Data']['VehicleTypeList'].filter((element): element is object => {
          let vehicleType = (element['LookupName'] as string)
          return vehicleType.startsWith('20') || vehicleType.startsWith('22') ||
            vehicleType.startsWith('24') || vehicleType.startsWith('32SXL') ||
            vehicleType.startsWith('32MXL 17MT') ||
            vehicleType.startsWith('32SXL 9MT HC') ||
            vehicleType.startsWith('20SXL 6MT') ||
            vehicleType.startsWith('17FT 4MT') ||
            vehicleType.startsWith('14FT 3MT');
        });;

        this.serviceTypes = data['Data']['LCLOrderTypes']

      }
    });
  }

  book() {
    let orderType = this.serviceTypes.filter(x => x.LookupId == this.order.ServiceTypeId)[0].LookupName;
    this.orderType = orderType;

  }

  getSelectedStateDetails(selectedStateIds: any[]): { stateIds: any, stateNames: any } {

    let selectedStatesList = ""
    let selectStateIds = ""
    if (selectedStateIds.length > 0) {
      selectedStateIds.forEach(selectedStateId => {

        selectedStatesList = selectedStatesList + this.deliveryStateList.filter(x => x.LookupId == selectedStateId)[0].LookupName + ","
        selectStateIds = selectStateIds + this.deliveryStateList.filter(x => x.LookupId == selectedStateId)[0].LookupId + ","
      })
    }

    return { stateIds: selectStateIds.substring(0, selectStateIds.length - 1), stateNames: selectedStatesList.substring(0, selectedStatesList.length - 1) }
  }


  bookOrder() {

    this.submitted = true;
    this.isBookNewOrderClicked = true

    // const invalid = [];
    //    const controls = this.bookOrderForm.controls;
    //    for (const name in controls) {
    //        if (controls[name].invalid) {
    //            invalid.push(name);
    //        }
    //    }
    //   console.log(invalid,"pay")

    //console.log(this.bookOrderForm)

    if (this.bookOrderForm.valid) {
      this.isBookNewOrderClicked = true


      if (this.orderType === "LCL Breakbulk") {
        let statesResult = this.getSelectedStateDetails(this.getValueOfFormControl("DeliveryStateIdFE"))
        this.setValuesForFormControl([{ name: "DeliveryStateId", value: statesResult.stateIds }, { name: "DeliveryStateName", value: statesResult.stateNames }])
      }

      // ADDED BECAUSE OF 5 & 1/2 hrs DIFFERENCE OF SCHEDULE DATEs

      var p1 = new Date(this.bookOrderForm.controls['PickUpRequestFromDate'].value)
      var p2 = new Date(this.bookOrderForm.controls['PickUpRequestToDate'].value)

      this.setValuesForFormControl([{ name: "PickUpRequestFromDate", value: new Date(p1.getTime() + (330 * 60 * 1000)) },
      { name: "PickUpRequestToDate", value: new Date(p2.getTime() + (330 * 60 * 1000)) }])

      // -------------------------------------------------------------------------------------------

      this.orderservice.bookLCLOrder(this.bookOrderForm.value).subscribe(response => {
        if (response['Success']) {
          this.reset(true)
          this.orderType = null
          this.order.ServiceTypeId = null
          this.submitted = false;
          let dialogRef = this.dialog.open(NewOrderDialogComponent, {
            data: response['Message'],
            height: 'auto',
            width: '500px'
          });
          dialogRef.afterClosed().subscribe(data => {
            if (data == true) {
              //TO DO
            } else {
              this.route.navigate(['/xp/generate-xpcn']);
            }
          });

        }
        else {
          this.isBookNewOrderClicked = false
          this.alertService.createAlert(response['Message'], 0)
        }
      })
    }
    else {
      this.isBookNewOrderClicked = false
      this.alertService.createAlert('please fill all the required fields',0)
    }

  }
  setValuesForFormControl(formControls: { name: string, value: any }[]) {
    /*If fromControl keys are more than 1 then forEach executed,
     if not no need of forEach execution*/
    if (formControls.length > 1) {
      formControls.forEach(formControl => {
        this.bookOrderForm.controls[formControl.name].setValue(formControl.value)
        if (formControl.value === null && (formControl.name == 'OriginMap' || formControl.name == 'PickUpRequestDate' || formControl.name == 'DeliveryStateIdFE' || formControl.name == 'DestMap')) {
          this.resetBoolean(formControl.name)
        }
      })
    }
    else {
      this.bookOrderForm.controls[formControls[0].name].setValue(formControls[0].value)
      if (formControls[0].value === null && (formControls[0].name == 'OriginMap' || formControls[0].name == 'PickUpRequestDate' || formControls[0].name == 'DeliveryStateIdFE' || formControls[0].name == 'DestMap')) {
        this.resetBoolean(formControls[0].name)
      }
    }

  }

  setToTime(event) {

    this.sample = new Date(event);

    if (this.sample.getHours() >= 20) {
      this.sample3 = new Date();
      this.sample3.setHours(23, 59, 0)
      this.setValuesForFormControl([{ name: 'ToTime', value: this.datepipe.transform(this.sample3, "HH:mm") }])
      this.onSelectToTime(this.sample3)
    }
    else {
      this.sample2 = new Date();
      this.sample2.setTime(this.sample.getTime() + 4 * (60 * 60 * 1000));
      this.setValuesForFormControl([{ name: 'ToTime', value: this.datepipe.transform(this.sample2, "HH:mm") }])
      this.onSelectToTime(this.sample2)
    }



    // let fromTimeHour = Number.parseInt(event)
    // let fromTimeMinutes = Number.parseInt(event)
    // let toTimeHour = 0
    // let toTimeMinutes = 0

    // if (fromTimeHour < this.RESTRICTED_HOURS) {
    //   toTimeHour = fromTimeHour + this.TENTATIVE_TO_TIME_HOURS
    //   toTimeMinutes = fromTimeMinutes
    // }
    // else {
    //   toTimeHour = 23
    //   toTimeMinutes = 59
    // }
    // let hardcodeDate = `1998-03-20T${toTimeHour <= 9 ? '0' + toTimeHour : toTimeHour}:${toTimeMinutes <= 9 ? '0' + toTimeMinutes : toTimeMinutes}:00`
    // this.setValuesForFormControl([{ name: 'ToTime', value: this.datepipe.transform(hardcodeDate, "HH:mm") }])
    // this.onSelectToTime(hardcodeDate)
  }


  onSelectPickUpRequestDate() {
    this.isPickUpDateSelected = true
    let formControls = [{ name: 'FromTime', value: null }, { name: 'PickUpRequestFromDate', value: null }, { name: 'ToTime', value: null }, { name: 'PickUpRequestToDate', value: null }]
    this.setValuesForFormControl(formControls)

  }


  validationForServiceTypeSelection(selectedServiceType: string) {
    /* LCL BreakBulk = VehicleType and DeliveryZone details are mandatory
     LCL BreakBulk = Tentative Weight is not mandatory
     LCL Express or Aggregation = VehicleType and DeliveryZone details are not mandatory
    LCL Express or Aggregation = Tentative Weight is mandatory - Bharath Uppalanchi
    */

    var keyWords = ['VehicleTypeId', 'VehicleTypeName', 'DeliveryZoneId', 'DeliveryZoneName', 'TentativeWeight', 'DeliveryStateIdFE', 'DeliveryStateId', 'DeliveryStateName', 'FreightAmount', 'DestLocationId', 'DestId']

    switch (selectedServiceType) {

      case this.LCL_BREAKBULK:
        this.isBreakbulk = true;
        keyWords.forEach(key => {
          if (key === "TentativeWeight" || key === "DestLocationId" || key === "DestId" || key === "DeliveryStateIdFE" || key === "DeliveryStateName" || key === "DeliveryStateId")
            this.clearValidator(key, true)

          else this.setValidator(key)
        })
        break;

      case this.LCL_DIRECT_DELIEVERY:
        this.isLCLDirect = true
        keyWords.forEach(key => {
          if (key === "TentativeWeight" || key === "DeliveryStateId" || key === "DeliveryStateName" || key === "DeliveryStateName" || key === "DeliveryZoneId" || key === "DeliveryZoneName" || key === "DeliveryStateIdFE")
            this.clearValidator(key, true)

          else this.setValidator(key)
        })
        break;

      default:
        this.isBreakbulk = false;
        keyWords.forEach(key => {
          if (key === "TentativeWeight" && selectedServiceType != this.LCL_DIRECT_DELIEVERY) this.setValidator(key)
          else this.clearValidator(key, true)
        })
        break;

    }

    // TO SET STATE & ZONES
    if (this.isBreakbulk && this.isLCLDirect && !this.isDeliveryRegionEnabled) {
      //this.getDeliveryStates()
    }
  }

  resetBoolean(keyName) {
    switch (keyName) {
      case 'OriginMap':
        this.isOriginSelectedFromMap = false
        break;
      case 'PickUpRequestDate':
        this.isPickUpDateSelected = false
        break;
      case 'DestMap':
        this.isDestSelectedFromMap = false
        break;
    }
  }


  setValidator(keyName: string) {
    this.bookOrderForm.get(keyName).setValidators(Validators.required)
    this.bookOrderForm.get(keyName).updateValueAndValidity()
  }

  clearValidator(keyName: string, clearValue: boolean) {
    this.bookOrderForm.get(keyName).clearValidators()
    this.bookOrderForm.get(keyName).updateValueAndValidity()

    if (clearValue) {
      this.setValuesForFormControl([{ name: keyName, value: null }])
    }
  }

  googleLocation(event) {
    let val = event.target.value;
    if (val != null && val.length >= 3) {
      this.customerservice.getGoogleLocation(val).subscribe(data => {
        this.googleLocations = data['Data'];
      });
    } else {
      this.googleLocations = [];
    }
  }
  // getCustomerSavedLocations(data) {
  //    
  //   this.bookOrderForm.controls['CustomerId'].setValue(data)

  //   this.orderservice.GetLocationsDropdownlist(this.bookOrderForm.value).subscribe(response => {

  //     //Filtering the list for postal codes (removing saved loactions with no postal code)
  //     let filteredList = response['Data'].filter((element): element is object => {
  //       return element['Postcode'] !== null && element['Postcode'] !== '-'&& element['LocationId'] == '-';
  //     });

  //     this.originList = response['Data'].filter((element): element is object => {
  //       return element['LocationName'] !== null && element['LocationName'] !== '-';

  //     });
  //     //this.originList = filteredList
  //     this.destinationList = filteredList
  //   })
  // }
  onVehicleTypeSelection(event) {
    let selectedVehicleType = this.vehicleTypeList.filter(x => x.LookupId == event)[0];
    this.setValuesForFormControl([{ name: 'VehicleTypeName', value: selectedVehicleType['LookupName'] }])
  }


  onOriginLocationSelection(event) {
    let OriginDistrictId = this.originLocationList.filter(x => x.LookupId == event.value )[0].Id;

    if (this.customerId != null && this.getValueOfFormControl("OriginLocationId") != null) {
      // this.getCustomerSavedLocations(this.customerId, this.getValueOfFormControl("OriginLocationId"), "Origin")
      this.getCustomerSavedLocations(this.customerId,OriginDistrictId, "Origin")
    }

  }
  //Destination
  onDestLocationSelection(event) {
    if (this.customerId != null && this.getValueOfFormControl("DestLocationId") != null) {
      this.getCustomerSavedLocations(this.customerId, this.getValueOfFormControl("DestLocationId"), "Dest")
    }

  }
  getCustomerSavedLocations(customerId: number, originLocationId, type) {
    if (type == "Origin") this.resetSavedOriginSelection(type);
    else this.resetSavedOriginSelection(type);
    this._orderService.getCustomerLocation(customerId).subscribe(response => {
      let filteredList = response['Data'].filter((element) => {
        return element['Postcode'] !== null && element['Postcode'] !== '-' && element['DistrictId'] == originLocationId;

      });
      if (type == "Origin") {
        this.originList = filteredList
      }
      else {
        this.destList = filteredList
      }
    })
  }
  resetSavedOriginSelection(Type) {
    if (Type == "Origin") {
      let formControls = [
        { name: "OriginId", value: null },
        { name: 'OriginPostCodeId', value: null },
        { name: 'OriginPostCodeNumber', value: null },
        { name: 'OriginName', value: null },
        { name: 'OriginLatitude', value: null },
        { name: 'OriginLongitude', value: null },
        { name: 'POCName', value: null },
        { name: 'POCNumber', value: null },
        { name: "OriginAddress", value: null }]
      this.setValuesForFormControl(formControls)
    }
    else {
      let formControls = [
        { name: "DestId", value: null },
        { name: 'DestPostCodeId', value: null },
        { name: 'DestPostCodeNumber', value: null },
        { name: 'DestName', value: null },
        { name: 'DestLatitude', value: null },
        { name: 'DestLongitude', value: null },
        { name: "DestAddress", value: null }]
      this.setValuesForFormControl(formControls)
    }
  }


  validateCapturedLocation(mapData: any, Type: String) {

    this._orderService.getPostCodeDetails(mapData['Pincode']).subscribe(res => {

      let data = res["Data"]
      if (data != null) {
        if (Type == "Origin") {
          console.log(this.getValueOfFormControl("OriginLocationId") + " " + data["LocationId"] + " " + mapData['Pincode']);
          if (data["LocationId"] === this.getValueOfFormControl("OriginLocationId")) {
            this.clearValidator('OriginId', true)
            this.clearValidator('OriginPostCodeId', true)
            let formControls = [{ name: "OriginPostCodeNumber", value: mapData['Pincode'] },
            { name: "OriginName", value: mapData['Address'] },
            { name: "OriginMap", value: mapData['Address'] },
            { name: "OriginLatitude", value: mapData['Latitude'] },
            { name: "OriginLongitude", value: mapData['Longitude'] },
            { name: "POCName", value: null },
            { name: "POCNumber", value: null },
            { name: "OriginAddress", value: null }
            ]
            this.setValuesForFormControl(formControls)
            this.isOriginSelectedFromMap = true

          }
          else {
            this.alertService.createAlert(`Invalid Location ${data['LocationName']} , Please select location as per Origin Location`, 2)
          }
        }
        else {
          if (data["LocationId"] === this.getValueOfFormControl("DestLocationId")) {
            this.clearValidator('DestId', true)
            this.clearValidator('DestPostCodeId', true)
            let formControls = [{ name: "DestPostCodeNumber", value: mapData['Pincode'] },
            { name: "DestName", value: mapData['Address'] },
            { name: "DestMap", value: mapData['Address'] },
            { name: "DestLatitude", value: mapData['Latitude'] },
            { name: "DestLongitude", value: mapData['Longitude'] },
            { name: "DestAddress", value: null }
            ]
            this.setValuesForFormControl(formControls)
            this.isDestSelectedFromMap = true

          }
          else {
            this.alertService.createAlert(`Invalid Location ${data['LocationName']} , Please select location as per Destination Location`, 2)
          }
        }
      }
      else {
        this.alertService.createAlert("Services are not available for this location", 2)
      }
    })
  }

  onSelectAll(items: any) {
    console.log(items);
  }
}

@Pipe({
  name: 'stringFilterBy'
})
export class StringFilterByPipeLCL implements PipeTransform {
  transform(arr: any[], searchText: string, fieldName?: string): any[] {
    debugger
    if (!arr) return [];
    if (!searchText) return arr;
    if (searchText.length > 0) {
      searchText = searchText.toLowerCase();
    }
    return arr.filter((it: any) => {
      if (typeof it == 'string') {
        return it.toLowerCase().includes(searchText);
      } else if (typeof it == 'number') {
        return it.toString().toLowerCase().includes(searchText);
      } else {
        return it[fieldName].toLowerCase().includes(searchText);
      }

    });
  }
}