import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';  
@Component({
  selector: 'app-orders-summary',
  templateUrl: './orders-summary.component.html',
  styleUrls: ['./orders-summary.component.scss']
})
export class OrdersSummaryComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<OrdersSummaryComponent>) { }

  ngOnInit(): void {
  }
  close(){
    this.dialogRef.close();
  }

  print() {
    let printContents, popupWin;
    printContents = document.getElementById('tblPrint').innerHTML;
    popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write('<html><head><title></title><style></style></head><body onload="window.print();window.close()"><div><table [innerHTML]="printContents" style="width: 100%; border: 2px solid black; margin-bottom: 10px;"></table></div></body></html>');
    popupWin.document.close();
  }

  downloadPDF() { 
    var data = document.getElementById('pdf');  
    // data.style.display = 'block';
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      var imgWidth = 210;
      var pageHeight = 297;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A6 size page of PDF 
      var position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('Order Summary - ' + this.data.OrderIdCode + '.pdf'); // Generated PDF
      
    }); 
  }

}
