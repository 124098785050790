import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-xpcn-preview-dialog',
  templateUrl: './xpcn-preview-dialog.component.html',
  styleUrls: ['./xpcn-preview-dialog.component.scss']
})
export class XpcnPreviewDialogComponent implements OnInit {

  rows = 5;
  formData: FormData = new FormData();
  addEditForm: FormGroup;
  showFreight = false;
  xpcn: any;
  invoices = [];
  waybills = [];
  invoices1 = [];
  waybills1 = [];
  invoicesCount = 0;
  waybillsCount = 0;
  rowspanheader:any=5;
  showBoxesDimensions : boolean = false

  freightMode;
  viaPoints;
  goodsDesc;
  packageType;
  riskType;
  cgst = 0;
  sgst = 0;
  igst = 0;
  invoicesTotal = 0;
  total = 0;
  totalWithourGST: number = 0;
  totalBox;
  totalWt = 0;
  showDownload = false;

  isConsignor = false;
  rows1 = 4;

  constructor(public dialogRef: MatDialogRef<XpcnPreviewDialogComponent>,
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.addEditForm = this._fb.group({
      FileName: new FormControl(''),
      Subject: new FormControl('')
    });
  }

  ngOnInit(): void {


    if (this.data != null) {

      if (this.data['xpcn'] != null) {
        this.xpcn = this.data['xpcn'];
        this.showDownload = this.data['showDownload'];
        this.xpcn.BoxesListConsignor.forEach(x => {
          this.totalWt += x.Weight;
        });
        this.isConsignor = this.xpcn.ConsignorId != null ? true : false;
        this.showBoxesDimensions = this.xpcn.ServiceType != 'LCL Breakbulk'
        this.rows1 = this.isConsignor == true ? 5 : 4;
        this.rowspanheader = this.isConsignor == true ? 6 : 5;

        // this.isConsignor = this.xpcn.ConsignorId != null ? true : false;
        this.rows1 = this.isConsignor == true ? 5 : 4;
        const count = this.xpcn['Boxes'].filter(item => item.isSelected === true).length
        if (count > 0) this.totalBox = count; else this.totalBox = this.xpcn.Boxes.length;
        if (this.xpcn.ServiceType == 'LCL Aggregation')
          this.rows = 6;

        if (this.xpcn.ModeOfFreightId != null && this.xpcn.FreightModes != null
          && this.xpcn.FreightModes.length > 0 && this.xpcn.FreightModes.filter(x => x.LookupId == this.xpcn.ModeOfFreightId).length > 0) {
          this.freightMode = this.xpcn.FreightModes.filter(x => x.LookupId == this.xpcn.ModeOfFreightId)[0].LookupName;
        }
        this.showFreight = this.freightMode == 'Paid' || this.freightMode == 'To Pay' || this.xpcn['CreditFreightShow'] == 'CreditShow'; //this.xpcn['XPCNFreightDetails'] ||
        if (this.xpcn.GoodsDescId != null && this.xpcn.GoodsDescs != null
          && this.xpcn.GoodsDescs.length > 0 && this.xpcn.GoodsDescs.filter(x => x.LookupId == this.xpcn.GoodsDescId).length > 0) {
          this.goodsDesc = this.xpcn.GoodsDescs.filter(x => x.LookupId == this.xpcn.GoodsDescId)[0].LookupName;
        }
        if (this.xpcn.PackageTypeId != null && this.xpcn.PackageTypes != null
          && this.xpcn.PackageTypes.length > 0 && this.xpcn.PackageTypes.filter(x => x.LookupId == this.xpcn.PackageTypeId).length > 0) {
          this.packageType = this.xpcn.PackageTypes.filter(x => x.LookupId == this.xpcn.PackageTypeId)[0].LookupName;
        }
        if (this.xpcn.RiskTypeId != null && this.xpcn.RiskTypes != null
          && this.xpcn.RiskTypes.length > 0 && this.xpcn.RiskTypes.filter(x => x.LookupId == this.xpcn.RiskTypeId).length > 0) {
          this.riskType = this.xpcn.RiskTypes.filter(x => x.LookupId == this.xpcn.RiskTypeId)[0].LookupName;
        }
        if (this.xpcn.Via1 != null && this.xpcn.Via2 == null) {
          this.viaPoints = this.xpcn.Via1;
        }
        else if (this.xpcn.Via1 != null && this.xpcn.Via2 != null) {
          this.viaPoints = this.xpcn.Via1 + ', ' + this.xpcn.Via2;
        }

        // if (this.xpcn.Rate != null) {
        //   this.total = this.total + this.xpcn.Rate;
        // }
        // if (this.xpcn.HandlingCharges != null) {
        //   this.total = this.total + this.xpcn.HandlingCharges;
        // }
        // if (this.xpcn.HaltingCharges != null) {
        //   this.total = this.total + this.xpcn.HaltingCharges;
        // }
        // if (this.xpcn.OtherCharges != null) {
        //   this.total = this.total + this.xpcn.OtherCharges;
        // }

        // if (this.xpcn.GST != null) {
        //   this.total = this.total + this.xpcn.GST;
        //   if (this.xpcn.BillingOfficeType == 'Central') {
        //     this.igst = this.xpcn.GST;
        //   } else {
        //     this.cgst = this.xpcn.GST / 2;
        //     this.sgst = this.xpcn.GST / 2;
        //   }
        // }

        this.calculateTotal();

        // if (this.xpcn.BasicFreight != null)
        // this.total = this.total + this.xpcn.TotalAmountWithoutGST;
        // if (this.xpcn.HandlingCharges != null)
        // this.total = this.total + this.xpcn.HandlingCharges;
        // if (this.xpcn.HaltingCharges != null)
        // this.total = this.total + this.xpcn.HaltingCharges;
        // if (this.xpcn.OtherCharges != null)
        // this.total = this.total + this.xpcn.OtherCharges;

        // if (this.xpcn.GST != null)
        // {
        //     this.total = this.total + (this.total * this.xpcn.GST)/100;
        //     if (this.xpcn.BillingOfficeType == "Central")
        //     {
        //         this.igst = (this.xpcn.TotalAmountWithoutGST * this.xpcn.GST) / 100;
        //     }
        //     else
        //     {
        //         this.cgst = ((this.xpcn.TotalAmountWithoutGST * this.xpcn.GST) / 100) / 2;
        //         this.sgst = ((this.xpcn.TotalAmountWithoutGST * this.xpcn.GST) / 100) / 2;
        //     }
        // }
      }

      if (this.data['invoices'] != null && this.data['invoices'].length > 0) {
        this.invoices = this.data['invoices'];
        this.invoices1 = this.data['invoices'];
        this.invoices.forEach(x => {
          this.invoicesTotal = this.invoicesTotal + x.InvoiceValue;
        });
        this.invoicesTotal = Number(this.invoicesTotal.toFixed(2));
        this.invoicesCount = this.invoices.length;
        if (this.invoices.length > 15) {
          this.invoices = this.invoices.slice(0, 15);
        }
      }
      if (this.data['waybills'] != null && this.data['waybills'].length > 0) {
        this.waybills = this.data['waybills'];
        this.waybills1 = this.data['waybills'];
        this.waybillsCount = this.waybills.length;
        if (this.waybills.length > 15) {
          this.waybills = this.waybills.slice(0, 15);
        }
      }
    }
  }

  calculateTotal() {
    this.total = 0;
    let BasicFreight = this.xpcn.BasicFreight == null ? 0 : this.xpcn.BasicFreight;
    let CNCharges = this.xpcn.CNCharges == null ? 0 : this.xpcn.CNCharges;
    let FOVCharge = this.xpcn.FOVCharge == null ? 0 : this.xpcn.FOVCharge;
    let RiskCharges = this.xpcn.RiskCharges == null ? 0 : this.xpcn.RiskCharges;
    let ODACharge = this.xpcn.ODACharge == null ? 0 : this.xpcn.ODACharge;
    let ToPayCharge = this.xpcn.ToPayCharge == null ? 0 : this.xpcn.ToPayCharge;
    let FuelCharge = this.xpcn.FuelCharge == null ? 0 : this.xpcn.FuelCharge;
    let GreenCharge = this.xpcn.GreenCharge == null ? 0 : this.xpcn.GreenCharge;
    let Handling = this.xpcn.HandlingCharges == null ? 0 : this.xpcn.HandlingCharges;
    // let CODDODCharge = this.xpcn.CODDODCharge == null ? 0 : this.xpcn.CODDODCharge;
    let DelCharge = this.xpcn.DelCharge == null ? 0 : this.xpcn.DelCharge;
    let ValueAddedCharge = this.xpcn.ValueAddedCharge == null ? 0 : this.xpcn.ValueAddedCharge;
    let OtherCharges = this.xpcn.OtherCharges == null ? 0 : this.xpcn.OtherCharges;
    // let gst = Number(BasicFreight) * 0.12;

    this.totalWithourGST =
      Number(BasicFreight) +
      Number(CNCharges) +
      Number(FOVCharge) +
      Number(RiskCharges) +
      Number(ODACharge) +
      Number(ToPayCharge) +
      Number(FuelCharge) +
      Number(GreenCharge) +
      Number(Handling) +
      // Number(CODDODCharge) +
      Number(DelCharge) +
      Number(ValueAddedCharge) +
      Number(OtherCharges);

    let gst = this.totalWithourGST * 0.12;
    this.total = this.totalWithourGST + Number(gst);

    if (this.xpcn.GST != null) {
      if (this.xpcn.BillingOfficeType == "Central")
        this.igst = gst;
      else {
        this.cgst = gst / 2;
        this.sgst = gst / 2;
      }
    }
  }

  close() {
    this.dialogRef.close()
  }
  downloadPDF() {
    window.open(environment.apiUrl + 'InvoiceView/DownloadLCLXPCNPDF?ids=' + this.xpcn.XPCNId + "&xpcn_code=" + this.xpcn.XPCNCode, "_blank");
  }

}
