<div class="col-xs-12" *ngIf="access == true">
    <div class="col-xs-12 mb-4 d-flex justify-content-right ml-0 p-0">
        <div class="d-flex justify-content-right ml-0 p-0">



            <a routerLinkActive="active"  class="btn btn mb-1 ml-2  fa fa-file-text"
                style="background-color: #0077bd;color: white; height: 34px;" [routerLink]="['./company-details']">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Company Details</span></a>
            <a routerLinkActive="active"  class="btn btn mb-1 ml-2 fa fa-user"
                style="background-color: #0077bd;color: white; height: 34px;" [routerLink]="['./users']">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Users</span></a>
            <a routerLinkActive="active"  class="btn btn mb-1 ml-2  fa fa-map-marker"
                style="background-color: #0077bd;color: white; height: 34px;" [routerLink]="['./locations']">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Locations</span></a>
            <a routerLinkActive="active"  class="btn btn mb-1 ml-2  fa fa-bank"
                style="background-color: #0077bd;color: white; height: 34px;" [routerLink]="['./billing-office']">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Billing Office</span></a>
            <a routerLinkActive="active"  class="btn btn mb-1 ml-2 fa fa-handshake-o"
                style="background-color: #0077bd;color: white; height: 34px;" [routerLink]="['./contracts']">
                <span style="font-family: Arial, Helvetica, sans-serif;"> Contracts</span></a>
            <a routerLinkActive="active"  class="btn btn mb-1 ml-2  fa fa-rupee"
                style="background-color: #0077bd;color: white; height: 34px;" [routerLink]="['./fcl-rates']">
                <span style="font-family: Arial, Helvetica, sans-serif;"> FCL
                    Rates</span></a>
            <a routerLinkActive="active"  class="btn btn mb-1 ml-2 fa fa-envelope-open"
                style="background-color: #0077bd;color: white; height: 34px;" [routerLink]="['./mis']">
                <span style="font-family: Arial, Helvetica, sans-serif;"> MIS</span></a>

        </div>
    </div>
    <div class="row ">
        <div class="col-xl-12">
            <router-outlet></router-outlet>
        </div>

    </div>
</div>

<div *ngIf="access == false">
    <p class="text-center dj">You don't have permission to access this screen, please contact administrator.</p> 
</div>