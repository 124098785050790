import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  public form: FormGroup;

  constructor(public fb:FormBuilder, public router:Router, public alertService:AlertService,private _loginservice:LoginService, private Activatedroute:ActivatedRoute) {
    this.form=this.fb.group({
      'Email':[null,Validators.compose([Validators.required])]
    });
   }

  ngOnInit(): void {
  }


  public onSubmit(values:Object):void{
    if(this.form.valid){
      this._loginservice.forgotPassword(this.form.value).subscribe(data=> {
        if (data != null){
          if(data['Success']){
            this.router.navigate(['/login'])
          }
        }
      })

    }
  }

}
