import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/pages/login/login.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit {
  userName = '';
  roleName = '';
  image = '';
  name = '';
  sessionDetails: any;
  UserDetails: any;
  messageCount = 0;
  subscription: Subscription;

  constructor(
    public alertService: AlertService,
    public dialog: MatDialog, private _loginService: LoginService
  ) {
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
    // console.log(this.sessionDetails)
    if (this.sessionDetails != null && this.sessionDetails != undefined && this.sessionDetails != '') {
      this.userName = this.sessionDetails['FirstName'];
      this.roleName = this.sessionDetails['Role'];
    }
    const source = interval(300000);
    // if (!this.isAssociate) {
    this.subscription = source.subscribe(val => this.getNewNotifications());
  }

  ngOnInit() {
    this.customerDetails();
    this.getNewNotifications();
  }

  customerDetails(): void {
    this._loginService.getuserdetails().subscribe((res) => {
      if (res != null) {
        //console.log(res);
        this.UserDetails = res['Data'];
        this.image = res['Data']['ProfilePicUrl'];
        this.name = this.UserDetails['UserName'];
      }
    });
  }  

  getNewNotifications() {
    this._loginService.GetNewMessagesCount().subscribe(data => {
      if (data != null && data['Success']) {
        this.messageCount = data['Data'];
      }
    });
  }

  logoutUser() {
    localStorage.clear()
    this.alertService.createAlert("Logged out successfully", 1);
  }
  public openUserDialogs(id) {
    let dialogRef = this.dialog.open(UserProfileComponent, {
      data: id,
      height: 'auto',
      width: '600px',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(data => {
    });
  }

  updateNewMessageCount() {
        setTimeout(() => { this.messageCount = 0 }, 3000);
  }
}
