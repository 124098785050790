import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BookOrderService {

  headers: { headers: any; };
  constructor(private _http: HttpClient) { }

  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'dataType': 'jsonp'
      })
    };
    return this.headers = httpOptions;
  }
  getCustomerDetails() {
    return this._http.get(environment.apiUrl + 'api/cstmr/get-customer-details-web');
  }

  getOrderOptions() {
    return this._http.get(environment.apiUrl + 'api/get-order-options');
  }
   //to get the list of region
   GetRegions() {
    return this._http.get(environment.apiUrl + 'api/get-region')
  }
  // to get customer location
  getCustomerLocation(customerId) {
    return this._http.get(environment.apiUrl + 'api/get-customer-location?customerId=' + customerId);
  }
  getSavedPickupLocations(customerId, originId, originType) {
    return this._http.get(environment.apiUrl + 'api/get-saved-pickup-location?customerId=' + customerId + '&originId=' + originId + '&originType=' + originType);
  }

  getLatLong(origin) {
    return this._http.get(environment.apiUrl + 'api/get-lat-long?origin=' + origin);
  }

  getOriginDestinations(keyword, isOrigin) {
    return this._http.get(environment.apiUrl + 'api/get-origin-destination?keyword=' + keyword + '&isOrigin=' + isOrigin);
  }

  getOrderRates(order) {
    return this._http.post(environment.apiUrl + 'api/get-order-rates', order);
  }

  saveOrder(order) {
    return this._http.post(environment.apiUrl + 'api/cstmr/book-new-order', order);
  }
  bookLCLOrder(data){
    return this._http.post(environment.apiUrl + 'api/book-lcl-order', data);
  }
  

  getGoogleLocation(keyword) {
    return this._http.get(environment.apiUrl + 'api/get-google-location?keyword=' + keyword);
  }

  getSettings(type) {
    return this._http.get(environment.apiUrl + 'api/get-settings?type=' + type);
  }
  getPostCodeDetails(postcode) {
    return this._http.get(environment.apiUrl + 'api/postcode-details?postcode=' + postcode);
    }
  //to get the list of gateway
  GetGateway() {
    return this._http.get(environment.apiUrl + 'api/get-gateway')
  }

}
