<div class="d-flex justify-content-center align-items-center w-100 h-100 login-container login-bg">
  <div class="col-xl-4 col-md-6 col-10">
      <div class="card border-0 box-shadow rounded-0">
          <div class="card-header d-flex justify-content-center align-items-center border-0 box-shadow" style="background-color: #fdfdfd; height:180px; width:180px">
              <img style="height:100px;margin-left:10px; border-radius: 20px;" src="../../assets/img/vendor/leaflet/logo_xpp.jpg">
          </div>
          <div class="card-body text-center pb-1">
              <h2>Forget Password</h2>
              <!-- <a routerLink="/register" class="transition">Don't have an account? Sign up now!</a> -->

              <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)"  class="text-left mt-4">
                  <div class="form-group">
                      <input  class="form-control validation-field" placeholder="Email" type="text" formControlName="Email">
                      <mat-error class="text-danger" *ngIf="form.controls.Email.errors?.required" >Email is required</mat-error>
                  </div>
                  <div class="form-group">
                      <button  class="btn btn-block" type="submit">Submit</button>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>

